export const APPROVE_NUMBER = 2 ** 256;
export const Colors = () => {
  return {
    text: "#FCF6FE",
    text1: "#F7E4FB",
    text2: "#2D112D",
    text3: "#d6c9d9",
    textOverlay: "rgba(238, 200, 247, 0.6)",
    textOverlay2: "rgba(247, 228, 251, 0.6)",
    trHover: "#353535",
    // Link
    textLink: "#99DCF7",
    textHover: "#A93EC1",
    // backgrounds / greys
    bg: "#2D112D",
    bg1: "#4A2F4A",
    bg2: "#181516",
    bg3: "#0D000F",
    bg4: "#C300E1",
    bg5: "#201720",
    bg6: "rgba(212, 158, 255, 0.07)",
    bg7: "rgba(24, 21, 22, 0.8)",
    bg8: "rgba(74, 47, 74, 0.2)",
    bg9: "",
    bg10: "rgba(74, 47, 74, 0.5)",
    bg11: "#221722",
    //primary colors
    primary: "#A93EC1",
    // Border
    border: "#EEC8F7",
    border1: "#F7E4FB",
    border2: "#352936",
    border3: "#735F73",
    borderOverlay: "rgba(238, 200, 247, 0.2)",
    borderOverlay1: "rgba(115, 95, 115, 0.2)",
    //Style button
    bgBtn: "#353535",
    textBtn: "#c2c2c2",
    bdBtn: "#c2c2c2",

    bgBtnActive: "#A93EC1",
    textBtnActive: "#fff",

    bgBtnDisable: "#353535",
    textBtnDisable: "#565656",

    up: "#30CD60",
    down: "#DA4343",

    success: "#1D8C46",
    warning: "#D9852B",
    error: "#B32E33",

    //box shadow
    boxShadow: "rgba(212, 158, 255, 0.1)",
    boxShadow1: "rgba(0, 0, 0, 0.05)",
  };
};
