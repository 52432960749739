import styled from "styled-components";

export const WrapTable = styled.div`
  background: ${({ theme }) => theme.bg7};
  backdrop-filter: blur(20px);
  border-radius: 8px;
  box-shadow: 0px 4px 8px ${({ theme }) => theme.boxShadow1};
  padding: 24px;
  height: 100%;
  @media (max-width: 991px) {
    padding: 15px;
    border-radius: 0px;
  }
  &.not-bg-content-table {
    background-color: transparent;
    padding: 0px;
    border-radius: 0px;
    box-shadow: 0px 0px 0px;
    @media (max-width: 767px) {
      padding: 0px;
    }
  }
  .style-table-bordered {
    .ant-table {
      border: 1px solid ${({ theme }) => theme.borderOverlay1} !important;
      padding-top: 1px;
      background: transparent;
      border-radius: 8px;
      overflow: hidden;
      table {
        color: ${({ theme }) => theme.text};
        .ant-table-tbody > tr {
          &:last-child {
            td {
              border-bottom: 0px !important;
            }
          }
          > td {
            border-bottom: 1px solid ${({ theme }) => theme.borderOverlay1};
            background: ${({ theme }) => theme.bg5};
            padding-left: 15px;
            padding-right: 15px;
          }
        }
        .ant-table-thead > tr > th {
          color: ${({ theme }) => theme.text};
          background: ${({ theme }) => theme.bg7};
          border-bottom: 0px;
          padding-left: 15px;
          padding-right: 15px;
          font-size: 13px;
        }
        .ant-table-tbody > tr.ant-table-row:hover > td {
          background: ${({ theme }) => theme.bg6}!important;
        }
        .ant-table-tbody > tr.ant-table-placeholder:hover > td {
          background: ${({ theme }) => theme.bg6}!important;
        }
      }
    }
  }
  .ant-empty-description {
    color: ${({ theme }) => theme.textOverlay};
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background: transparent;
    color: ${({ theme }) => theme.text};
    border: none;
  }
  .ant-select-arrow {
    color: ${({ theme }) => theme.text};
  }
  .styled-spin {
    .ant-spin-dot-item {
      background-color: ${({ theme }) => theme.primary};
    }
  }
`;
