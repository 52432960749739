import React from "react";
import styled from "styled-components";
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";

export function Up({ number }: { number: string | number | undefined | null }) {
  return (
    <UpWrap>
      <CaretUpOutlined /> {number}%
    </UpWrap>
  );
}

export function Down({
  number,
}: {
  number: string | number | undefined | null;
}) {
  return (
    <DownWrap>
      <CaretDownOutlined /> {number}%
    </DownWrap>
  );
}

export function UpRearIcon({
  number,
}: {
  number: string | number | undefined | null;
}) {
  return (
    <UpWrap>
      {number}% <CaretUpOutlined />
    </UpWrap>
  );
}

export function DownRearIcon({
  number,
}: {
  number: string | number | undefined | null;
}) {
  return (
    <DownWrap>
      {number}% <CaretDownOutlined />
    </DownWrap>
  );
}

const UpWrap = styled.span`
  color: ${({ theme }) => theme.up};
`;
const DownWrap = styled.span`
  color: ${({ theme }) => theme.down};
`;
