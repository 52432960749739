import React from "react";
import { Row, Col, Space } from "antd";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Wrapper,
  Container,
  TextMain,
  TextOverlay,
  ButtonGradient,
} from "common/styledGeneral";
// import Search404 from "common/SearchBox";
import icon404Left from "assets/images/icon-404-left.svg";
import icon404Right from "assets/images/icon-404-right.svg";
import { PageTitle } from "common/PageTitle";
import { useQuery } from "app/hooks";

interface KeyWordSearch {
  keyWord?: any;
}

function Error404() {
  const query = useQuery();
  const searchString = query.get("q")?.trim() || "";
  const navigate = useNavigate();

  function retry() {
    let searchType;
    switch (true) {
      case searchString.length === 66:
        searchType = "tx";
        break;

      case !searchString.match(/\D+/):
        searchType = "block";
        break;

      default:
        searchType = "address";
        break;
    }

    if (searchString && searchType) {
      navigate(`/${searchType}/${searchString}`);
    }
  }
  return (
    <Wrapper className="mt-5">
      <Helmet>
        <title>404 Error</title>
      </Helmet>
      <Container>
        <Row gutter={[24, 24]}>
          <Col xs={12} md={{ span: 5, order: 1 }} xl={{ span: 6, order: 1 }}>
            <div className="st-icon404 ml-a-80">
              <img src={icon404Left} />
            </div>
          </Col>
          <Col xs={12} md={{ span: 5, order: 3 }} xl={{ span: 6, order: 3 }}>
            <div className="st-icon404 mr-a-80">
              <img src={icon404Right} />
            </div>
          </Col>
          <Col
            xs={24}
            md={{ span: 14, order: 2 }}
            xl={{ span: 12, order: 2 }}
            className="text-center"
          >
            <PageTitle title="404 - PAGE NOT FOUND" fontSize="32" />
            <TextOverlay>Oops! The search string you entered was</TextOverlay>
            <TextMain className="bold inline-block word-break mx-05">
              {searchString}
            </TextMain>
            <TextOverlay>is an invalid string.</TextOverlay>
            {/* <Search404 /> */}
            <ButtonGradient
              className="mt-3"
              style={{ paddingTop: 16, paddingBottom: 16 }}
              onClick={retry}
            >
              Click to retry!
            </ButtonGradient>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  );
}

export default Error404;
