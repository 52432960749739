import React from "react";

import { useGetTxsOfBlockByNumberQuery } from "app/services/block";
import { txColumns } from "./columnsConfig";
import DataTableTotal from "common/DataTable/DataTableTotal";
import SkeletonTable from "common/SkeletonTable";
import { WrapTable } from "common/TableAccountTxs/styled";
import { DataTableNew } from "common/TableAccountTxs/Table";

export function TxsOnBlock({ blockNumber }: { blockNumber: string }) {
  const { data, isLoading } = useGetTxsOfBlockByNumberQuery({
    offset: 0,
    limit: 10,
    number: blockNumber,
  });
  return (
    <WrapTable className="not-bg-content-table">
      {isLoading ? (
        <SkeletonTable type="tx" record={1} loading={isLoading} />
      ) : (
        <>
          <div style={{ marginBottom: 20 }}>
            <DataTableTotal total={data?.total || 0} type="tx" />
          </div>
          <DataTableNew
            data={data?.data ? data.data : []}
            columns={txColumns}
            loading={isLoading}
          />
        </>
      )}
    </WrapTable>
  );
}
