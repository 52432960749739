import React, { useState } from "react";

import { AddressType } from "constants/types";
import { useGetNftsInAccountQuery } from "app/services/account";
import { tokenBalanceColumn } from "./columnsConfig";
import DataTable from "common/DataTable";
import DataTableTotal from "common/DataTable/DataTableTotal";

export function NftsInAccount({ address }: { address: string }) {
  const [args, setArgs] = useState({ address: "", offset: 0, limit: 20 });
  const { data, error, isLoading } = address
    ? useGetNftsInAccountQuery({ ...args, address })
    : { data: null, error: null, isLoading: false };

  return (
    <DataTable
      notBgContentTable="not-bg-content-table"
      data={data?.data?.items ? data.data.items : []}
      headerLeft={
        <DataTableTotal
          total={data?.data?.total ? data.data.total : 0}
          type="tx"
        />
      }
      total={data?.data?.total ? data.data.total : 0}
      columns={tokenBalanceColumn}
      setParams={setArgs}
      loading={isLoading}
    />
  );
}
