import React from "react";
import { Space, Tooltip } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { truncate as Substring } from "utils";
import { LinkType } from "constants/types";
import { TextOverlay } from "common/styledGeneral";
import CopyIcon from "common/CopyIcon";

const LinkFormat = ({
  title,
  value,
  type = "address",
  truncate = false,
  position,
  num,
  tooltip = true,
  status,
  copy = false,
}: LinkType) => {
  if (!value) return null;

  if (!tooltip) {
    return (
      <Space>
        {copy && <CopyIcon value={value} />}
        <Link className="txt-ellipsis" to={`/${type}/${value.toLowerCase()}`}>
          {status == "0" && (
            <ExclamationCircleOutlined
              style={{ marginTop: 3, marginRight: 5, color: "#B32E33" }}
            />
          )}
          <TextOverlay className="link-color text-ellipsis">
            {title
              ? title
              : truncate && value
              ? Substring(value, position, num)
              : value}
          </TextOverlay>
        </Link>
      </Space>
    );
  }

  return (
    <div
      style={{
        maxWidth: "100%",
        display: "inline-flex",
        alignContent: "center",
      }}
    >
      {copy && <CopyIcon value={value} />}
      <Tooltip title={value} color="#4A2F4A">
        <div
          style={{
            maxWidth: "100%",
            display: "inline-flex",
            alignContent: "center",
          }}
        >
          {status == "0" && (
            <ExclamationCircleOutlined
              style={{ marginTop: 3, marginRight: 5, color: "#B32E33" }}
            />
          )}
          <Link
            className="d-block text-ellipsis"
            to={`/${type}/${value.toLowerCase()}`}
          >
            {title
              ? title
              : truncate && value
              ? Substring(value, position, num)
              : value}
          </Link>
        </div>
      </Tooltip>
    </div>
  );
};

export default LinkFormat;
