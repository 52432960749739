import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import styled from "styled-components";
import {
  Col,
  Row,
  Form,
  Select,
  Spin,
  Button,
  Input,
  Upload,
  Radio,
} from "antd";
import type { RadioChangeEvent } from "antd";
import {
  MinusCircleOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { InputStyled, Title, InputNumberStyled } from "./styled";
import {
  Container,
  TextMain,
  TextOverlay,
  Wrapper,
} from "common/styledGeneral";
import { getNetwork } from "utils";
import TextArea from "antd/lib/input/TextArea";
import { API_URL } from "constants/api";
import { useQuery } from "app/hooks";

const { Option } = Select;

export default function ContractVerify() {
  const [loading, setLoading] = useState(false);
  const [compilerType, setCompilerType] = useState("solidity");
  const [typeVer, setTypeVer] = useState([]);
  const [sourceCode, setSourceCode] = useState({});
  const [typeSource, setTypeSourde] = useState("default");
  const [mgs, setMgs]: Array<any> = useState([]);
  const [form] = Form.useForm();
  const searchParam = useQuery();
  const address = searchParam.get("address");
  // const { apiUrl } = getNetwork();
  const radioOptions = [
    { label: "Default", value: "default" },
    { label: "Upload", value: "upload" },
  ];

  const getTypeVer = async (value: string) => {
    const { data } = await axios.get(
      `${API_URL}contract/version?compiler_type=${value}`
    );
    if (data && data.success && data.data) {
      setTypeVer(data.data);
    }
    return data;
  };

  useEffect(() => {
    if (compilerType) {
      getTypeVer(compilerType);
    }
  }, [compilerType]);

  let formMgs: Array<any> = [];
  const contractVerify = async (values: any) => {
    return await axios.post(`${API_URL}contract/verify`, values);
  };
  const onFinish = async (values: any) => {
    if (typeSource === "upload" && sourceCode) {
      const source_code = sourceCode;
      values = { ...values, source_code };
    }
    setLoading(true);
    if (values && values.args) {
      values.args = JSON.parse(values.args);
    }
    if (!values.source_code) {
      formMgs.push({
        status: "warning",
        mgs: "Warning: Please add Solidity source code!",
      });
    } else {
      try {
        await contractVerify(values);
        formMgs.push({
          status: "success",
          mgs: "Contract verified successful!",
        });
      } catch (e: any) {
        formMgs.push({
          errors: e?.response?.data?.errors,
          status: "error",
          mgs:
            e?.response?.data?.message ||
            "Error: An unknown error. Please reload the website and try again",
        });
      }
    }
    setLoading(false);
    setMgs(formMgs);
  };
  const fileOnChange = async (files: any) => {
    try {
      if (files?.fileList) {
        const data = await upload(files.fileList);
        setSourceCode(data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const upload = async (data: any) => {
    // Convert the FileList into an array and iterate
    let files = data.map((file: any) => {
      // Define a new file reader
      let reader = new FileReader();
      // Create a new promise
      return new Promise((resolve) => {
        // Resolve the promise after reading file
        reader.onload = () =>
          resolve({
            file_name: file?.originFileObj?.webkitRelativePath || "",
            content: reader.result,
          });

        // Read the file as a text
        reader.readAsText(file.originFileObj);
      });
    });

    // At this point you'll have an array of results
    return await Promise.all(files);
  };

  const onChangeRadio = ({ target: { value } }: RadioChangeEvent) => {
    setTypeSourde(value);
  };
  return (
    <Wrapper>
      <Container className="mx-md-down-0">
        <HeaderStyled>
          <Title
            style={{
              fontSize: "24px",
              paddingBottom: 0,
            }}
          >
            Verify and Publish your Solidity Source Code
          </Title>
          <SubStyled>
            To verify smart contracts using Hardhat,&nbsp;
            <a
              href="https://github.com/neonscan/hardhat-integrate"
              target="_blank"
              rel="noreferrer"
            >
              CLICK HERE&nbsp;
            </a>
            for detailed instructions
          </SubStyled>
        </HeaderStyled>
        <FormGroup>
          <Form layout="vertical" form={form} onFinish={onFinish}>
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item
                  name="contract_address"
                  label="Contract Address"
                  rules={[{ required: true, message: "Please select a value" }]}
                  initialValue={address ? address : ""}
                >
                  <InputStyled placeholder="0x..." required />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="contract_name"
                  label="Contract Name"
                  rules={[
                    { required: true, message: "Please enter a contract name" },
                  ]}
                >
                  <InputStyled placeholder="eg: ERC20" required />
                </Form.Item>
              </Col>
              <Col span={24}>
                <div>
                  <Form.Item
                    name="compiler_type"
                    label="Compiler Type"
                    hasFeedback
                    rules={[
                      { required: true, message: "Please select a value" },
                    ]}
                    initialValue="solidity"
                  >
                    <Select
                      placeholder="Please select a value"
                      dropdownClassName="compiler-type"
                      onChange={(value) => setCompilerType(value)}
                    >
                      <Option value="solidity">Solidity</Option>
                      <Option value="vyper">Vyper</Option>
                    </Select>
                  </Form.Item>
                </div>
              </Col>
              <Col span={24}>
                <div>
                  <Form.Item
                    name="version"
                    label="Compiler Version"
                    hasFeedback
                    rules={[
                      { required: true, message: "Please select a value" },
                    ]}
                  >
                    <Select
                      placeholder="Please select a value"
                      dropdownClassName="solc-ver"
                    >
                      {typeVer &&
                        typeVer.length > 0 &&
                        typeVer.map((v: any, key: number) => {
                          return (
                            <Option key={`item${key}`} value={v.fullVersion}>
                              {v.fullVersion}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </div>
              </Col>
              <Col span={24}>
                <div>
                  <Form.Item
                    name="optimization"
                    label="Optimization"
                    hasFeedback
                    rules={[
                      { required: true, message: "Please select a value" },
                    ]}
                    initialValue={false}
                  >
                    <Select
                      placeholder="Please select a value"
                      dropdownClassName="optimazation"
                    >
                      <Option value={false}>No</Option>
                      <Option value={true}>Yes</Option>
                    </Select>
                  </Form.Item>
                </div>
              </Col>
              <Col span={24}>
                <div>
                  <Form.Item
                    name="runs"
                    rules={[{ type: "number" }]}
                    label="Runs"
                  >
                    <InputNumberStyled placeholder="eg: 200" />
                  </Form.Item>
                </div>
              </Col>
              <Col span={24}>
                <div>
                  <Form.Item
                    name="args"
                    label="Constructor Arguments"
                    rules={[
                      {
                        validator: (_, values) => {
                          if (!values) {
                            return Promise.resolve();
                          } else {
                            try {
                              const a = JSON.parse(values);
                              return Promise.resolve();
                            } catch (e) {
                              return Promise.reject(
                                `Invalid constructor arguments. Value must be array with JSON format. For example: ["0xbd9ebfe0e6e909e56f1fd3346d0118b7db49ca15", 40]`
                              );
                            }
                          }
                        },
                      },
                    ]}
                  >
                    <InputStyled
                      placeholder={`eg: ["0xa52bfa16d7d5af6c976bc33774cf4c418e51edc", 40]`}
                    />
                  </Form.Item>
                </div>
              </Col>
            </Row>
            <Title className="bold">Solidity Source Code</Title>
            <RadioStyled>
              <Radio.Group
                options={radioOptions}
                onChange={onChangeRadio}
                value={typeSource}
                optionType="button"
              />
            </RadioStyled>
            {typeSource === "default" && (
              <Row>
                <Form.List name="source_code">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <FieldExtend key={`code-${key}`}>
                          <Form.Item
                            {...restField}
                            name={[name, "file_name"]}
                            label="File name"
                            rules={[
                              {
                                required: true,
                                message: "Please enter file name",
                              },
                            ]}
                          >
                            <InputStyled placeholder="Enter file name. eg: ERC20.sol" />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, "content"]}
                            label="Code"
                            rules={[
                              {
                                required: true,
                                message: "Please enter source code",
                              },
                            ]}
                          >
                            <TextAreaStyled
                              rows={10}
                              placeholder="Solidity source code"
                            />
                          </Form.Item>
                          <MinusCircleOutlined onClick={() => remove(name)} />
                        </FieldExtend>
                      ))}
                      <Form.Item>
                        <AddSource
                          type="dashed"
                          onClick={() => add()}
                          block
                          icon={<PlusOutlined />}
                        >
                          Add
                        </AddSource>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </Row>
            )}
            {typeSource === "upload" && (
              <Row gutter={24}>
                <Col span={24}>
                  <UploadStyled>
                    <TextOverlay className="block" style={{ marginBottom: 10 }}>
                      Select the directory containing the contract file .sol
                    </TextOverlay>
                    <Upload
                      action={(file) => {
                        return new Promise((resolve) => resolve("ok"));
                      }}
                      accept=".sol"
                      directory
                      onChange={fileOnChange}
                    >
                      <Button icon={<UploadOutlined />}>
                        Upload Directory
                      </Button>
                    </Upload>
                  </UploadStyled>
                </Col>
              </Row>
            )}
            <Title className="bold">Contract Library</Title>
            <Row>
              <Form.List name="contract_library_address">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <FieldExtend key={`lib-${key}`}>
                        <Form.Item
                          {...restField}
                          name={[name, "file_name"]}
                          label="File name"
                          rules={[
                            {
                              required: true,
                              message: "Please enter file name",
                            },
                          ]}
                        >
                          <InputStyled placeholder="Enter library name. eg: Utils.sol" />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, "library_name"]}
                          label="Library name"
                          rules={[
                            {
                              required: true,
                              message: "Please enter library name",
                            },
                          ]}
                        >
                          <InputStyled placeholder="Enter library name. eg: Utils" />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, "library_address"]}
                          label="Library address"
                          rules={[
                            {
                              required: true,
                              message: "Please enter library address",
                            },
                          ]}
                        >
                          <InputStyled placeholder="Enter library address" />
                        </Form.Item>
                        <MinusCircleOutlined onClick={() => remove(name)} />
                      </FieldExtend>
                    ))}
                    <Form.Item>
                      <AddSource
                        type="dashed"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                      >
                        Add
                      </AddSource>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Row>
            <Form.Item>
              <ButtonSubmit type="submit" value="Submit" disabled={loading} />
              {loading && <Spin size="small" style={{ color: "#A93EC1" }} />}
            </Form.Item>
          </Form>
          {mgs.length > 0 && (
            <>
              <StatusStyled status={mgs[0].status}>{mgs[0].mgs}</StatusStyled>
              <NotifyRender args={mgs[0]?.errors || ""} />
            </>
          )}
        </FormGroup>
      </Container>
    </Wrapper>
  );
}

const NotifyRender = ({ args }: { args: any }) => {
  return (
    <>
      {args &&
        args.length > 0 &&
        args.map((item: any, key: number) => {
          return (
            <TextOverlay key={key} className="block">
              {JSON.stringify(item)}
            </TextOverlay>
          );
        })}
    </>
  );
};

const HeaderStyled = styled.div`
  text-align: center;
  margin-bottom: 20px;
  padding-left: 24px;
  padding-right: 24px;
`;

const SubStyled = styled.div`
  color: ${({ theme }) => theme.textOverlay};
`;
const FormGroup = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.bg5};
  padding: 24px;
  border-radius: 8px;
  max-width: 960px;
  margin: 0 auto;
  .ant-form-item-label {
    label {
      color: ${({ theme }) => theme.text};
      font-weight: 600;
    }
  }
  .ant-select: not(.ant-select-customize-input) .ant-select-selector {
    background-color: ${({ theme }) => theme.bg5};
    border-color: ${({ theme }) => theme.border2};
    color: ${({ theme }) => theme.textOverlay};
  }
  .ant-select-arrow {
    color: ${({ theme }) => theme.textOverlay};
  }
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    border-color: ${({ theme }) => theme.border};
  }
  .ant-btn-dashed {
    border-color: ${({ theme }) => theme.border2};
  }
  .ant-btn-dashed:hover,
  .ant-btn-dashed:focus {
    color: ${({ theme }) => theme.text};
    background-color: ${({ theme }) => theme.bg5};
    border-color: ${({ theme }) => theme.border};
  }
`;
const RadioStyled = styled.div`
  margin-bottom: 30px;
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
  .ant-radio-button-wrapper {
    color: #fff;
    background-color: ${({ theme }) => theme.bg10};
    border: none;
  }
  .ant-radio-button-wrapper {
    &.ant-radio-button-wrapper-checked {
      background-color: ${({ theme }) => theme.primary};
      &:focus {
        box-shadow: none;
      }
    }
    &:focus {
      box-shadow: none;
    }
  }
`;
const UploadStyled = styled.div`
  margin-bottom: 30px;
  button {
    background-color: ${({ theme }) => theme.bg10};
    border: none;
    color: #fff;
    &:hover,
    &:focus {
      background-color: ${({ theme }) => theme.bg10};
      color: #fff;
    }
  }
  .ant-upload-list-item {
    height: 30px;
  }
  .ant-upload-list-item-info {
    // padding: 10px;
    border-radius: 4px;
  }
  .ant-upload-span,
  .ant-upload-list-item-info .ant-upload-text-icon .anticon,
  .ant-upload-list-item-info .anticon-loading .anticon,
  .anticon-delete {
    color: ${({ theme }) => theme.text};
  }
  .ant-upload-list-item {
    &:hover {
      .ant-upload-list-item-info {
        background-color: ${({ theme }) => theme.bg10};
      }
    }
  }
`;
const FieldExtend = styled.div`
  background-color: transparent;
  border-radius: 16px;
  margin-bottom: 20px;
  width: 100%;
  .anticon-minus-circle {
    color: ${({ theme }) => theme.textOverlay};
  }
`;

const AddSource = styled(Button)`
  background-color: ${({ theme }) => theme.bg2};
  color: ${({ theme }) => theme.text};
  font-weight: 500;
  &:hover {
    background-color: ${({ theme }) => theme.bg2};
  }
`;

const TextAreaStyled = styled(TextArea)`
  background-color: ${({ theme }) => theme.bg8};
  border: 1px solid ${({ theme }) => theme.border2};
  color: ${({ theme }) => theme.textOverlay};
  &.ant-input:focus,
  &.ant-input-focused,
  &.ant-input:hover {
    border-color: ${({ theme }) => theme.border};
  }
`;

const ButtonSubmit = styled(Input)`
  cursor: pointer;
  width: auto;
  padding: 5px 40px;
  font-weight: 500;
  background-color: ${({ theme }) => theme.primary};
  color: #fff;
  border: none;
  margin-right: 20px;
  &.ant-input[disabled] {
    background-color: ${({ theme }) => theme.textOverlay};
  }
`;

const StatusStyled = styled.p<{ status: string }>`
  color: ${({ theme, status }) => theme[status]};
  font-weight: 500;
`;
