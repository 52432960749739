import React, { useEffect, useState, useMemo } from "react";
import styled from "styled-components";
import { useGetTokenHolderQuery } from "app/services/token";
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
import Title from "antd/lib/skeleton/Title";
import { TextMain, TextOverlay, TitleStyle } from "common/styledGeneral";
import { formatNumber, timeFormatDistance, truncate } from "utils";
import BigNumber from "bignumber.js";
import { Col, Row, Select, Space } from "antd";
// import DataTable from "common/DataTable";
import { tokenDistributionColumns } from "features/tokens/columnsConfig";
import LinkFormat from "common/LinkFormat";
import { ADDRESS_MAPPER } from "constants/address_mapper";
import DataTable from "./TableStyled";

const COLORS = [
  "#51A0C0",
  "#99DCF7",
  "#B8E7F9",
  "#CCEEFB",
  "#E0F5FD",
  "#CCEEFB",
  "#E0F5FD",
];

export function HolderPieChart({
  address,
  totalSupply,
}: {
  address: string;
  totalSupply: number;
}) {
  const [args, setArgs] = useState({ address: "", offset: 0, limit: 10 });
  const [topInfo, setTopInfo] = useState({
    address: "Other",
    amount: 0,
    ratio: 0,
    decimals: 0,
  });
  const { data, error, isLoading } = address
    ? useGetTokenHolderQuery({ ...args, address })
    : { data: null, error: null, isLoading: false };

  const handleChange = (value: any) => {
    setArgs({ ...args, limit: value?.value || 10 });
  };
  useEffect(() => {
    if (data?.data?.items && totalSupply) {
      let a = 0;
      let b = 0;
      data.data.items.map((item: any) => {
        a += item.amount;
        b += item.ratio;
      });

      if (totalSupply > a || b <= 100) {
        setTopInfo({
          ...topInfo,
          amount: totalSupply - a,
          ratio: 100 - b,
          decimals: data.data.items[0].decimals,
        });
      }
    }
  }, [data]);
  const newData = useMemo(() => {
    if (data?.data?.items && topInfo.amount > 0 && topInfo.ratio > 0) {
      return [...data.data.items, topInfo];
    }
    return data?.data?.items || [];
  }, [data, topInfo]);
  return (
    <>
      <Space
        style={{
          padding: "5px 20px",
          backgroundColor: "#281B29",
          marginBottom: 20,
          marginTop: 10,
          borderRadius: 5,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <TextMain className="bold block">Token Distribution</TextMain>
        <Select
          dropdownClassName="st-dropdown-theme"
          defaultValue={10}
          labelInValue={true}
          onChange={handleChange}
          options={[
            {
              value: 10,
              label: "Top 10",
            },
            {
              value: 20,
              label: "Top 20",
            },
            {
              value: 50,
              label: "Top 50",
            },
            {
              value: 100,
              label: "Top 100",
            },
          ]}
        />
      </Space>
      <Row gutter={24}>
        <Col span={24} md={12}>
          {/* <DataTable data={newData || []} columns={tokenDistributionColumns} /> */}
          {/* <BoxList>
            <Space>
              <ItemStyled>
                <TextOverlay>Account</TextOverlay>
              </ItemStyled>
              <TextOverlay>Percentage(%)</TextOverlay>
            </Space>
            {newData &&
              newData.map((item: any, key: number) => {
                return (
                  <Space key={key}>
                    <ItemStyled style={{ display: "inline-block", width: 120 }}>
                      <LinkFormat
                        title={ADDRESS_MAPPER[item.address.toLowerCase()] || ""}
                        type="token"
                        value={item.address}
                        truncate={true}
                        position="top"
                      />
                    </ItemStyled>
                    <TextMain>{item.ratio}</TextMain>
                  </Space>
                );
              })}
            ;
          </BoxList> */}
          <DataTable
            columns={tokenDistributionColumns}
            data={newData}
            loading={isLoading}
          />
        </Col>
        <Col span={24} md={12}>
          <ResponsiveContainer height={300}>
            <PieChart>
              <Pie
                dataKey="ratio"
                data={newData || []}
                startAngle={90}
                endAngle={-450}
                innerRadius={70}
                outerRadius={120}
              >
                {newData &&
                  newData.length > 0 &&
                  newData.map((v: any, index: number) => {
                    if (index < 7) {
                      return (
                        <Cell
                          key={`cell-${index}`}
                          fill={COLORS[index % COLORS.length]}
                        />
                      );
                    } else {
                      if (index === newData.length - 1) {
                        return <Cell key={`cell-${index}`} fill="#4A2F4A" />;
                      } else if (index % 2 === 0) {
                        return <Cell key={`cell-${index}`} fill="#CCEEFB" />;
                      } else {
                        return <Cell key={`cell-${index}`} fill="#E0F5FD" />;
                      }
                    }
                  })}
              </Pie>
              <Tooltip content={<TooltipChart />} />
            </PieChart>
          </ResponsiveContainer>
        </Col>
      </Row>
    </>
  );
}

const TooltipChart = ({ active, payload }: any) => {
  if (active && payload && payload.length) {
    return (
      <TooltipWrap>
        <Space style={{ display: "flex" }}>
          {payload[0].payload.address !== "Other" && (
            <TextOverlay>#{payload[0].payload.rank}.</TextOverlay>
          )}
          <TextMain>{truncate(payload[0].payload.address, "", 9)}</TextMain>
        </Space>
        <Space style={{ display: "flex" }}>
          <TextOverlay>Balance: </TextOverlay>
          <TextMain>
            {formatNumber(
              new BigNumber(payload[0].payload.amount)
                .dividedBy(10 ** payload[0].payload.decimals)
                .toString()
            )}
          </TextMain>
        </Space>
        <Space style={{ display: "flex" }}>
          <TextOverlay>Percentage: </TextOverlay>
          <TextMain>{formatNumber(payload[0].payload.ratio, 8)}%</TextMain>
        </Space>
      </TooltipWrap>
    );
  }
  return null;
};

const TooltipWrap = styled.div`
  background-color: ${({ theme }) => theme.bg1};
  padding: 10px 15px;
  border-radius: 8px;
`;

const BoxList = styled.div``;

const ItemStyled = styled.div`
  padding: 5px 0;
  display: inline-block;
  width: 120px;
`;
