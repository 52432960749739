import React, { useState } from "react";
import styled from "styled-components";
import { Col, Row, Space, Tooltip } from "antd";
// import AceEditor from "react-ace";
import {
  CheckCircleFilled,
  ProfileOutlined,
  CodeOutlined,
  FileDoneOutlined,
  DeploymentUnitOutlined,
  CodeFilled,
} from "@ant-design/icons";

import { Title, PreStyled } from "./styled";
import { TextMain, TextOverlay } from "common/styledGeneral";
import CopyIcon from "common/CopyIcon";
import { formatUtc } from "utils";

function Code({ data }: { data: any }) {
  const [isByteCode, setIsByteCode] = useState(true);
  const solidityCode =
    data && data.sourceCode ? Object.entries(data.sourceCode) : [];
  return (
    <>
      <Title>
        <TextMain>
          <CheckCircleFilled color="#00E8B4" /> Contract Source Code Verified
        </TextMain>
        {data?.verifyTime && (
          <TextOverlay
            style={{ fontWeight: "normal", fontSize: 12, paddingLeft: 20 }}
          >
            Verified at {formatUtc(data.verifyTime)}
          </TextOverlay>
        )}
      </Title>
      <BoxContent>
        <Row gutter={24}>
          <Col span={24} lg={8}>
            <Row gutter={24}>
              <Col span={10}>
                <TextMain className="mt-1">Contract Name:</TextMain>
              </Col>
              <Col span={14}>
                <TextMain className="mt-1 bold">{data.contractName}</TextMain>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={10}>
                <TextMain className="mt-1">Compiler Type:</TextMain>
              </Col>
              <Col span={14}>
                <TextMain className="mt-1 bold">
                  {data.compilerType ? data.compilerType : "--"}
                </TextMain>
              </Col>
            </Row>
          </Col>
          <Col span={24} lg={8}>
            <Row gutter={24}>
              <Col span={10}>
                <TextMain className="mt-1">Compiler Version:</TextMain>
              </Col>
              <Col span={14}>
                <TextMain className="mt-1 bold">{data.compiler}</TextMain>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={10}>
                <TextMain className="mt-1">Optimization:</TextMain>
              </Col>
              <Col span={14}>
                <TextMain className="mt-1 bold">
                  {data.optimization ? "Yes" : "No"}
                </TextMain>
              </Col>
            </Row>
          </Col>
          <Col span={24} lg={8}>
            <Row gutter={24}>
              <Col span={10}>
                <TextMain className="mt-1">Constructor Args:</TextMain>
              </Col>
              <Col span={14}>
                <TextMain className="mt-1 word-break bold">
                  {data.constructorArgs
                    ? `[${data.constructorArgs.toString()}]`
                    : "--"}
                </TextMain>
              </Col>
            </Row>
          </Col>
        </Row>
      </BoxContent>
      <Box>
        <Title style={{ paddingBottom: 0 }}>
          <ProfileOutlined style={{ color: "#836c87" }} /> Contract Source Code
        </Title>
        <BoxContent>
          {solidityCode.length > 0 &&
            solidityCode.map((value: any, i: any) => {
              return <CodeRender key={`code-${i}`} data={value} />;
            })}
        </BoxContent>
      </Box>
      {data?.abi && (
        <Box>
          <Title
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginRight: 20,
            }}
          >
            <div>
              <CodeOutlined style={{ color: "#836c87" }} /> Contract ABI
            </div>
            <CopyIcon value={JSON.stringify(data.abi)} />
          </Title>
          <BoxContent>
            <PreStyled height="10pc">{JSON.stringify(data.abi)}</PreStyled>
          </BoxContent>
        </Box>
      )}
      <Box>
        <Title
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginRight: 20,
          }}
        >
          <div>
            <FileDoneOutlined style={{ color: "#836c87" }} /> Contract Creation
            Code
          </div>
          <SwitchButton
            onClick={() => setIsByteCode(!isByteCode)}
          >{`Switch To ${
            isByteCode ? "Opcodes View" : "Bytecodes View"
          }`}</SwitchButton>
        </Title>
        <BoxContent>
          <PreStyled height="10pc">
            {isByteCode ? data.bytecode.object : data.bytecode.opcodes}
          </PreStyled>
        </BoxContent>
      </Box>
      {data?.deployedBytecode?.sourceMap && (
        <Box>
          <Title>
            <DeploymentUnitOutlined style={{ color: "#836c87" }} /> Deployed
            ByteCode Sourcemap
          </Title>
          <BoxContent>
            <PreStyled height="6pc">
              {data.deployedBytecode.sourceMap}
            </PreStyled>
          </BoxContent>
        </Box>
      )}
    </>
  );
}

const CodeRender = ({ data }: any) => {
  return (
    <>
      <Title
        style={{
          marginTop: "20px",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <CodeFilled style={{ color: "#836c87" }} /> {data[0]}{" "}
        </div>
        <CopyIcon value={data[1].content} />
      </Title>
      <BoxContent style={{ paddingLeft: 0, paddingRight: 0 }}>
        <PreStyled height="16pc">{data[1].content}</PreStyled>
      </BoxContent>
      {/* <AceEditor
        width="100%"
        height="300px"
        readOnly={true}
        value={data[1].content}
        showPrintMargin={false}
      /> */}
    </>
  );
};

const Box = styled.div`
  padding-top: 20px;
`;

const BoxContent = styled.div`
  padding-left: 20px;
  padding-right: 20px;
  .ace_content {
    background-color: ${({ theme }) => theme.bg2};
    color: ${({ theme }) => theme.textOverlay};
  }
  .ace_gutter-cell {
    color: ${({ theme }) => theme.textOverlay};
  }
  .ace-tm .ace_gutter {
    background-color: ${({ theme }) => theme.bg3};
  }
  .ace-tm .ace_gutter-active-line {
    background-color: ${({ theme }) => theme.bg10};
  }
  .ace-tm .ace_marker-layer .ace_selection {
    background-color: #2c182d;
  }
  .ace_text-layer {
    width: 100%;
  }
`;

const SwitchButton = styled.div`
  color: ${({ theme }) => theme.textOverlay};
  font-weight: normal;
  font-size: 12px;
  margin-right: 10px;
  padding: 5px 10px;
  border: 1px solid ${({ theme }) => theme.border2};
  border-radius: 5px;
  background-color: ${({ theme }) => theme.bg1};
  cursor: pointer;
`;
export default Code;
