import React from "react";
import BigNumber from "bignumber.js";
import { Space } from "antd";
import { Link } from "react-router-dom";
import { CopyrightOutlined } from "@ant-design/icons";

import { addressCheckSum, formatNumber } from "utils";
import LinkFormat from "common/LinkFormat";
import { TextMain } from "common/styledGeneral";
import { Up, Down } from "common/Tag";
import { ADDRESS_MAPPER } from "constants/address_mapper";
import iconFailed from "assets/images/icon-failed.svg";

export const Columns = [
  {
    title: "#",
    dataIndex: "rank",
    render: (rank: number) => <TextMain>{rank}</TextMain>,
  },
  {
    title: "TOKEN",
    dataIndex: "name",
    width: "310px",
    render: (name: string, record: any) => (
      <Link to={`/token/${record.address}`}>
        <Space>
          {record.logoURI ? (
            <img width="24" src={record.logoURI} />
          ) : (
            <CopyrightOutlined style={{ fontSize: "24px" }} />
          )}
          {name}
        </Space>
      </Link>
    ),
  },
  {
    title: "",
    dataIndex: "symbol",
    render: (symbol: string) => <TextMain>{symbol}</TextMain>,
  },
  {
    title: "HOLDER",
    dataIndex: "holder",
    render: (holder: string) => (
      <TextMain>{holder ? formatNumber(holder) : ""}</TextMain>
    ),
    sorter: {},
    sortDirections: ["descend", "ascend", "descend"],
  },
  {
    title: "PRICE",
    dataIndex: "price",
    render: (price: number) => (
      <TextMain>{price ? formatNumber(price) : ""}</TextMain>
    ),
    sorter: {},
    sortDirections: ["descend", "ascend", "descend"],
  },
  {
    title: "CHANGE 24H(%)",
    dataIndex: "priceChangePercentage24h",
    render: (change: number) => {
      return (
        <>
          {change > 0 && <Up number={formatNumber(change, 2)} />}
          {change < 0 && <Down number={formatNumber(change, 2)} />}
          {change === 0 && <TextMain>{change}%</TextMain>}
        </>
      );
    },
    sorter: {},
    sortDirections: ["descend", "ascend", "descend"],
  },
  // {
  //   title: "Total Supply",
  //   dataIndex: "totalSupply",
  //   render: (totalSupply: number, record: any) =>
  //     totalSupply &&
  //     record.decimals && (
  //       <TextMain>
  //         {formatNumber(
  //           new BigNumber(totalSupply)
  //             .dividedBy(10 ** record.decimals)
  //             .toString()
  //         )}
  //       </TextMain>
  //     ),
  // },
  {
    title: "MARKETCAP",
    dataIndex: "fd",
    sorter: {},
    sortDirections: ["descend", "ascend", "descend"],
    render: (fd: number, record: any) =>
      fd > 0 &&
      record.decimals && (
        <TextMain>
          {formatNumber(
            new BigNumber(fd).dividedBy(10 ** record.decimals).toString()
          )}
        </TextMain>
      ),
  },
];

export const txColumnsToken = [
  {
    title: "TX HASH",
    dataIndex: "transactionHash",
    render: (hash: string) => <LinkFormat type="tx" value={hash} copy={true} />,
    // <div className="d-flex">
    //   <img style={{ marginRight: "5px" }} width="24" src={iconFailed} />
    //   <LinkFormat type="tx" value={hash} truncate={false} />
    // </div>
  },
  {
    title: "TIME",
    dataIndex: "timestamp",
    ellipsis: true,
    width: "180px",
  },
  {
    title: "METHOD",
    dataIndex: "type",
    width: "120px",
    ellipsis: true,
    render: (type: string) => (
      <TextMain style={{ textTransform: "capitalize" }}>{type}</TextMain>
    ),
  },
  {
    title: "FROM",
    dataIndex: "from",
    render: (from: string) => (
      <LinkFormat
        title={ADDRESS_MAPPER[from.toLowerCase()] || ""}
        type="address"
        copy={true}
        value={addressCheckSum(from)}
      />
    ),
  },
  {
    title: "TO",
    dataIndex: "to",
    render: (to: string) => (
      <LinkFormat
        title={ADDRESS_MAPPER[to.toLowerCase()] || ""}
        type="address"
        copy={true}
        value={addressCheckSum(to)}
      />
    ),
  },
  {
    title: "QUANTITY",
    dataIndex: "amount",
    width: "150px",
    render: (amount: string, record: any) => (
      <TextMain>
        {formatNumber(
          new BigNumber(amount).dividedBy(10 ** record.decimals).toString()
        )}
      </TextMain>
    ),
  },
  {
    title: "FEE (NEON)",
    dataIndex: "fee",
    ellipsis: true,
    width: "150px",
    render: (fee: string) => (
      <TextMain>
        {formatNumber(new BigNumber(fee).dividedBy(10 ** 18).toString(), 9)}
      </TextMain>
    ),
  },
];

export const holderColumnsToken = [
  {
    title: "#",
    dataIndex: "rank",
    render: (rank: number) => <TextMain>{rank}</TextMain>,
    width: "60px",
  },
  {
    title: "ADDRESS",
    dataIndex: "address",
    render: (address: string) => (
      <LinkFormat
        title={ADDRESS_MAPPER[address.toLowerCase()] || ""}
        type="address"
        copy={true}
        value={addressCheckSum(address)}
      />
    ),
    width: "250px",
  },
  {
    title: "QUANTITY",
    dataIndex: "amount",
    render: (amount: string, record: any) => (
      <TextMain>
        {formatNumber(
          new BigNumber(amount).dividedBy(10 ** record.decimals).toString()
        )}
      </TextMain>
    ),
    width: "160px",
  },
  {
    title: "VALUE",
    dataIndex: "price",
    render: (price: string, record: any) =>
      price && (
        <TextMain>
          $
          {formatNumber(
            new BigNumber(record.amount)
              .dividedBy(10 ** record.decimals)
              .multipliedBy(price)
              .toString()
          )}
        </TextMain>
      ),
    width: "160px",
  },
  {
    title: "PERCENTAGE",
    dataIndex: "ratio",
    ellipsis: true,
    render: (ratio: number) => <TextMain>{formatNumber(ratio)}%</TextMain>,
    width: "160px",
  },
];

export const tokenDistributionColumns = [
  {
    title: "Account",
    dataIndex: "address",
    render: (address: string) => (
      <LinkFormat
        title={ADDRESS_MAPPER[address.toLowerCase()] || ""}
        type="address"
        value={address}
        truncate={true}
        position="top"
        copy={true}
      />
    ),
  },
  {
    title: "%",
    dataIndex: "ratio",
    render: (ratio: number) => <TextMain>{ratio}</TextMain>,
  },
];
