import React from "react";
import { Link } from "react-router-dom";
import BigNumber from "bignumber.js";
import { Tooltip } from "antd";
import { FileTextOutlined, FileDoneOutlined } from "@ant-design/icons";

import { addressCheckSum, formatNumber } from "utils";
import LinkFormat from "common/LinkFormat";
import { TextMain, TextOverlay } from "common/styledGeneral";
import { ADDRESS_MAPPER } from "constants/address_mapper";

export const Columns = [
  {
    title: "BLOCK",
    dataIndex: "number",
    render: (number: string) => <Link to={`/block/${number}`}>#{number}</Link>,
    width: "200px",
  },
  {
    title: "TIME",
    dataIndex: "timestamp",
    ellipsis: true,
    width: "200px",
  },
  {
    title: "TRANSACTIONS",
    dataIndex: "transactions",
    width: "200px",
  },
  {
    title: "GAS LIMIT",
    dataIndex: "gasLimit",
    render: (gasLimit: string) => formatNumber(gasLimit),
    width: "200px",
  },
  {
    title: "GAS USED",
    dataIndex: "gasUsed",
    width: "200px",
    render: (gasUsed: string, record: any) => (
      <TextMain>
        {formatNumber(gasUsed)}&nbsp;
        <TextOverlay>
          (
          {formatNumber(
            new BigNumber(gasUsed)
              .dividedBy(record.gasLimit)
              .multipliedBy(100)
              .toString(),
            2
          )}
          %)
        </TextOverlay>
      </TextMain>
    ),
  },
];

export const txColumns = [
  {
    title: "HASH",
    dataIndex: "hash",
    render: (hash: string) => (
      <LinkFormat type="tx" value={hash} truncate={false} copy={true} />
    ),
    width: "200px",
  },
  {
    title: "METHOD",
    dataIndex: "method",
    width: "120px",
    ellipsis: true,
  },
  {
    title: "BLOCK",
    dataIndex: "blockNumber",
    width: "110px",
    ellipsis: true,
    render: (blockNumber: number) => (
      <Link to={`/block/${blockNumber}`}>#{blockNumber}</Link>
    ),
  },
  {
    title: "TIME",
    dataIndex: "timestamp",
    width: "170px",
    ellipsis: true,
  },
  {
    title: "FROM",
    dataIndex: "from",
    width: "200px",
    ellipsis: true,
    render: (from: string) => (
      <LinkFormat
        title={ADDRESS_MAPPER[from.toLowerCase()] || ""}
        type="address"
        value={addressCheckSum(from)}
        copy={true}
        truncate={false}
      />
    ),
  },
  {
    title: "TO",
    dataIndex: "to",
    width: "200px",
    ellipsis: true,
    render: (to: string, record: any) =>
      to ? (
        <LinkFormat
          title={ADDRESS_MAPPER[to.toLowerCase()] || ""}
          type="address"
          value={addressCheckSum(to)}
          copy={true}
          truncate={false}
        />
      ) : record?.contractAddress ? (
        <Tooltip title={record.contractAddress} color="#4A2F4A">
          <Link to={`/address/${record.contractAddress}`}>
            <TextMain style={{ marginRight: 5, display: "inline-block" }}>
              <FileDoneOutlined />
            </TextMain>
            Contract Creation
          </Link>
        </Tooltip>
      ) : (
        ""
      ),
  },
  {
    title: "VALUE (NEON)",
    dataIndex: "value",
    ellipsis: true,
    render: (value: string) => (
      <TextMain>
        {formatNumber(new BigNumber(value).dividedBy(10 ** 18).toString())}
      </TextMain>
    ),
    width: "130px",
  },
  {
    title: "FEE (NEON)",
    dataIndex: "fee",
    width: "130px",
    ellipsis: true,
    render: (fee: number) =>
      fee && (
        <TextMain>
          {formatNumber(new BigNumber(fee).dividedBy(10 ** 18).toString(), 9)}
        </TextMain>
      ),
  },
];
