let API_URL: string | undefined,
  API_TESTNET_URL: string | undefined,
  API_DEVNET_URL: string | undefined;

const env = process.env;

if (env) {
  API_URL = env.REACT_APP_API_URL;
  API_TESTNET_URL = env.REACT_APP_API_TESTNET_URL;
  API_DEVNET_URL = env.REACT_APP_API_DEVNET_URL;
}

export { API_URL, API_TESTNET_URL, API_DEVNET_URL };
export const MAX_RECORD = 20000;
