import { Button } from "antd";
import styled from "styled-components";

export const TransactionLog = styled.div`
  border-top: 1px solid ${({ theme }) => theme.border2};
  padding-top: 10px;
  padding-bottom: 10px;
  &:first-child {
    border-top: 0px;
    padding-top: 0px;
  }
`;

export const DataType = styled.div`
  margin-bottom: 15px;
`;
export const ButtonType = styled.button`
  background-color: ${({ theme }) => theme.textOverlay};
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: inline-block;
  padding: 5px 10px;
  height: 20px;
  line-height: 10px;
  margin-right: 5px;
  &:last-child {
    margin-right: 0;
  }
  &.active {
    background-color: ${({ theme }) => theme.primary};
    color: #fff;
  }
`;

export const TxLogLoadMore = styled(Button)`
  margin-top: 20px;
  color: ${({ theme }) => theme.text};
  font-weight: bold;
  background-color: ${({ theme }) => theme.primary};
  border-radius: 8px;
  border: none;
  &:hover,
  &:focus {
    color: ${({ theme }) => theme.text};
    background-color: ${({ theme }) => theme.textHover};
  }
`;

export const ViewTxsSolana = styled(Button)`
  background-color: transparent;
  padding: 0;
  border: none;
  height: auto;
  margin-left: 10px;
  display: block;
  &:hover {
    span {
      text-decoration: underline;
    }
  }
  &:hover,
  &:focus {
    background-color: transparent;
  }
  @media (max-width: 991px) {
    margin-left: 0;
  }
`;
export const LogoSolana = styled.img`
  width: 12px;
  height: 12px;
  border-radius: 24px;
`;
