import React, { useMemo } from "react";
import { Table, Skeleton } from "antd";
import styled from "styled-components";
import { parserCol } from "common/TableAccountTxs/configColLoading";
import { TitleStyle } from "./styledGeneral";

const SkeletonTable = ({
  type,
  record,
  loading,
}: {
  type: string;
  record: number;
  loading: boolean;
}) => {
  const data = useMemo(() => {
    const result = Array.from({ length: record }, (_, index) => ({
      key: index + 1,
      name: "",
      age: "",
      address: "",
    }));
    return result;
  }, [record]);
  const columns = parserCol(type, loading);
  return (
    <TableSkeletonStyled>
      <Table
        className="style-table-bordered"
        columns={columns}
        dataSource={data || []}
        size="middle"
        scroll={{ x: columns.length > 5 ? 900 : 450 }}
        pagination={false}
      />
    </TableSkeletonStyled>
  );
};

const TableSkeletonStyled = styled.div`
  .ant-skeleton-element {
    width: 100%;
    .ant-skeleton-input {
      border-radius: 4px;
    }
  }
`;

export default SkeletonTable;
