import React from "react";
import styled from "styled-components";
import { TitleStyle } from "common/styledGeneral";

interface PageTitleProps {
  title: string;
  fontSize?: any;
}

export function PageTitle({ title, fontSize }: PageTitleProps) {
  return (
    <TitleStyle level={2} className={"uppercase font-" + fontSize}>
      {title}
    </TitleStyle>
  );
}
