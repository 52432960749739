import styled from "styled-components";
import { Form, Input, Button, InputNumber } from "antd";

export const Title = styled.div`
  font-weight: bold;
  font-size: 16px;
  padding-bottom: 15px;
  color: ${({ theme }) => theme.text};
  .anticon {
    color: ${({ theme }) => theme.success};
  }
`;

export const PreStyled = styled.pre<{ height?: string }>`
  background-color: ${({ theme }) => theme.bg5};
  white-space: pre-wrap;
  height: ${({ height }) => (height ? height : "20px")};
  padding: 10px;
  word-wrap: break-word;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.borderOverlay1};
  font-size: 12px;
  color: ${({ theme }) => theme.text3};
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: ${({ theme }) => theme.bg7};
  }
  ::-webkit-scrollbar {
    width: 12px;
    background-color: ${({ theme }) => theme.bg7};
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: ${({ theme }) => theme.bg1};
  }
`;

export const FormGroup = styled.div`
  label {
    font-weight: 500;
    color: ${({ theme }) => theme.text};
  }
  .ant-form .anticon-minus-circle {
    color: ${({ theme }) => theme.textOverlay};
  }
`;

export const FormItem = styled(Form.Item)`
  margin-bottom: 0;
  border-radius: 5px;
`;
export const InputStyled = styled(Input)`
  background-color: ${({ theme }) => theme.bg8};
  border: 1px solid ${({ theme }) => theme.borderOverlay1};
  color: ${({ theme }) => theme.textOverlay};
  border-radius: 4px;
  margin-bottom: 15px;
  &.ant-input:focus,
  &.ant-input-focused,
  &.ant-input:hover {
    border-color: ${({ theme }) => theme.border};
  }
`;

export const InputNumberStyled = styled(InputNumber)`
  background-color: ${({ theme }) => theme.bg8};
  border: 1px solid ${({ theme }) => theme.borderOverlay1};
  color: ${({ theme }) => theme.textOverlay};
  border-radius: 4px;
  margin-bottom: 15px;
  &.ant-input-number:focus,
  &.ant-input-number:hover {
    border-color: ${({ theme }) => theme.border};
  }
  .ant-input-number-handler-wrap {
    background-color: ${({ theme }) => theme.bg8};
    .anticon {
      color: ${({ theme }) => theme.text};
    }
    .ant-input-number-handler {
      border-color: ${({ theme }) => theme.borderOverlay1};
    }
  }
`;

export const ButtonStyled = styled(Button)`
  border: 1px solid ${({ theme }) => theme.borderOverlay1};
  background-color: ${({ theme }) => theme.bg6};
  padding: 5px 15px;
  margin-top: 10px;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.bg6};
  }
  &.ant-btn:hover,
  &.ant-btn:focus,
  &.ant-btn:active {
    border-color: ${({ theme }) => theme.borderOverlay1};
  }
  &.ant-btn[disabled],
  &.ant-btn[disabled]:hover,
  &.ant-btn[disabled]:focus,
  &.ant-btn[disabled]:active {
    background-color: ${({ theme }) => theme.bg7};
  }
`;
