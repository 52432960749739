import React from "react";
import { BigNumber } from "bignumber.js";
import { Link } from "react-router-dom";
import { Row, Col, Space, Divider } from "antd";

import { formatNumber } from "utils";

import { Up, Down } from "common/Tag";
import { CopyrightOutlined } from "@ant-design/icons";
import {
  BoxStyleCommon,
  TextMain,
  TextOverlay,
  TokenItem,
  TitleStyle,
} from "common/styledGeneral";
import DataTableLoading from "common/DataTable/DataTableLoading";

interface TokenMobileProps {
  loading?: boolean;
  total?: null | number;
  data: any;
}

export function LayoutTokenMB({ data, loading }: TokenMobileProps) {
  if (loading)
    return (
      <BoxStyleCommon>
        <TitleStyle level={2} className="uppercase font-18">
          TOKENS
        </TitleStyle>
        <DataTableLoading />
      </BoxStyleCommon>
    );
  return (
    <>
      {data?.items &&
        data.items.length > 0 &&
        data.items.map((item: any, key: number) => {
          return (
            <TokenItem key={item.number}>
              <Row gutter={[24, 12]}>
                <Col span={24}>
                  <Space wrap>
                    <TextOverlay>#{item.number}</TextOverlay>
                    <Link to={`/token/${item.address}`}>
                      <Space size={3}>
                        {item?.logoURI ? (
                          <img width="24" src={item.logoURI} />
                        ) : (
                          <CopyrightOutlined style={{ fontSize: "24px" }} />
                        )}{" "}
                        {item.name}
                      </Space>
                    </Link>
                    <Divider type="vertical" />
                    <TextOverlay>{item.symbol}</TextOverlay>
                  </Space>
                </Col>
                <Col span={24}>
                  <Divider className="my-0" />
                </Col>
                {!!item?.price && (
                  <Col span={24}>
                    <Space>
                      <TextMain>
                        <TextOverlay>Price</TextOverlay> $
                        {formatNumber(item.price)}
                      </TextMain>
                      <Divider type="vertical" />
                      <TextOverlay>
                        24h{" "}
                        {item.priceChangePercentage24h > 0 && (
                          <Up
                            number={formatNumber(
                              item.priceChangePercentage24h,
                              2
                            )}
                          />
                        )}
                        {item.priceChangePercentage24h < 0 && (
                          <Down
                            number={formatNumber(
                              item.priceChangePercentage24h,
                              2
                            )}
                          />
                        )}
                        {item.priceChangePercentage24h === 0 && (
                          <TextMain>{item.priceChangePercentage24h}%</TextMain>
                        )}
                      </TextOverlay>
                      {/* <Divider type="vertical" />
                    <TextOverlay>
                      7D <DownRearIcon number="7.12" />
                    </TextOverlay> */}
                    </Space>
                  </Col>
                )}
                {!!item?.fd && (
                  <Col span={24}>
                    <TextMain>
                      <TextOverlay>Marketcap</TextOverlay> $
                      {formatNumber(
                        new BigNumber(item.fd)
                          .dividedBy(10 ** item.decimals)
                          .toString()
                      )}
                    </TextMain>
                  </Col>
                )}
                {!!item?.holder && (
                  <Col span={24}>
                    <Space>
                      <TextMain>
                        <TextOverlay>Holders</TextOverlay>{" "}
                        {formatNumber(item.holder)}
                      </TextMain>
                    </Space>
                  </Col>
                )}
              </Row>
            </TokenItem>
          );
        })}
    </>
  );
}
