import React from "react";
import { ArrowRightOutlined } from "@ant-design/icons";
import { useGetAccountTokenTransferQuery } from "app/services/account";
import { neonColumnsTransfer } from "./columnsConfig";
import { WrapTable } from "common/TableAccountTxs/styled";
import ShowItemOfNumber from "common/TableAccountTxs/ShowItemOfTotal";
import SkeletonTable from "common/SkeletonTable";
import { DataTableNew } from "common/TableAccountTxs/Table";
import { LinkButton } from "features/extendPages/styled";

export function NeonTransfers({ address }: { address: string }) {
  const { data, isFetching } = useGetAccountTokenTransferQuery({
    offset: 0,
    limit: 20,
    address,
    type: "neon_transfer",
  });
  // check Current Address
  const newData =
    data?.data?.items &&
    data.data.items.length > 0 &&
    data.data.items.map((item: any) => {
      return { ...item, currentAddress: address };
    });
  return (
    <WrapTable>
      <div style={{ marginBottom: 20 }}>
        <ShowItemOfNumber total={data?.data?.total ? data.data.total : 0} />
      </div>
      {isFetching ? (
        <SkeletonTable type="tokenTxs" record={20} loading={isFetching} />
      ) : (
        <DataTableNew
          columns={neonColumnsTransfer}
          data={newData || []}
          loading={isFetching}
        />
      )}
      {data?.data?.total > 20 && (
        <div style={{ textAlign: "center" }}>
          <LinkButton to={`/internal-txs?acc=${address.toLowerCase()}`}>
            View more transaction <ArrowRightOutlined />
          </LinkButton>
        </div>
      )}
    </WrapTable>
  );
}
