import React, { useState } from "react";
import styled from "styled-components";
import { Space, Tooltip, Button } from "antd";
import { SwapRightOutlined } from "@ant-design/icons";

import { useGeneralContext } from "providers/general";
import { TextOverlay } from "common/styledGeneral";

export function SolTxs({ data, show }: { data: any; show: boolean }) {
  const { network } = useGeneralContext();
  let cluster: string = "";
  if (network !== "mainnet") cluster = `?cluster=${network}`;
  return (
    <SolWrap>
      {show &&
        data &&
        data.length > 0 &&
        data.map((item: string) => {
          return (
            <Space
              key={item}
              style={{ width: "100%", alignItems: "center", display: "flex" }}
            >
              <TextOverlay>
                <SwapRightOutlined />
              </TextOverlay>
              <Tooltip title={item} color="#4A2F4A">
                <SolanaLink
                  className="text-ellipsis"
                  href={`https://solscan.io/tx/${item}${cluster}`}
                  target="blank"
                >
                  {item}
                </SolanaLink>
              </Tooltip>
            </Space>
          );
        })}
    </SolWrap>
  );
}

const SolWrap = styled.div`
  display: block;
`;
const SolanaLink = styled.a`
  display: block;
  font-size: 13px;
  @media (max-width: 991px) {
    width: 70%;
  }
  @media (max-width: 767px) {
    width: 45%;
  }
  @media (max-width: 767px) {
    width: 250px;
  }
  &:hover {
    text-decoration: underline;
  }
`;
