import React, { useMemo } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import BigNumber from "bignumber.js";
import { Col, Divider, Dropdown, Row, Space, Spin } from "antd";
import { CaretDownOutlined, CopyrightOutlined } from "@ant-design/icons";

import {
  useGetAccountNftListQuery,
  useGetAccountTokenListQuery,
} from "app/services/account";
import { formatNumber } from "utils";
import {
  BoxScrollDropdown,
  DropdownToken,
  StyledBoxDropdown,
  TextMain,
  TextOverlay,
} from "common/styledGeneral";
// import { NoData } from "common/NoData";
import ListNft from "./AccountNfts";

export function AccountTokens({ address }: { address: string }) {
  const { data: nftData } = useGetAccountNftListQuery(address as string);

  const { data, error, isLoading } = address
    ? useGetAccountTokenListQuery(address)
    : { data: null, error: null, isLoading: false };

  let capTokenTotal = 0;
  data?.data?.items &&
    data.data.items.length > 0 &&
    data.data.items.map((item: any, key: number) => {
      return (capTokenTotal += new BigNumber(item.amount)
        .dividedBy(10 ** item.decimals)
        .multipliedBy(item?.price ? item.price : 0)
        .toNumber());
    });

  let capNftTotal = 0;
  nftData?.nftData?.items &&
    nftData.nftData.items.length > 0 &&
    nftData.nftData.items.map((item: any, key: number) => {
      return (capNftTotal += new BigNumber(item.amount)
        .multipliedBy(item?.price ? item.price : 0)
        .toNumber());
    });

  const totalToken = useMemo(() => {
    return (data?.data?.total || 0) + (nftData?.data?.total || 0);
  }, [data?.data?.total, nftData?.data?.total]);
  return (
    <Dropdown
      overlay={
        <>
          <ListToken
            tokenList={
              data?.data?.items && data.data.items.length > 0
                ? data.data.items
                : []
            }
            loading={isLoading}
            total={data?.data?.total || 0}
            nftData={nftData}
          />
        </>
      }
      trigger={["click"]}
      mouseEnterDelay={0}
      mouseLeaveDelay={0}
    >
      <StyledBoxDropdown onClick={(e) => e.preventDefault()}>
        <Row gutter={12} justify="space-between">
          <Col>
            <TextMain>
              {totalToken ? `${totalToken} Tokens` : "0 Token"}
            </TextMain>
          </Col>
          <Col>
            <Space>
              <TextOverlay>
                ${formatNumber(capTokenTotal + capNftTotal, 4)}
              </TextOverlay>
              {data?.data?.total > 0 || nftData?.data?.total > 0 ? (
                <>
                  <Divider type="vertical" />
                  <CaretDownOutlined />
                </>
              ) : (
                ""
              )}
            </Space>
          </Col>
        </Row>
      </StyledBoxDropdown>
    </Dropdown>
  );
}

const ListToken = ({
  tokenList,
  total,
  nftData,
  loading,
}: {
  tokenList: [];
  nftData: any;
  total: number;
  loading: boolean;
}) => {
  return (
    <DropdownToken>
      <BoxScrollDropdown>
        <>
          {tokenList.length > 0 ? (
            <Row gutter={24} className="title-token">
              <Col span={8}>
                <TextMain>{total > 1 ? `Tokens(${total})` : `Token`}</TextMain>
              </Col>
              <Col span={8}>
                <TextMain>Amount</TextMain>
              </Col>
              <Col span={8}>
                <TextMain>Value</TextMain>
              </Col>
            </Row>
          ) : (
            ""
          )}
          <div>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: 10,
                }}
              >
                <Spin />
              </div>
            ) : tokenList.length > 0 ? (
              tokenList.map((token: any, key: number) => {
                return (
                  <Row gutter={24} className="item-token" key={key}>
                    <Col span={8}>
                      <Link to={`/token/${token.tokenAddress}`}>
                        <Space>
                          {token.logoURI ? (
                            <img
                              width={24}
                              src={token.logoURI}
                              alt={token.tokenName}
                            />
                          ) : (
                            <CopyrightOutlined style={{ fontSize: "24px" }} />
                          )}
                          <div>
                            <TextMain className="inner-image">
                              {token.tokenName}
                            </TextMain>
                            {token.price && (
                              <TextOverlay className="font-12 block">
                                ${formatNumber(token.price)}
                              </TextOverlay>
                            )}
                          </div>
                        </Space>
                      </Link>
                    </Col>
                    <Col span={8}>
                      {token.amount && (
                        <TextOverlay>
                          {formatNumber(
                            new BigNumber(token.amount)
                              .dividedBy(10 ** token.decimals)
                              .toString()
                          )}
                        </TextOverlay>
                      )}
                    </Col>
                    <Col span={8}>
                      {token.price && token.amount && (
                        <TextOverlay>
                          $
                          {formatNumber(
                            new BigNumber(token.amount)
                              .dividedBy(10 ** token.decimals)
                              .multipliedBy(token.price)
                              .toString()
                          )}
                        </TextOverlay>
                      )}
                    </Col>
                  </Row>
                );
              })
            ) : (
              ""
              // <NoData />
            )}
          </div>
        </>
        {nftData?.data?.items && <ListNft data={nftData} />}
        {(nftData?.data?.total > 0 || total > 0) && (
          <ViewButton>
            <LinkStyled href="#tokensBalance">View All Here</LinkStyled>
          </ViewButton>
        )}
      </BoxScrollDropdown>
    </DropdownToken>
  );
};

const ViewButton = styled.div`
  text-align: center;
`;

const LinkStyled = styled.a`
  padding: 10px 0;
  display: block;
  background-color: ${({ theme }) => theme.bg10};
  font-weight: 600;
`;
