import React from "react";
import { Skeleton } from "antd";

const parserCol = (type: string, loading: boolean) => {
  // Transaction Column
  const txCol = [
    {
      title: "TX HASH",
      dataIndex: "hash",
      width: "180px",
      ellipsis: true,
      render: (text: string) => <Skeleton.Input active={loading} />,
    },
    {
      title: "METHOD",
      dataIndex: "method",
      width: "150px",
      ellipsis: true,
      render: (text: string) => <Skeleton.Input active={loading} />,
    },
    {
      title: "BLOCK",
      dataIndex: "blockNumber",
      ellipsis: true,
      width: "140px",
      render: (text: string) => <Skeleton.Input active={loading} />,
    },
    {
      title: "TIME",
      dataIndex: "timestamp",
      ellipsis: true,
      width: "180px",
      render: (text: string) => <Skeleton.Input active={loading} />,
    },
    {
      title: "FROM",
      dataIndex: "from",
      ellipsis: true,
      width: "200px",
      render: (text: string) => <Skeleton.Input active={loading} />,
    },
    {
      title: "TO",
      dataIndex: "to",
      ellipsis: true,
      width: "180px",
      render: (text: string) => <Skeleton.Input active={loading} />,
    },
    {
      title: "VALUE",
      dataIndex: "value",
      render: (text: string) => <Skeleton.Input active={loading} />,
      width: "130px",
    },
    {
      title: "FEE (NEON)",
      dataIndex: "fee",
      ellipsis: true,
      width: "110px",
      render: (text: string) => <Skeleton.Input active={loading} />,
    },
  ];
  // Token Txs Column
  const tokenTxsCol = [
    {
      title: "TX HASH",
      dataIndex: "transactionHash",
      width: "250px",
      render: (text: string) => <Skeleton.Input active={loading} />,
    },
    {
      title: "TIME",
      dataIndex: "timestamp",
      ellipsis: true,
      width: "200px",
      render: (text: string) => <Skeleton.Input active={loading} />,
    },
    {
      title: "FROM",
      dataIndex: "from",
      width: "170px",
      render: (text: string) => <Skeleton.Input active={loading} />,
    },
    {
      title: "TO",
      dataIndex: "to",
      width: "170px",
      render: (text: string) => <Skeleton.Input active={loading} />,
    },
    {
      title: "VALUE",
      dataIndex: "amount",
      width: "150px",
      render: (text: string) => <Skeleton.Input active={loading} />,
    },
    {
      title: "TOKEN",
      dataIndex: "tokenName",
      render: (text: string) => <Skeleton.Input active={loading} />,
      width: "230px",
    },
    {
      title: "FEE (NEON)",
      dataIndex: "fee",
      ellipsis: true,
      width: "110px",
      render: (text: string) => <Skeleton.Input active={loading} />,
    },
  ];
  // Neon Transfer
  const neonTxsCol = [
    {
      title: "TX HASH",
      dataIndex: "transactionHash",
      width: "250px",
      render: (text: string) => <Skeleton.Input active={loading} />,
    },
    {
      title: "TIME",
      dataIndex: "timestamp",
      ellipsis: true,
      width: "200px",
      render: (text: string) => <Skeleton.Input active={loading} />,
    },
    {
      title: "FROM",
      dataIndex: "from",
      width: "150px",
      render: (text: string) => <Skeleton.Input active={loading} />,
    },
    {
      title: "TO",
      dataIndex: "to",
      width: "150px",
      render: (text: string) => <Skeleton.Input active={loading} />,
    },
    {
      title: "VALUE",
      dataIndex: "amount",
      width: "150px",
      render: (text: string) => <Skeleton.Input active={loading} />,
    },
    {
      title: "TOKEN",
      dataIndex: "tokenName",
      render: (text: string) => <Skeleton.Input active={loading} />,
      width: "230px",
    },
    {
      title: "FEE (NEON)",
      dataIndex: "fee",
      ellipsis: true,
      width: "110px",
      render: (text: string) => <Skeleton.Input active={loading} />,
    },
  ];

  //Tokens in Account
  const tokenBalance = [
    {
      title: "",
      dataIndex: "tokenName",
      width: "310px",
      render: (text: string) => <Skeleton.Input active={loading} />,
    },
    {
      title: "",
      dataIndex: "symbol",
      render: (text: string) => <Skeleton.Input active={loading} />,
      width: "100px",
    },
    {
      title: "",
      dataIndex: "amountUi",
      render: (text: string) => <Skeleton.Input active={loading} />,
      width: "150px",
    },

    {
      title: "",
      dataIndex: "price",
      render: (text: string) => <Skeleton.Input active={loading} />,
      width: "150px",
    },
    {
      title: "",
      dataIndex: "value",
      render: (text: string) => <Skeleton.Input active={loading} />,
      width: "150px",
    },
  ];

  const txsHome = [
    {
      title: "HASH",
      dataIndex: "hash",
      ellipsis: true,
      render: (text: string) => <Skeleton.Input active={loading} />,
      width: "100px",
    },
    {
      title: "FROM",
      dataIndex: "from",
      ellipsis: true,
      render: (text: string) => <Skeleton.Input active={loading} />,
      width: "100px",
    },
    {
      title: "TO",
      dataIndex: "to",
      ellipsis: true,
      render: (text: string) => <Skeleton.Input active={loading} />,
      width: "100px",
    },
    {
      title: "Time",
      dataIndex: "timestamp",
      ellipsis: true,
      render: (text: string) => <Skeleton.Input active={loading} />,
      width: "100px",
    },
    {
      title: "FEE(NEON)",
      dataIndex: "fee",
      ellipsis: true,
      render: (text: string) => <Skeleton.Input active={loading} />,
      width: "100px",
    },
  ];
  const blockHome = [
    {
      title: "BLOCK ID",
      dataIndex: "number",
      ellipsis: true,
      render: (text: string) => <Skeleton.Input active={loading} />,
      width: "100px",
    },
    // {
    //   title: "OPERATOR",
    //   dataIndex: "hash",
    //   render: (hash: string) => (
    //     <LinkFormat type="tx" value={hash} truncate={false} />
    //   ),
    // },
    {
      title: "TX COUNT",
      dataIndex: "transactions",
      ellipsis: true,
      render: (text: string) => <Skeleton.Input active={loading} />,
      width: "100px",
    },
    {
      title: "AGE",
      dataIndex: "timestamp",
      ellipsis: true,
      render: (text: string) => <Skeleton.Input active={loading} />,
      width: "100px",
    },
  ];

  const blockPage = [
    {
      title: "BLOCK",
      dataIndex: "number",
      render: (text: string) => <Skeleton.Input active={loading} />,
      width: "200px",
    },
    {
      title: "TIME",
      dataIndex: "timestamp",
      ellipsis: true,
      render: (text: string) => <Skeleton.Input active={loading} />,
      width: "200px",
    },
    {
      title: "TRANSACTIONS",
      dataIndex: "transactions",
      render: (text: string) => <Skeleton.Input active={loading} />,
      width: "200px",
    },
    {
      title: "GAS LIMIT",
      dataIndex: "gasLimit",
      render: (text: string) => <Skeleton.Input active={loading} />,
      width: "200px",
    },
    {
      title: "GAS USED",
      dataIndex: "gasUsed",
      width: "200px",
      render: (text: string) => <Skeleton.Input active={loading} />,
    },
  ];

  const tokenPage = [
    {
      title: "#",
      dataIndex: "rank",
      render: (text: string) => <Skeleton.Input active={loading} />,
    },
    {
      title: "TOKEN",
      dataIndex: "name",
      width: "310px",
      render: (text: string) => <Skeleton.Input active={loading} />,
    },
    {
      title: "",
      dataIndex: "symbol",
      render: (text: string) => <Skeleton.Input active={loading} />,
    },
    {
      title: "HOLDER",
      dataIndex: "holder",
      render: (text: string) => <Skeleton.Input active={loading} />,
    },
    {
      title: "PRICE",
      dataIndex: "price",
      render: (text: string) => <Skeleton.Input active={loading} />,
    },
    {
      title: "CHANGE 24H(%)",
      dataIndex: "priceChangePercentage24h",
      render: (text: string) => <Skeleton.Input active={loading} />,
    },
    {
      title: "MARKETCAP",
      dataIndex: "fd",
      render: (text: string) => <Skeleton.Input active={loading} />,
    },
  ];

  const verifyContract = [
    {
      title: "#",
      dataIndex: "number",
      render: (text: string) => <Skeleton.Input active={loading} />,
      width: 80,
    },
    {
      title: "Address",
      dataIndex: "address",
      render: (text: string) => <Skeleton.Input active={loading} />,
      width: 200,
    },
    {
      title: "Contract Name",
      dataIndex: "contractName",
      render: (text: string) => <Skeleton.Input active={loading} />,
      width: 180,
    },

    {
      title: "Optimization",
      dataIndex: "optimization",
      render: (text: string) => <Skeleton.Input active={loading} />,
      width: 150,
    },
    {
      title: "Compiler Type",
      dataIndex: "compilerType",
      render: (text: string) => <Skeleton.Input active={loading} />,
      width: 150,
    },
    {
      title: "Compiler Version",
      dataIndex: "compiler",
      render: (text: string) => <Skeleton.Input active={loading} />,
      width: 200,
    },
    {
      title: "Txs Number",
      dataIndex: "txCount",
      render: (text: string) => <Skeleton.Input active={loading} />,
      width: 150,
    },
    {
      title: "Verified Time",
      dataIndex: "verifyTime",
      ellipsis: true,
      render: (text: string) => <Skeleton.Input active={loading} />,
      width: 200,
    },
  ];

  switch (type) {
    case "tx":
      return txCol;
    case "tokenTxs":
      return tokenTxsCol;
    case "neonTxs":
      return neonTxsCol;
    case "tokenBalance":
      return tokenBalance;
    case "txsHome":
      return txsHome;
    case "blockHome":
      return blockHome;
    case "blockPage":
      return blockPage;
    case "tokenPage":
      return tokenPage;
    case "verifyContract":
      return verifyContract;
    default:
      return txCol;
  }
};

export { parserCol };
