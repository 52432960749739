import React from "react";
import { Space, Button } from "antd";
import styled from "styled-components";
import {
  StepBackwardOutlined,
  LeftOutlined,
  RightOutlined,
  StepForwardOutlined,
} from "@ant-design/icons";

import { PaginationType } from "constants/types";
import { formatNumber } from "utils";
import { PaginationWrapper } from "common/DataTable/dataTableStyles";
import { ButtonStyled, CurrentNumber, TotalNumber } from "common/styledGeneral";

type DataTablePaginationProps = {
  changePage: Function;
  pagination: PaginationType;
  pages: null | number;
};

export default function DataTablePagination({
  changePage,
  pagination,
  pages,
}: DataTablePaginationProps) {
  // if (pages === 1) return null;

  return (
    <PaginationWrapper>
      <ButtonStyled
        disabled={pagination.currPage === 1}
        onClick={() => changePage("first")}
      >
        <StepBackwardOutlined />
      </ButtonStyled>
      <ButtonStyled
        disabled={pagination.currPage === 1}
        onClick={() => changePage("prev")}
      >
        <LeftOutlined />
      </ButtonStyled>
      <Space className="mx-05">
        <CurrentNumber>{formatNumber(pagination.currPage)}</CurrentNumber>
        <TotalNumber> of {pages && formatNumber(pages)} pages</TotalNumber>
      </Space>
      <ButtonStyled
        disabled={pagination.currPage === pages}
        onClick={() => changePage("next")}
      >
        <RightOutlined />
      </ButtonStyled>
      <ButtonStyled
        disabled={pagination.currPage === pages}
        onClick={() => changePage("last")}
      >
        <StepForwardOutlined />
      </ButtonStyled>
    </PaginationWrapper>
  );
}
