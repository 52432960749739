import React from "react";
import BigNumber from "bignumber.js";
import styled from "styled-components";
import { Skeleton } from "antd";
import {
  ResponsiveContainer,
  YAxis,
  Tooltip,
  XAxis,
  LineChart,
  Line,
  CartesianGrid,
} from "recharts";
import {
  dataFormater,
  formatUtc,
  timeFormatDistance,
  formatNumber,
} from "utils";
import { BoxChart, TextMain, TextOverlay } from "common/styledGeneral";
import { useGetOverviewStatsQuery } from "app/services/stats";

type OverviewStatsPros = {
  type: string;
};

export function OverviewStats({ type }: OverviewStatsPros) {
  const { data, error, isLoading } = useGetOverviewStatsQuery(type);

  const newTxChart =
    data?.data &&
    data.data.length > 0 &&
    data.data.map((item: any, key: number) => {
      const fee = new BigNumber(item.fee).dividedBy(10 ** 18).toString();
      const date = formatUtc(item.unixTime, type === "1D" ? "HH:ss" : "dd/MM");
      return { ...item, fee, date };
    });
  return (
    <>
      <TextOverlay className="mv-2">Total Transactions and Blocks</TextOverlay>
      {isLoading ? (
        <Skeleton />
      ) : (
        <BoxChartStyled>
          <ResponsiveContainer width="100%" height="100%" aspect={2.5}>
            <LineChart
              data={newTxChart ? newTxChart : []}
              margin={{
                top: 5,
                left: -10,
              }}
            >
              <CartesianGrid
                strokeWidth={0.1}
                strokeDasharray="7"
                vertical={false}
                stroke="#EAE7EA"
              />
              <XAxis
                dataKey="date"
                axisLine={false}
                tickLine={false}
                stroke="rgba(238, 200, 247, 0.6)"
                tickSize={10}
              />
              <YAxis
                tickFormatter={dataFormater}
                axisLine={false}
                tickLine={false}
                tickSize={10}
                stroke="rgba(238, 200, 247, 0.6)"
              />
              <Tooltip
                content={<TooltipContent />}
                cursor={{
                  stroke: "#A93EC1",
                  fill: "#A93EC1",
                }}
                // allowEscapeViewBox={{ y: true }}
                // position={{ y: -120 }}
              />
              <Line
                type="monotone"
                dataKey="totalTxs"
                stroke="#A93EC1"
                dot={{ stroke: "#A93EC1", strokeWidth: 3, r: 1 }}
              />
              <Line
                type="monotone"
                dataKey="totalBlock"
                stroke="#99DCF7"
                dot={{ stroke: "#99DCF7", strokeWidth: 3, r: 1 }}
              />
            </LineChart>
          </ResponsiveContainer>
        </BoxChartStyled>
      )}
    </>
  );
}
const TooltipContent = ({ active, payload }: any) => {
  if (active && payload && payload.length) {
    return (
      <TooltipWrap>
        <TextOverlay style={{ fontSize: "12px" }}>
          {formatUtc(payload[0].payload.unixTime)}
        </TextOverlay>
        <TextMain style={{ fontSize: "13px", color: `${payload[0].stroke}` }}>
          Transactions: {formatNumber(payload[0].payload.totalTxs, 4)}
        </TextMain>
        <TextMain style={{ fontSize: "13px", color: `${payload[1].stroke}` }}>
          Blocks: {formatNumber(payload[0].payload.totalBlock, 4)}
        </TextMain>
      </TooltipWrap>
    );
  }
  return null;
};

const BoxChartStyled = styled(BoxChart)`
  background-color: ${({ theme }) => theme.bg5};
`;

const TooltipWrap = styled.div`
  background-color: ${({ theme }) => theme.bg1};
  padding: 10px 15px;
  border-radius: 8px;
`;
