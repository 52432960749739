import styled from "styled-components";
import { Tag, Typography, Button } from "antd";
const { Text, Title } = Typography;

export const Wrapper = styled.div`
  width: 100%;
  position: relative;
  a {
    color: ${({ theme }) => theme.textLink};
  }
`;

export const Container = styled.div`
  width: 100%;
  max-width: 1600px;
  padding: 0 80px;
  margin: 0 auto;
  @media only screen and (max-width: 991px) {
    padding: 0 15px;
  }
`;

export const BoxDetail = styled.div`
  background: ${({ theme }) => theme.bg7};
  // backdrop-filter: blur(20px);
  border-radius: 8px;
  box-shadow: 0px 4px 8px ${({ theme }) => theme.boxShadow1};
  padding: 24px;
  @media (max-width: 991px) {
    padding: 15px;
    border-radius: 0;
  }
`;

export const TextMain = styled.div`
  color: ${({ theme }) => theme.text};
  &.inline-block {
    display: inline-block;
  }
  &.block {
    display: block;
  }
  &.flex {
    display: flex;
  }
  &.word-break {
    word-break: break-word;
  }
  &.link-color {
    color: ${({ theme }) => theme.textLink};
  }
  &.bold {
    font-weight: 700;
  }
  &.inner-image {
    display: flex;
    align-items: center;
    img {
      width: 18px;
      height: 18px;
      margin-right: 5px;
    }
  }
  &.border-left {
    margin-left: 10px;
    padding-left: 10px;
    border-left: 1px solid ${({ theme }) => theme.borderOverlay};
  }
  &.border-right {
    margin-right: 10px;
    padding-right: 10px;
    border-right: 1px solid ${({ theme }) => theme.borderOverlay};
  }
`;

export const TextOverlay = styled.span`
  color: ${({ theme }) => theme.textOverlay};
  &.mv-2 {
    margin-bottom: 8px;
    margin-top: 8px;
    display: block;
  }
  &.block {
    display: block;
  }
  &.border-left {
    margin-left: 10px;
    padding-left: 10px;
    border-left: 1px solid ${({ theme }) => theme.borderOverlay};
  }
  &.border-right {
    margin-right: 10px;
    padding-right: 10px;
    border-right: 1px solid ${({ theme }) => theme.borderOverlay};
  }
  &.link-color {
    color: ${({ theme }) => theme.textLink};
  }
  &.bold {
    font-weight: bold;
  }
`;

export const TabStyle = styled.div`
  background-color: ${({ theme }) => theme.bg7};
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px ${({ theme }) => theme.boxShadow1};
  @media (max-width: 991px) {
    padding: 15px;
    border-radius: 0px;
  }
  &.not-bg-tab {
    background-color: transparent;
    padding: 0px;
    border-radius: 0px;
    box-shadow: 0px 0px 0px;
    .ant-tabs {
      overflow: inherit;
    }
    @media (max-width: 767px) {
      padding: 0px;
    }
  }
  .ant-tabs-nav:before {
    border-bottom: none !important;
  }
  // .ant-tabs-nav-list {
  //   padding: 0;
  //   border-radius: 8px;
  // }
  .ant-tabs-nav {
    margin-bottom: 0px;
    &:before {
      border-bottom: none !important;
    }
  }
  .ant-tabs-content-holder {
    .ant-tabs-nav-list {
      background-color: transparent;
      .ant-tabs-tab {
        font-weight: 400;
        border: 1px solid ${({ theme }) => theme.borderOverlay}!important;
        margin-right: 10px;
        &.ant-tabs-tab-active {
          background: ${({ theme }) => theme.bg3}!important;
        }
      }
    }
  }
  .ant-tabs-tab {
    border: 0 !important;
    border-radius: 8px !important;
    color: ${({ theme }) => theme.textOverlay}!important;
    padding: 10px 20px !important;
    background-color: transparent !important;
    font-weight: 500;
    &.ant-tabs-tab-active {
      * {
        color: #fff;
      }
      .ant-tabs-tab-btn {
        &:after {
          background: linear-gradient(
            90.05deg,
            #99dcf7 -24.35%,
            #a5c6ff 34.93%,
            #d89aff 99.95%
          );
        }
      }
    }
    .ant-tabs-tab-btn {
      &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        bottom: 0;
        left: 0;
      }
    }
  }
  .col-txt-left {
    color: ${({ theme }) => theme.textOverlay};
  }
  @media (max-width: 767px) {
    padding: 15px;
    .ant-tabs-tab {
      font-size: 14px;
    }
  }
`;

export const TabBoxDetail = styled.div`
  .ant-tabs-tabpane {
    background: ${({ theme }) => theme.bg5};
    padding: 15px 24px;
    border: 1px solid ${({ theme }) => theme.borderOverlay1};
    border-radius: 0 0 8px 8px;
    @media (max-width: 767px) {
      padding: 15px;
    }
  }
  &.tmp-tab-box2 {
    .ant-tabs-content-holder {
      margin: 0px -24px -24px;
      .ant-tabs-tabpane {
        border-radius: 0 0 12px 12px;
        @media (max-width: 767px) {
          padding: 15px 24px;
          border-radius: 0;
        }
      }
    }
  }
`;
// Tag status
export const StatusStyled = styled(Tag)<{ status: string }>`
  background: transparent;
  color: ${({ theme, status }) => theme[status]};
  border: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 14px;
`;

export const BorderStyle = styled.div<{ width?: string; color?: string }>`
  border: ${({ width }) => (width ? width : "1px")} solid
    ${({ theme, color }) => (color ? color : theme.border2)};
  border-radius: 8px;
  padding: 15px;
  margin: 10px 0;

  .all-box-ellipsis {
    max-width: 100%;
    &.mb-1:last-child {
      margin-bottom: 0 !important;
    }
    // .ant-space-item {
    //   white-space: nowrap;
    //   overflow: hidden;
    //   text-overflow: ellipsis;
    //   max-width: 100%;
    // }
  }
`;

export const BoxInner = styled.div`
  overflow: hidden;
  padding-bottom: 10px;
  &:last-child {
    padding-bottom: 0px;
  }
`;

export const SpaceStyle = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
`;

// Style Box Background Common
export const BoxStyleCommon = styled.div`
  background: ${({ theme }) => theme.bg7};
  backdrop-filter: blur(20px);
  border-radius: 8px;
  box-shadow: 0px 4px 8px ${({ theme }) => theme.boxShadow1};
  padding: 24px;
  @media (max-width: 991px) {
    padding: 15px;
    border-radius: 0px;
  }
  .ant-empty-description {
    color: ${({ theme }) => theme.textOverlay};
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background: transparent;
    color: ${({ theme }) => theme.text};
    border: none;
  }
  .ant-select-arrow {
    color: ${({ theme }) => theme.text};
  }
`;

export const TokenItem = styled.div`
  background: ${({ theme }) => theme.bg8};
  border: 1px solid ${({ theme }) => theme.borderOverlay1};
  box-sizing: border-box;
  backdrop-filter: blur(30px);
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 15px;
  &:last-child {
    margin-bottom: 0px;
  }
`;
export const BoxStyleInner = styled.div`
  background: ${({ theme }) => theme.bg8};
  backdrop-filter: blur(30px);
  border-radius: 8px;
  padding: 24px;
  height: 100%;
  @media (max-width: 991px) {
    padding: 24px 14px;
  }
  @media (max-width: 767px) {
    padding: 14px;
  }
  .inner-box-network {
    position: absolute;
    right: 0;
    height: 100%;

    @media (max-width: 767px) {
      bottom: 0;
      width: 100%;
      height: 1px;
      left: 0;
      top: inherit;
      margin: 0;
      display: block;
      border-bottom: 1px solid rgba(238, 200, 247, 0.1);
      border-left: 0;
    }
  }
`;

export const BoxLogoImage = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 767px) {
    width: 30px;
    height: 30px;
  }
  img {
    max-width: 100%;
  }
  .neon-logo {
    background-color: #46344f;
    border-radius: 100%;
    padding: 2px;
  }
  &.bg {
    background: ${({ theme }) => theme.bg6};
    box-shadow: inset 0px 0px 15px ${({ theme }) => theme.boxShadow};
    backdrop-filter: blur(15px);
    border-radius: 100%;
    border: 2px solid ${({ theme }) => theme.boxShadow};
    img {
      width: 20px;
      @media (max-width: 767px) {
        width: 16px;
      }
    }
  }
  &.w-20 {
    width: 20px;
    height: 20px;
  }
`;

// Style Title, Text
export const TitleStyle = styled(Title)`
  color: ${({ theme }) => theme.text1} !important;
  font-size: 18px !important;
  font-weight: bold;
  &.uppercase {
    text-transform: uppercase;
  }
  &.absolute {
    position: absolute;
    top: 0;
  }
  &.font-32 {
    font-size: 32px !important;
    @media only screen and (max-width: 767px) {
      font-size: 26px !important;
    }
  }
  &.font-28 {
    font-size: 28px !important;
  }
  &.font-24 {
    font-size: 24px !important;
  }
  &.font-22 {
    font-size: 22px !important;
  }
  &.font-20 {
    font-size: 20px !important;
  }
  &.font-18 {
    font-size: 18px !important;
  }
  &.font-16 {
    font-size: 16px !important;
  }
  &.font-14 {
    font-size: 14px !important;
  }
`;

export const TextStyle1 = styled.div`
  color: ${({ theme }) => theme.textOverlay};
  &.text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const TextStyle2 = styled.div`
  color: ${({ theme }) => theme.text};
  font-weight: bold;
  font-size: 20px;
  @media only screen and (max-width: 991px) {
    font-size: 16px;
  }
  &.d-flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

// box chart
export const BoxChart = styled.div`
  background-color: #140e16;
  padding: 10px 5px;
  border-radius: 8px;
  font-size: 14px;
`;

//
export const DropdownToken = styled.div`
  background: ${({ theme }) => theme.bg};
  backdrop-filter: blur(500px);
  border-radius: 8px;
  overflow: hidden;
  .title-token {
    background: ${({ theme }) => theme.bg5};
    padding: 10px 16px;
    border-radius: 8px 8px 0 0;
  }
  .item-token {
    padding: 12px 16px;
    border-bottom: 1px solid ${({ theme }) => theme.borderOverlay};
    &:last-child {
      border: 0;
    }
  }
`;

export const StyledBoxDropdown = styled.div`
  background: transparent;
  border: 1px solid ${({ theme }) => theme.border2};
  border-radius: 8px;
  padding: 10px 12px;
  cursor: pointer;
  position: relative;
  margin-top: 10px;
  color: ${({ theme }) => theme.text};
  @media (max-width: 767px) {
    padding: 10px 12px;
  }
`;

export const BoxScrollDropdown = styled.div`
  max-height: 350px;
  overflow: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: #99809d;
  }
  &::-webkit-scrollbar-thumb {
    background: #693176;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #813992;
  }
`;

export const ValueRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;
  &:last-child {
    margin-bottom: 0px;
  }
  .btn-switch {
    background: transparent;
    border-color: ${({ theme }) => theme.border2};
    border-radius: 8px;
    margin-top: 5px;
    margin-right: 10px;
    text-transform: uppercase;
    font-size: 12px;
    &.active {
      border-color: #fff;
    }
  }
`;

export const CustomStyleTable = styled.div`
  .content-table {
    padding: 0;
    background: none;
  }
`;

// paging
export const ButtonStyled = styled(Button)`
  width: 32px;
  height: 32px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  color: ${({ theme }) => theme.textBtn};
  @media only screen and (max-width: 767px) {
    width: 22px;
  }

  &.ant-btn[disabled],
  &.ant-btn[disabled]:hover,
  &.ant-btn[disabled]:focus,
  &.ant-btn[disabled]:active {
    background-color: transparent;
    border: none;
    color: ${({ theme }) => theme.textBtnDisable};
  }

  &.ant-btn:hover,
  &.ant-btn:focus,
  &.ant-btn:active {
    background-color: transparent;
    border: 1px solid none;
    color: ${({ theme }) => theme.text};
  }
`;

export const CurrentNumber = styled.span`
  border: 1px solid ${({ theme }) => theme.textOverlay};
  border-radius: 6px;
  display: inline-block;
  min-width: 34px;
  padding: 2px 10px;
  text-align: center;
`;

export const TotalNumber = styled.span`
  color: ${({ theme }) => theme.textOverlay};
`;

export const ButtonGradient = styled.div`
  background: linear-gradient(
    90.05deg,
    #99dcf7 -36.85%,
    #a5c6ff 28.39%,
    #d89aff 99.95%
  );
  border-radius: 8px;
  color: ${({ theme }) => theme.text2};
  display: inline-block;
  padding: 8px 24px;
  text-align: center;
  font-weight: bold;
  min-width: 160px;
  cursor: pointer;
`;

export const TextSkeleton = styled.div<{ size?: string }>`
  background: hsl(320deg 16% 7%);
  border-radius: 4px;
  height: 16px;
  width: ${({ size }) => (size ? size : "60px")};
`;

export const StyledButton = styled(Button)`
  border-color: ${({ theme }) => theme.textOverlay};
  background-color: transparent;
  color: #fff;
  font-size: 12px;
  border-radius: 8px;
  &.ant-btn[disabled],
  &.ant-btn[disabled]:hover,
  &.ant-btn[disabled]:focus,
  &.ant-btn[disabled]:active {
    background-color: transparent;
    border: none;
    color: ${({ theme }) => theme.textBtnDisable};
  }

  &.ant-btn:hover,
  &.ant-btn:focus,
  &.ant-btn:active {
    background-color: ${({ theme }) => theme.primary};
    color: #fff;
  }
  &.active {
    background-color: ${({ theme }) => theme.primary};
    color: #fff;
  }
`;
