import React from "react";
import { Skeleton, Row, Col } from "antd";

function DataTableLoading() {
  return (
    <div>
      <Row>
        <Col span={24}>
          <Skeleton active paragraph={{ rows: 3 }} />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Skeleton active paragraph={{ rows: 3 }} />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Skeleton active paragraph={{ rows: 3 }} />
        </Col>
      </Row>
    </div>
  );
}

export default DataTableLoading;
