import styled from "styled-components";
import { Layout, Input, Drawer, Select } from "antd";
import iconCheck from "assets/images/icon-check.svg";
import { Link } from "react-router-dom";

const { Footer } = Layout;

export const Header = styled.div`
  background: transparent;
  align-items: center;
  width: 100%;
  .noBorder {
    border: 0;
  }
  .ant-row {
    align-items: center;
    height: 64px;
    flex-flow: nowrap;
  }
  .ant-menu {
    background: transparent;
  }

  .ant-menu {
    li {
      color: ${({ theme }) => theme.text};
      border: 0 !important;
      &:after {
        display: none;
      }
      .ant-menu-title-content {
        color: ${({ theme }) => theme.text};
        a {
          color: ${({ theme }) => theme.text};
        }
      }
      &.ant-menu-item-selected * {
        font-weight: bold;
        color: ${({ theme }) => theme.textHover}!important;
      }
    }
  }
  .ant-menu-submenu-selected {
    color: ${({ theme }) => theme.text};
    font-weight: bold;
  }
  @media only screen and (max-width: 767px) {
    .pc-menu {
      background: ${({ theme }) => theme.bg};
      display: none;
    }
  }
  .d-block {
    display: block;
  }
  .ant-dropdown-link {
    background: #c74ae3;
    color: #ffffff;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 5px 10px;
    display: inline-block;
    text-align: center;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    .ml-1 {
      margin-left: 5px !important;
    }
    * {
      color: #ffffff !important;
    }
  }
  .user-account {
    display: flex;
    align-items: center;
    justify-content: center;
    .ant-menu-title-content {
      display: inline-block;
      background-color: #c74ae3;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      text-align: center;
      color: #fff;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .anticon-caret-down {
      color: #6d6d6d;
    }
    .ant-menu-submenu-title {
      flex-direction: row-reverse;
    }
  }
`;

//  Style menu MB
export const CustomDrawer = styled(Drawer)`
  .ant-drawer-body {
    padding: 0px 40px 40px;
  }
  .ant-drawer-content {
    background: ${({ theme }) => theme.bg2};
    * {
      color: ${({ theme }) => theme.text}!important;
    }
    .st-menu,
    .ant-drawer-header {
      background: ${({ theme }) => theme.bg2};
      border: 0;
    }
    .st-menu {
      .ant-menu-title-content {
        font-weight: bold;
      }
      .ant-menu-sub {
        .ant-menu-title-content {
          font-weight: normal;
        }
      }
    }
    .ant-menu-sub {
      background: ${({ theme }) => theme.bg8};
      border-radius: 0px 0px 8px 8px !important;
      border: 1px solid ${({ theme }) => theme.borderOverlay1};
      overflow: hidden;
      margin-top: 10px;
      padding: 10px 20px !important;
      li {
        margin: 0 0 5px !important;
        padding: 0 0 5px !important;
        border-bottom: 1px solid ${({ theme }) => theme.borderOverlay1};
        height: auto !important;
        &:last-child {
          border-bottom: 0;
          margin: 0 !important;
          padding: 0 !important;
        }
      }
    }
    .ant-menu-item-selected {
      background: transparent;
      color: ${({ theme }) => theme.text}!important;
      a {
        color: ${({ theme }) => theme.textHover}!important;
        font-weight: bold;
      }
    }
  }
  .text-center {
    text-align: center;
  }
  .btn-back {
    position: absolute;
    left: 18px;
    width: 24px;
    height: 24px;
    img {
      width: 24px;
      height: 24px;
    }
  }
  .ant-drawer-header {
    padding: 24px 15px;
  }
  .ant-menu {
    border-right: 0;
    li {
      margin: 0 !important;
      &:after {
        display: none;
      }
    }
  }
  .ant-menu-root {
    padding: 0;
    margin: 0;
    > li {
      margin: 0 0 15px !important;
      padding: 0 0 15px !important;
      border-bottom: 1px solid ${({ theme }) => theme.borderOverlay};
      height: auto !important;
      &:last-child {
        border-bottom: 0;
      }
      &.ant-menu-item-only-child {
        padding-right: 0px !important;
      }
      &.ant-menu-item-selected {
        // padding: 0 15px!important;
      }
    }
    .ant-menu-submenu-title {
      padding-left: 0 !important;
      background: transparent !important;
    }
    .ant-menu-submenu-title {
      .ant-menu-submenu-arrow {
        right: 0;
      }
    }
  }
  .btn-signin {
    background: #00e8b4;
    border-radius: 8px;
    padding: 8px 24px;
    color: #ffffff !important;
    font-weight: bold;
    font-size: 15px;
    line-height: 24px;
    display: block;
    text-align: center;
    margin: 15px 0;
  }
`;

export const ToggleBtnMenu = styled.div`
  color: ${({ theme }) => theme.text};
  display: flex;
  place-items: center;
  font-size: 24px;
  cursor: pointer;
  position: relative;
  &.btn-menu-sp {
    img {
      width: 32px;
    }
  }
  .box-network {
    background: ${({ theme }) => theme.bg};
    position: absolute;
    top: calc(100% + 5px);
    right: 0;
    border-radius: 10px;
    overflow: hidden;
    z-index: 99;
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
      0 9px 28px 8px rgb(0 0 0 / 5%) !important;
    .ant-menu {
      .ant-menu-item {
        .ant-menu-title-content {
          color: ${({ theme }) => theme.text}!important;
          a {
            color: ${({ theme }) => theme.text}!important;
          }
        }
        &.active {
          .ant-menu-title-content {
            div:first-child {
              font-weight: bold;
            }
          }
        }
        &.active,
        &:hover {
          background: ${({ theme }) => theme.bg};
          .ant-menu-title-content {
            color: ${({ theme }) => theme.text};
          }
        }
      }
    }
  }
`;

export const FormItemStyled = styled.div`
  position: relative;
  width: 100%;
  z-index: 1;
  &:before {
    content: "";
    position: absolute;
    width: 80px;
    height: 120px;
    left: -30px;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(195, 0, 225, 0.35);
    filter: blur(50px);
    z-index: -1;
  }
`;

export const InputStyled = styled(Input)`
  background: rgba(212, 158, 255, 0.07);
  backdrop-filter: blur(15px);
  box-shadow: inset 0px 0px 15px rgba(212, 158, 255, 0.1);
  border-radius: 8px;
  border: none;
  padding: 15px;
  position: relative;
  width: 100%;

  .ant-input,
  .ant-select-selector {
    background: transparent;
    padding-left: 5px !important;
    color: ${({ theme }) => theme.text};
  }

  .ant-input-suffix {
    padding-right: 55px;
  }
`;

export const SelectNetwork = styled(Select)`
  .ant-select-selector {
    background-color: transparent !important;
    background-image: url(${iconCheck}) !important;
    background-repeat: no-repeat !important;
    background-size: 16px !important;
    background-position: top 9px left 12px !important;
    border: 1px solid ${({ theme }) => theme.border}!important;
    box-sizing: border-box;
    border-radius: 8px !important;
    padding: 2px 15px 2px 34px !important;
    height: auto !important;
    min-width: 110px;
    @media only screen and (max-width: 767px) {
      background-size: 14px !important;
      background-position: top 8px left 8px !important;
      border-radius: 8px !important;
      padding: 0px 10px 0px 28px !important;
      min-width: 90px;
    }
  }
  * {
    background: transparent !important;
    border: 0 !important;
    color: ${({ theme }) => theme.text};
    font-weight: bold;
    font-size: 14px;
    @media only screen and (max-width: 767px) {
      font-weight: normal;
    }
  }
  .ant-select-selection-item {
    padding-right: 0 !important;
  }
  .ant-select-arrow {
    display: none;
  }
`;

export const VerifyButton = styled(Link)`
  position: absolute;
  right: 25px;
  top: 10px;
  z-index: 1;
  padding: 5px 10px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.borderOverlay};
  font-weight: 600;
  color: ${({ theme }) => theme.text1}!important;
  &:hover {
    background-color: ${({ theme }) => theme.primary};
    color: #fff !important;
  }
  @media (max-width: 480px) {
    top: 45px;
    right: 15px;
  }
`;

// footer
export const FooterStyle = styled(Footer)`
  background: transparent !important;
  height: 86px;
  padding: 25px 0;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
  &:before {
    content: "";
    background: ${({ theme }) => theme.bg4};
    border-radius: 50% 50% 0 0;
    opacity: 0.1;
    filter: blur(40px);
    width: 80%;
    height: 100%;
    z-index: -1;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
`;
