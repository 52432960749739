import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";

import { transactionApi } from "app/services/transaction";
import { blockApi } from "app/services/block";
import { tokenApi } from "app/services/token";
import { accountApi } from "app/services/account";
import { homeApi } from "app/services/home";
import { generalApi } from "app/services/general";
import { statsApi } from "app/services/stats";
import { transferApi } from "app/services/transfer";
import generalReducer from "app/services/general";

export const store = configureStore({
  reducer: {
    general: generalReducer,
    // Add the generated reducer as a specific top-level slice
    [generalApi.reducerPath]: generalApi.reducer,
    [homeApi.reducerPath]: homeApi.reducer,
    [transactionApi.reducerPath]: transactionApi.reducer,
    [blockApi.reducerPath]: blockApi.reducer,
    [tokenApi.reducerPath]: tokenApi.reducer,
    [accountApi.reducerPath]: accountApi.reducer,
    [transferApi.reducerPath]: transferApi.reducer,
    [statsApi.reducerPath]: statsApi.reducer,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      transactionApi.middleware,
      generalApi.middleware,
      homeApi.middleware,
      blockApi.middleware,
      tokenApi.middleware,
      accountApi.middleware,
      statsApi.middleware,
      transferApi.middleware,
    ]),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);
