import React from "react";
import BigNumber from "bignumber.js";
import styled from "styled-components";
import { Space, Button, Skeleton } from "antd";
import {
  Bar,
  BarChart,
  ResponsiveContainer,
  YAxis,
  Tooltip,
  XAxis,
} from "recharts";
import { dataFormater, formatUtc, formatNumber } from "utils";
import { BoxChart, TextMain, TextOverlay } from "common/styledGeneral";
import { useGetFeeChartQuery } from "app/services/stats";

type FeePros = {
  type: string;
  setType: Function;
};
export function FeeChart({ type }: FeePros) {
  const { data, error, isLoading } = useGetFeeChartQuery(type);
  const newData =
    data?.data &&
    data.data.length > 0 &&
    data.data.map((item: any) => {
      const fee = new BigNumber(item.fee).dividedBy(10 ** 18).toNumber();
      const date = formatUtc(item.unixTime, type === "1D" ? "HH:ss" : "dd/MM");
      return { ...item, fee, date };
    });
  return (
    <>
      <TextOverlay className="mv-2">Network Fees Over Time</TextOverlay>
      {isLoading ? (
        <Skeleton />
      ) : (
        <BoxChartStyled>
          <ResponsiveContainer width="100%" height="100%" aspect={2.5}>
            <BarChart
              data={newData ? newData : []}
              margin={{
                top: 5,
                left: -10,
              }}
            >
              <XAxis
                dataKey="date"
                axisLine={false}
                tickLine={false}
                stroke="rgba(238, 200, 247, 0.6)"
                tickSize={10}
              />
              <YAxis
                tickFormatter={dataFormater}
                axisLine={false}
                tickLine={false}
                tickSize={10}
                stroke="rgba(238, 200, 247, 0.6)"
              />
              <Tooltip
                content={<TooltipContent />}
                cursor={{
                  stroke: "#A93EC1",
                  fill: "#A93EC1",
                }}
                // allowEscapeViewBox={{ y: true }}
                // position={{ y: -120 }}
              />
              <Bar dataKey="fee" fill="#99DCF7" />
            </BarChart>
          </ResponsiveContainer>
        </BoxChartStyled>
      )}
    </>
  );
}
const TooltipContent = ({ active, payload }: any) => {
  if (active && payload && payload.length) {
    return (
      <TooltipWrap>
        <TextOverlay style={{ fontSize: "12px" }}>
          {formatUtc(payload[0].payload.unixTime)}
        </TextOverlay>
        <TextMain style={{ fontSize: "13px" }}>
          Fee: {formatNumber(payload[0].payload.fee, 4)} NEON
        </TextMain>
      </TooltipWrap>
    );
  }
  return null;
};

const BoxChartStyled = styled(BoxChart)`
  background-color: ${({ theme }) => theme.bg5};
`;

const TooltipWrap = styled.div`
  background-color: ${({ theme }) => theme.bg1};
  padding: 10px 15px;
  border-radius: 8px;
`;

const ButtonStyle = styled(Button)`
  border: none;
  color: ${({ theme }) => theme.textOverlay};
  background-color: ${({ theme }) => theme.bg2};
  border-radius: 6px;
  font-size: 12px;
  padding: 2px 10px;
  height: auto;
  &.active,
  &:hover,
  &:focus {
    color: #ffffff;
    background-color: rgba(169, 62, 193, 0.22);
  }
`;

const SpaceStyled = styled(Space)`
  justify-content: space-between;
  align-items: start;
  width: 100%;
  @media (max-width: 767px) {
    align-items: center;
  }
`;
