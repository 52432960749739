import React, { useState } from "react";
import styled from "styled-components";
import { Row, Col, Space, Button, Divider } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Wrapper,
  Container,
  TextMain,
  TextOverlay,
} from "common/styledGeneral";
import {
  AccountChart,
  FeeChart,
  OverviewStats,
  TxsChart,
} from "features/stats/chart";
import { PageTitle } from "common/PageTitle";

function Stats() {
  const [type, setType] = useState("1D");
  return (
    <Wrapper className="mt-5">
      <Helmet>
        <title>Statistic</title>
      </Helmet>
      <Container>
        <BoxStyled>
          <SpaceStyled>
            <PageTitle title="NETWORK STATISTIC" fontSize="18" />
            <DateStyled>
              <ButtonStyle
                onClick={() => setType("1D")}
                className={type === "1D" ? "active" : ""}
              >
                1D
              </ButtonStyle>
              <Divider type="vertical" />
              <ButtonStyle
                onClick={() => setType("7D")}
                className={type === "7D" ? "active" : ""}
              >
                7D
              </ButtonStyle>
              <Divider type="vertical" />
              <ButtonStyle
                onClick={() => setType("30D")}
                className={type === "30D" ? "active" : ""}
              >
                30D
              </ButtonStyle>
              <Divider type="vertical" />
              <ButtonStyle
                onClick={() => setType("60D")}
                className={type === "60D" ? "active" : ""}
              >
                60D
              </ButtonStyle>
              <Divider type="vertical" />
              <ButtonStyle
                onClick={() => setType("90D")}
                className={type === "90D" ? "active" : ""}
              >
                90D
              </ButtonStyle>
              <Divider type="vertical" />
              <ButtonStyle
                onClick={() => setType("All")}
                className={type === "All" ? "active" : ""}
              >
                All Time
              </ButtonStyle>
            </DateStyled>
          </SpaceStyled>
          <Row gutter={[24, 24]}>
            <Col span={24} md={12}>
              <OverviewStats type={type} />
            </Col>
            <Col span={24} md={12}>
              <TxsChart type={type} />
            </Col>
            <Col span={24} md={12}>
              <AccountChart type={type} />
            </Col>
            <Col span={24} md={12}>
              <FeeChart type={type} setType={setType} />
            </Col>
          </Row>
        </BoxStyled>
      </Container>
    </Wrapper>
  );
}

const BoxStyled = styled.div`
  background-color: ${({ theme }) => theme.bg7};
  padding: 20px;
  border-radius: 16px;
`;

const ButtonStyle = styled(Button)`
  border: none;
  // border-left: 1px solid ${({ theme }) => theme.border3};
  color: ${({ theme }) => theme.textOverlay};
  background-color: transparent;
  font-size: 12px;
  padding: 3px 10px;
  height: auto;
  &.active,
  &:hover,
  &:focus {
    color: #ffffff;
    background-color: rgba(169, 62, 193, 0.22);
    border-color: rgba(169, 62, 193, 0.22);
    border-radius: 4px;
  }
`;

const SpaceStyled = styled(Space)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  @media (max-width: 480px) {
    .ant-divider-vertical {
      margin-left: 3px;
      margin-right: 3px;
    }
  }
`;
const DateStyled = styled.div`
  display: flex;
  padding: 4px;
  border-radius: 8px;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.border3};
  margin-bottom: 0.7em;
`;
export default Stats;
