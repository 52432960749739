import React from "react";
import styled from "styled-components";
import { Skeleton } from "antd";
import {
  Bar,
  BarChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";
import { dataFormater, formatNumber, formatUtc } from "utils";
import {
  BoxChart,
  TextMain,
  TextOverlay,
  TextStyle1,
} from "common/styledGeneral";
import { useGetNewAccountChartQuery } from "app/services/stats";

type AccountPros = {
  type: string;
};

export function AccountChart({ type }: AccountPros) {
  const { data, error, isLoading } = useGetNewAccountChartQuery(type);

  const newTxChart =
    data?.data &&
    data.data.length > 0 &&
    data.data.map((item: any, key: number) => {
      const accountNumber = item.total;
      const date = formatUtc(item.unixTime, type === "1D" ? "HH:ss" : "dd/MM");
      return { ...item, date, accountNumber };
    });
  return (
    <>
      <TextOverlay className="mv-2">New Accounts Over Time</TextOverlay>
      {isLoading ? (
        <Skeleton />
      ) : (
        <BoxChartStyled>
          <ResponsiveContainer width="100%" height="100%" aspect={2.5}>
            <BarChart
              data={newTxChart ? newTxChart : []}
              margin={{
                top: 5,
                left: -10,
              }}
            >
              <XAxis
                dataKey="date"
                axisLine={false}
                tickLine={false}
                stroke="rgba(238, 200, 247, 0.6)"
                tickSize={10}
              />
              <YAxis
                tickFormatter={dataFormater}
                axisLine={false}
                tickLine={false}
                tickSize={10}
                stroke="rgba(238, 200, 247, 0.6)"
              />
              <Tooltip
                content={<TooltipContent />}
                cursor={{
                  stroke: "#A93EC1",
                  fill: "#A93EC1",
                }}
                // allowEscapeViewBox={{ y: true }}
                // position={{ y: -120 }}
              />
              <Bar dataKey="accountNumber" fill="#99DCF7" />
            </BarChart>
          </ResponsiveContainer>
        </BoxChartStyled>
      )}
    </>
  );
}

const TooltipContent = ({ active, payload }: any) => {
  if (active && payload && payload.length) {
    return (
      <TooltipWrap>
        <TextOverlay style={{ fontSize: "12px" }}>
          {formatUtc(payload[0].payload.unixTime)}
        </TextOverlay>
        <TextMain style={{ fontSize: "13px" }}>
          Account Number: {formatNumber(payload[0].payload.accountNumber)}
        </TextMain>
      </TooltipWrap>
    );
  }
  return null;
};

const BoxChartStyled = styled(BoxChart)`
  background-color: ${({ theme }) => theme.bg5};
`;
const TooltipWrap = styled.div`
  background-color: ${({ theme }) => theme.bg1};
  padding: 10px 15px;
  border-radius: 8px;
`;
