import React, { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Space, notification } from "antd";
import { useNavigate } from "react-router-dom";

import { useGetTransactionListQuery } from "app/services/transaction";
import DataTableTotal from "common/DataTable/DataTableTotal";

import {
  Wrapper,
  Container,
  TitleStyle,
  TextOverlay,
} from "common/styledGeneral";
import { useQuery } from "app/hooks";
import { DataTableNew } from "common/TableAccountTxs/Table";
import Paging from "common/TableAccountTxs/Pagination";
import { WrapTable } from "common/TableAccountTxs/styled";
import SkeletonTable from "common/SkeletonTable";
import LinkFormat from "common/LinkFormat";
import { ADDRESS_MAPPER } from "constants/address_mapper";
import { addressCheckSum } from "utils";
import { txColumnsToken } from "features/account/columnsConfig";

export function TransactionList() {
  const query = useQuery();
  const address = query.get("acc") || undefined;
  const currentPage = parseInt(query.get("page") || "1");
  const pageSize =
    parseInt(query.get("number") || "20") > 100
      ? 100
      : parseInt(query.get("number") || "20");
  const [limit, setLimit] = useState(pageSize);

  const navigate = useNavigate();
  const offset = useMemo(() => {
    return (currentPage - 1) * pageSize;
  }, [currentPage, pageSize]);

  const { data, isFetching } = useGetTransactionListQuery({
    offset,
    limit: pageSize,
    address,
  });

  // use update limit when direct /txs?number -> txs
  useEffect(() => {
    if (pageSize && pageSize !== limit) setLimit(pageSize);
    if (parseInt(query.get("number") || "10") > 100) {
      notification["warning"]({
        message: "Warning",
        description: "Each page will only load a maximum of 100 records",
        placement: "bottomRight",
      });
    }
  }, [pageSize]);

  const newData =
    data?.data?.items &&
    data.data.items.length > 0 &&
    data.data.items.map((item: any) => {
      return { ...item, currentAddress: address };
    });

  const pageTotal = useMemo(() => {
    return data?.data?.total && pageSize
      ? Math.ceil(data?.data?.total / pageSize)
      : 0;
  }, [data?.data?.total, pageSize]);

  function changeNumPerPage(value: string) {
    if (value) {
      setLimit(Number(value));
      query.set("number", value);
      navigate({ search: query.toString() });
    }
  }
  return (
    <Wrapper>
      <Helmet>
        <title>Recent Transactions {address ? `- For ${address}` : ""}</title>
      </Helmet>
      <Container className="mx-md-down-0">
        <WrapTable>
          <TitleStyle level={2} className="uppercase">
            Recent Transactions
          </TitleStyle>
          <div
            style={{
              display: "flex",
              alignContent: "center",
              justifyContent: "space-between",
              flexWrap: "wrap",
              marginBottom: 20,
            }}
          >
            <div>
              {address ? (
                <Space>
                  <TextOverlay>For account:</TextOverlay>
                  {/* <Link to={`/address/${address}`}>{address}</Link> */}
                  <LinkFormat
                    title={ADDRESS_MAPPER[address.toLowerCase()] || ""}
                    type="address"
                    copy={true}
                    value={addressCheckSum(address)}
                  />
                </Space>
              ) : (
                ""
              )}
              <div>
                <DataTableTotal
                  total={data?.data?.total ? data.data.total : ""}
                  type="tx"
                />
              </div>
            </div>
            <Paging
              currPage={currentPage}
              pageTotal={pageTotal}
              pageSize={limit}
              changeNumPerPage={changeNumPerPage}
            />
          </div>
          {isFetching ? (
            <SkeletonTable type="tx" record={pageSize} loading={isFetching} />
          ) : (
            <DataTableNew
              columns={txColumnsToken}
              data={newData || []}
              loading={isFetching}
            />
          )}

          <div style={{ marginTop: 20 }}>
            <Paging
              currPage={currentPage}
              pageTotal={pageTotal}
              pageSize={limit}
              changeNumPerPage={changeNumPerPage}
            />
          </div>
        </WrapTable>
      </Container>
    </Wrapper>
  );
}
