import React, { useEffect, useState } from "react";
import { Row, Col, Skeleton, Divider } from "antd";

import { useGetBlockListQuery } from "app/services/block";
import { useGetTransactionListQuery } from "app/services/transaction";
import { useGetNetworkInfoQuery, useGetStatsQuery } from "app/services/home";
import { formatNumber } from "utils";

import {
  Wrapper,
  Container,
  BoxStyleCommon,
  BoxStyleInner,
  TitleStyle,
  TextStyle1,
  TextStyle2,
  BoxLogoImage,
} from "common/styledGeneral";

// import DataTable from "common/DataTable";

import {
  txColumnsLatestTransaction,
  txColumnsLatestBlocks,
} from "./columnsConfig";

import iconNeon40 from "assets/images/neonlabs.png";
import iconNeon24 from "assets/images/neonlabs.png";
import iconChart from "assets/images/icon-chart.svg";
import iconDoc from "assets/images/icon-doc.svg";
import iconBlock from "assets/images/icon-block.svg";
// import { NoData } from "common/NoData";
import { TxChart } from "./TxChart";
import { Link } from "react-router-dom";
import SkeletonTable from "common/SkeletonTable";
import { WrapTable } from "common/TableAccountTxs/styled";
import { DataTableNew } from "common/TableAccountTxs/Table";
// import { RefreshByTime } from "./RefreshByTime";

function HomePage() {
  const [neonMarket, setNeonMarket] = useState({ usd: 0, usd_market_cap: 0 });
  const [marketInfoError, setError] = useState(null || "");
  const { data, error, isLoading } = useGetBlockListQuery(
    {
      offset: 0,
      limit: 10,
    },
    { pollingInterval: 15000 }
  );
  const {
    data: txData,
    error: txError,
    isLoading: txLoading,
  } = useGetTransactionListQuery(
    { offset: 0, limit: 10 },
    { pollingInterval: 15000 }
  );
  const {
    data: countData,
    error: countError,
    isLoading: countLoading,
  } = useGetNetworkInfoQuery("network", { pollingInterval: 15000 });

  const {
    data: statsData,
    error: statsError,
    isLoading: statsLoading,
  } = useGetStatsQuery("", { pollingInterval: 15000 });

  async function neonMarketInfo() {
    try {
      const response = await fetch(
        "https://api.coingecko.com/api/v3/simple/price?ids=neon&vs_currencies=USD&include_market_cap=true"
      );
      const data = await response.json();
      if (data?.neon) setNeonMarket({ ...neonMarket, ...data.neon });
    } catch (e) {
      setError(`Error: ${e}`);
      console.log("Error:", error);
    }
  }

  useEffect(() => {
    if (marketInfoError === null || marketInfoError === "") {
      neonMarketInfo();
    }

    const timer = setInterval(() => {
      if (marketInfoError === null || marketInfoError === "") {
        neonMarketInfo();
      }
    }, 65000);

    if (marketInfoError) return clearInterval(timer);

    return () => {
      clearInterval(timer);
    };
  }, [marketInfoError]);

  return (
    <Wrapper>
      <Container className="mx-md-down-0">
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <BoxStyleCommon>
              <Row gutter={[24, 24]}>
                <Col
                  className="pt-5"
                  xs={{ span: 24 }}
                  md={{ span: 8 }}
                  xl={{ span: 6 }}
                >
                  <TitleStyle className="uppercase font-16 absolute" level={2}>
                    MARKET
                  </TitleStyle>
                  <BoxStyleInner>
                    <Row>
                      <Col xs={12} md={24}>
                        <Row gutter={15} className="mb-md-3" align="middle">
                          <Col>
                            <BoxLogoImage>
                              <img className="neon-logo" src={iconNeon40} />
                            </BoxLogoImage>
                          </Col>
                          <Col>
                            <TextStyle1>NEON price</TextStyle1>
                            <TextStyle2>
                              {neonMarket && neonMarket.usd
                                ? `$${neonMarket.usd}`
                                : "__"}
                            </TextStyle2>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={12} md={24}>
                        <Row gutter={15} align="middle">
                          <Col>
                            <BoxLogoImage>
                              <img src={iconChart} />
                            </BoxLogoImage>
                          </Col>
                          <Col>
                            <TextStyle1>Market cap</TextStyle1>
                            <TextStyle2 className="text-ellipsis">
                              {neonMarket && neonMarket.usd_market_cap
                                ? `$${formatNumber(
                                    neonMarket.usd_market_cap,
                                    2
                                  )}`
                                : "__"}
                            </TextStyle2>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </BoxStyleInner>
                </Col>
                <Col
                  className="pt-5"
                  xs={{ span: 24 }}
                  md={{ span: 16 }}
                  xl={{ span: 12 }}
                >
                  <TitleStyle
                    className="uppercase mb-2 font-16 absolute"
                    level={2}
                  >
                    NETWORK INFO
                  </TitleStyle>
                  {/* <RefreshByTime timer={timer} /> */}
                  <BoxStyleInner>
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                      <Col
                        xs={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 10 }}
                      >
                        <Row>
                          <Col xs={12} md={24}>
                            <Row
                              gutter={15}
                              className="mb-2 mb-md-3"
                              align="middle"
                            >
                              <Col>
                                <BoxLogoImage>
                                  <img src={iconDoc} />
                                </BoxLogoImage>
                              </Col>
                              <Col>
                                <TextStyle1>Transactions</TextStyle1>
                                {countData?.data?.totalTxs ? (
                                  <TextStyle2>
                                    {formatNumber(countData.data.totalTxs)}
                                  </TextStyle2>
                                ) : (
                                  <Skeleton.Button
                                    style={{ width: 100 }}
                                    active={countLoading}
                                  />
                                )}
                              </Col>
                            </Row>
                          </Col>
                          <Col xs={12} md={24}>
                            <Row gutter={15} align="middle">
                              <Col>
                                <BoxLogoImage>
                                  <img src={iconBlock} />
                                </BoxLogoImage>
                              </Col>
                              <Col>
                                <TextStyle1>Last Block</TextStyle1>
                                {countData?.data?.totalTxs ? (
                                  <TextStyle2 className="text-ellipsis">
                                    {formatNumber(countData.data.totalBlock)}
                                  </TextStyle2>
                                ) : (
                                  <Skeleton.Button
                                    style={{ width: 100 }}
                                    active={countLoading}
                                  />
                                )}
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Divider
                          className="inner-box-network"
                          type="vertical"
                        />
                      </Col>
                      <Col
                        xs={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 14 }}
                      >
                        <TxChart />
                      </Col>
                    </Row>
                  </BoxStyleInner>
                </Col>
                <Col className="pt-5" xs={{ span: 24 }} xl={{ span: 6 }}>
                  <TitleStyle
                    className="uppercase mb-2 font-16 absolute"
                    level={2}
                  >
                    STATS
                  </TitleStyle>
                  <Link
                    style={{ position: "absolute", top: 0, right: 15 }}
                    to="/stats"
                  >
                    View more
                  </Link>
                  <Row gutter={[12, 12]}>
                    <Col xs={{ span: 12 }} md={{ span: 6 }} xl={{ span: 12 }}>
                      <BoxStyleInner className="p-16 text-center">
                        <TextStyle1>Accounts</TextStyle1>
                        <TextStyle2>
                          {statsData?.data?.totalAccount
                            ? formatNumber(statsData.data.totalAccount)
                            : "__"}
                        </TextStyle2>
                      </BoxStyleInner>
                    </Col>
                    <Col xs={{ span: 12 }} md={{ span: 6 }} xl={{ span: 12 }}>
                      <BoxStyleInner className="p-16 text-center">
                        <TextStyle1 className="text-ellipsis">
                          Tokens
                        </TextStyle1>
                        <TextStyle2>
                          {statsData?.data?.totalToken
                            ? formatNumber(statsData.data.totalToken)
                            : "__"}
                        </TextStyle2>
                      </BoxStyleInner>
                    </Col>
                    <Col xs={{ span: 12 }} md={{ span: 6 }} xl={{ span: 12 }}>
                      <BoxStyleInner className="p-16 text-center">
                        <TextStyle1>TPS</TextStyle1>
                        <TextStyle2 className="d-flex-center">
                          <BoxLogoImage className="w-20 mr-05">
                            <img
                              className="neon-logo"
                              width={18}
                              src={iconNeon24}
                            />
                          </BoxLogoImage>
                          {statsData?.data?.tps
                            ? formatNumber(statsData.data.tps, 4)
                            : 0}
                        </TextStyle2>
                      </BoxStyleInner>
                    </Col>
                    <Col xs={{ span: 12 }} md={{ span: 6 }} xl={{ span: 12 }}>
                      <BoxStyleInner className="p-16 text-center">
                        <TextStyle1>Verified Contracts</TextStyle1>
                        <TextStyle2>
                          {statsData?.data?.verifyedContract
                            ? formatNumber(statsData.data.verifyedContract)
                            : "__"}
                        </TextStyle2>
                      </BoxStyleInner>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </BoxStyleCommon>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <WrapTable>
              <TitleStyle
                style={{ marginBottom: 20 }}
                level={2}
                className={"uppercase font-16"}
              >
                LATEST TRANSACTIONS
              </TitleStyle>
              {txLoading ? (
                <SkeletonTable type="txsHome" record={8} loading={txLoading} />
              ) : (
                <DataTableNew
                  loading={txLoading}
                  data={txData?.data?.items ? txData.data.items : []}
                  columns={txColumnsLatestTransaction}
                />
              )}
            </WrapTable>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <WrapTable>
              <TitleStyle
                style={{ marginBottom: 20 }}
                level={2}
                className={"uppercase font-16"}
              >
                LATEST BLOCKS
              </TitleStyle>
              {txLoading ? (
                <SkeletonTable
                  type="blockHome"
                  record={8}
                  loading={txLoading}
                />
              ) : (
                <DataTableNew
                  loading={isLoading}
                  data={data?.data?.items ? data.data.items : []}
                  columns={txColumnsLatestBlocks}
                />
              )}
            </WrapTable>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  );
}

export default HomePage;
