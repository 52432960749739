import React from "react";
import { useState, useEffect } from "react";
import { Table, Select, Space, Spin } from "antd";
import { ClockCircleOutlined } from "@ant-design/icons";
import { formatUtc, timeFormatDistance } from "utils";
import { PaginationDirType, LoadingType } from "constants/types";
import { MAX_RECORD } from "constants/api";
import {
  TableHeader,
  PaginationWrapper,
  ContentTable,
  TextLink,
  WrapSpin,
} from "common/DataTable/dataTableStyles";
import DataTableLoading from "common/DataTable/DataTableLoading";
import DataTablePagination from "common/DataTable/DataTablePagination";
import { TextMain, TextOverlay, TitleStyle } from "common/styledGeneral";
import SkeletonTable from "common/SkeletonTable";

const { Option } = Select;

interface DataTableProps {
  title?: string;
  loading?: boolean;
  total?: null | number;
  columns: any;
  data: any;
  limit?: number;
  headerLeft?: any;
  headerRight?: any;
  showPagination?: boolean;
  setParams?: Function;
  onChange?: any;
  fontSize?: any;
  extendParams?: any;
  notBgContentTable?: any;
}
export default function DataTable({
  title,
  columns,
  data,
  total,
  headerLeft,
  headerRight,
  loading,
  limit = 20,
  setParams,
  showPagination = true,
  onChange,
  fontSize,
  extendParams,
  notBgContentTable,
}: DataTableProps) {
  const [pagination, setPagination] = useState({
    numPerPage: limit ? limit : 20,
    currPage: 1,
  });
  const [utc, setUtc] = useState(false);
  const maxRecord = total && total < MAX_RECORD ? total : MAX_RECORD;
  const pages =
    total && pagination ? Math.ceil(maxRecord / pagination.numPerPage) : 0;
  const offset = pagination.numPerPage * (pagination.currPage - 1);
  function changePage(dir: PaginationDirType) {
    if (!pages) return;
    let currPage = pagination.currPage;

    switch (true) {
      case dir === "first":
        currPage = 1;
        break;
      case dir === "last":
        currPage = pages;
        break;
      case dir === "next":
        if (pagination.currPage + 1 <= pages) {
          currPage = pagination.currPage + 1;
        }
        break;
      case dir === "prev":
        if (pagination.currPage - 1 >= 1) {
          currPage = pagination.currPage - 1;
        }
        break;
    }

    if (currPage !== pagination.currPage)
      setPagination({ ...pagination, currPage });
  }

  function changeNumPerPage(value: string) {
    let numPerPage = Number(value);

    if (numPerPage !== pagination.numPerPage)
      setPagination({ currPage: 1, numPerPage });
  }
  useEffect(() => {
    if (setParams)
      setParams({ ...extendParams, offset, limit: pagination.numPerPage });
  }, [pagination, setParams]);
  const parsedColumns =
    columns &&
    Array.isArray(columns) &&
    columns.map((col) => {
      if (col.dataIndex === "timestamp" || col.dataIndex === "verifyTime") {
        col.title = (
          <>
            {utc ? (
              <TextLink>TIME (UTC)</TextLink>
            ) : (
              <TextLink>
                TIME <ClockCircleOutlined />
              </TextLink>
            )}
          </>
        );
        col.render = (time: any) =>
          utc
            ? formatUtc(time, "MM-dd-yyyy HH:mm:ss")
            : timeFormatDistance(time);
        col.onHeaderCell = () => {
          return {
            onClick: () => {
              setUtc(!utc);
            },
          };
        };
      }
      return col;
    });
  return (
    <ContentTable className={"content-table " + notBgContentTable}>
      {title && (
        <TitleStyle level={2} className={"uppercase font-" + fontSize}>
          {title}
        </TitleStyle>
      )}
      <TableHeader className="mb-2">
        {headerLeft && (
          <Space>
            {loading && data.length > 0 && <Spin className="styled-spin" />}
            {headerLeft}
          </Space>
        )}
        {headerRight && !showPagination && headerRight}
        {!headerRight && showPagination && (
          <Space
            wrap
            className="d-flex-xs-justify-between w-xs-100 mt-xs-2 mr-a-1"
          >
            {/* {pages > 1 && ( */}
            {!!total && (
              <Space>
                <TextMain className="border-right d-inline">
                  <TextOverlay>Show</TextOverlay>
                  <Select
                    dropdownClassName="st-dropdown-theme"
                    value={pagination.numPerPage.toString()}
                    onChange={changeNumPerPage}
                  >
                    <Option value="10">10</Option>
                    <Option value="20">20</Option>
                    <Option value="40">40</Option>
                    <Option value="50">50</Option>
                  </Select>
                </TextMain>
              </Space>
            )}
            {/* )} */}
            {!!total && (
              <DataTablePagination
                changePage={changePage}
                pages={pages}
                pagination={pagination}
              />
            )}
          </Space>
        )}
      </TableHeader>
      <div style={{ position: "relative" }}>
        {loading ? (
          <SkeletonTable
            type="tokenBalance"
            record={pagination.numPerPage}
            loading={loading}
          />
        ) : (
          <Table
            className="style-table-bordered"
            columns={parsedColumns}
            dataSource={data || []}
            pagination={false}
            onChange={onChange}
            size="middle"
            scroll={{ x: parsedColumns.length > 5 ? 1100 : 550 }}
          />
        )}
      </div>

      {!!total && showPagination && (
        <PaginationWrapper className="mt-2 d-flex-xs-justify-between w-xs-100">
          <Space> </Space>
          <Space className="d-flex-xs-justify-between w-xs-100">
            {/* {pages > 1 && ( */}
            <Space>
              <TextMain className="border-right">
                <TextOverlay>Show</TextOverlay>
                <Select
                  dropdownClassName="st-dropdown-theme"
                  value={pagination.numPerPage.toString()}
                  onChange={changeNumPerPage}
                >
                  <Option value="10">10</Option>
                  <Option value="20">20</Option>
                  <Option value="40">40</Option>
                  <Option value="50">50</Option>
                </Select>
              </TextMain>
            </Space>
            {/* )} */}
            <DataTablePagination
              changePage={changePage}
              pages={pages}
              pagination={pagination}
            />
          </Space>
        </PaginationWrapper>
      )}
    </ContentTable>
  );
}
