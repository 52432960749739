import React, { useState } from "react";
import { Select, Space } from "antd";
import { useNavigate } from "react-router-dom";
import {
  StepBackwardOutlined,
  LeftOutlined,
  RightOutlined,
  StepForwardOutlined,
} from "@ant-design/icons";

import { useQuery, useWindowSize } from "app/hooks";
import { formatNumber } from "utils";
import { PaginationWrapper } from "common/DataTable/dataTableStyles";
import {
  ButtonStyled,
  CurrentNumber,
  TextMain,
  TextOverlay,
  TotalNumber,
} from "common/styledGeneral";

const { Option } = Select;

type DataTablePaginationProps = {
  currPage: number;
  pageTotal: null | number;
  pageSize: number;
  changeNumPerPage: (value: string) => void;
  address?: string;
};

export default function Paging({
  currPage,
  pageTotal,
  pageSize,
  changeNumPerPage,
  address,
}: DataTablePaginationProps) {
  const query = useQuery();
  const navigate = useNavigate();
  const { width } = useWindowSize();

  const handlePageChange = (dir: string) => {
    if (!pageTotal) return;
    let newCurrPage = currPage;
    switch (true) {
      case dir === "first":
        newCurrPage = 1;
        break;
      case dir === "last":
        newCurrPage = pageTotal;
        break;
      case dir === "next":
        if (currPage + 1 <= pageTotal) {
          newCurrPage = currPage + 1;
        }
        break;
      case dir === "prev":
        if (currPage - 1 >= 1) {
          newCurrPage = currPage - 1;
        }
        break;
    }
    if (address) query.set("acc", address);
    query.set("page", `${newCurrPage}`);
    query.set("number", `${pageSize}`);
    navigate({ search: query.toString() });
  };

  return (
    <PaginationWrapper>
      <TextMain className="border-right">
        <TextOverlay>Show</TextOverlay>
        <Select
          dropdownClassName="st-dropdown-theme"
          value={pageSize.toString()}
          onChange={changeNumPerPage}
        >
          <Option value="10">10</Option>
          <Option value="20">20</Option>
          <Option value="40">40</Option>
          <Option value="50">50</Option>
          <Option value="100">100</Option>
        </Select>
        {width && width > 767 ? <TextOverlay>per page</TextOverlay> : ""}
      </TextMain>
      <Space>
        <ButtonStyled
          disabled={currPage === 1}
          onClick={() => handlePageChange("first")}
        >
          <StepBackwardOutlined />
        </ButtonStyled>
        <ButtonStyled
          disabled={currPage === 1}
          onClick={() => handlePageChange("prev")}
        >
          <LeftOutlined />
        </ButtonStyled>
        <Space className="mx-05">
          <CurrentNumber>{formatNumber(currPage)}</CurrentNumber>
          <TotalNumber>
            {" "}
            of {currPage && formatNumber(pageTotal)} pages
          </TotalNumber>
        </Space>
        <ButtonStyled
          disabled={currPage === pageTotal}
          onClick={() => handlePageChange("next")}
        >
          <RightOutlined />
        </ButtonStyled>
        <ButtonStyled
          disabled={currPage === pageTotal}
          onClick={() => handlePageChange("last")}
        >
          <StepForwardOutlined />
        </ButtonStyled>
      </Space>
    </PaginationWrapper>
  );
}
