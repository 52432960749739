import React from "react";
import { Link } from "react-router-dom";
import BigNumber from "bignumber.js";
import { Tooltip } from "antd";
import { FileTextOutlined, FileDoneOutlined } from "@ant-design/icons";

import { addressCheckSum, formatNumber } from "utils";
import LinkFormat from "common/LinkFormat";
import { TextMain } from "common/styledGeneral";
import { ADDRESS_MAPPER } from "constants/address_mapper";

export const txColumnsLatestTransaction = [
  {
    title: "HASH",
    dataIndex: "hash",
    ellipsis: true,
    render: (hash: string, record: any) => (
      <LinkFormat
        type="tx"
        value={hash}
        truncate={false}
        status={record.status}
      />
    ),
    width: "100px",
  },
  {
    title: "FROM",
    dataIndex: "from",
    ellipsis: true,
    render: (from: string) => (
      <LinkFormat
        title={ADDRESS_MAPPER[from.toLowerCase()] || ""}
        type="address"
        value={addressCheckSum(from)}
        truncate={false}
      />
    ),
    width: "100px",
  },
  {
    title: "TO",
    dataIndex: "to",
    ellipsis: true,
    render: (to: string, record: any) =>
      to ? (
        <LinkFormat
          title={ADDRESS_MAPPER[to.toLowerCase()] || ""}
          type="address"
          value={addressCheckSum(to)}
          truncate={false}
        />
      ) : record?.contractAddress ? (
        <Tooltip
          title={addressCheckSum(record.contractAddress)}
          color="#4A2F4A"
        >
          <Link to={`/address/${record.contractAddress.toLowerCase()}`}>
            <TextMain style={{ marginRight: 5, display: "inline-block" }}>
              <FileDoneOutlined />
            </TextMain>
            Contract Creation
          </Link>
        </Tooltip>
      ) : (
        ""
      ),
    width: "100px",
  },
  {
    title: "AGE",
    dataIndex: "timestamp",
    ellipsis: true,
    width: "100px",
  },
  {
    title: "FEE(NEON)",
    dataIndex: "fee",
    ellipsis: true,
    render: (fee: number) => (
      <TextMain>
        {formatNumber(new BigNumber(fee).dividedBy(10 ** 18).toString(), 9)}
      </TextMain>
    ),
    width: "100px",
  },
];

export const txColumnsLatestBlocks = [
  {
    title: "BLOCK ID",
    dataIndex: "number",
    ellipsis: true,
    render: (blockid: string) => (
      <Link to={`/block/${blockid}`}>
        <TextMain className="link-color">#{blockid}</TextMain>
      </Link>
    ),
    width: "100px",
  },
  // {
  //   title: "OPERATOR",
  //   dataIndex: "hash",
  //   render: (hash: string) => (
  //     <LinkFormat type="tx" value={hash} truncate={false} />
  //   ),
  // },
  {
    title: "TX COUNT",
    dataIndex: "transactions",
    ellipsis: true,
    width: "100px",
  },
  {
    title: "AGE",
    dataIndex: "timestamp",
    ellipsis: true,
    width: "100px",
  },
];
