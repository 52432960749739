import React, { useState } from "react";

import { useGetTokenHolderQuery } from "app/services/token";
import { holderColumnsToken } from "./columnsConfig";
import DataTable from "common/DataTable";
import DataTableTotal from "common/DataTable/DataTableTotal";

export function HolderInToken({
  address,
  price,
}: {
  address: string;
  price: number;
}) {
  const [args, setArgs] = useState({ address: "", offset: 0, limit: 20 });
  const { data, error, isLoading } = address
    ? useGetTokenHolderQuery({ ...args, address })
    : { data: null, error: null, isLoading: false };
  const newData = data?.data?.items.map((item: any) => {
    return { ...item, price };
  });
  return (
    <DataTable
      notBgContentTable="not-bg-content-table"
      data={newData ? newData : []}
      headerLeft={
        <DataTableTotal type="holder" total={data?.data?.total || 0} />
      }
      total={data?.data?.total || 0}
      columns={holderColumnsToken}
      setParams={setArgs}
      loading={isLoading}
    />
  );
}
