import React, { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";

import { useGetBlockListQuery } from "app/services/block";
import { useQuery } from "app/hooks";
import { Columns } from "features/blocks/columnsConfig";
import { Wrapper, Container, TitleStyle } from "common/styledGeneral";
import DataTableTotal from "common/DataTable/DataTableTotal";
import { WrapTable } from "common/TableAccountTxs/styled";
import SkeletonTable from "common/SkeletonTable";
import { DataTableNew } from "common/TableAccountTxs/Table";
import Paging from "common/TableAccountTxs/Pagination";
import { notification } from "antd";

export function BlockList() {
  // const [args, setArgs] = useState({ offset: 0, limit: 20 });
  const query = useQuery();
  const currentPage = parseInt(query.get("page") || "1");
  const pageSize =
    parseInt(query.get("number") || "20") > 100
      ? 100
      : parseInt(query.get("number") || "20");
  const [limit, setLimit] = useState(pageSize);

  const navigate = useNavigate();
  const offset = useMemo(() => {
    return (currentPage - 1) * pageSize;
  }, [currentPage, pageSize]);

  const { data, isFetching } = useGetBlockListQuery({
    offset,
    limit: pageSize,
  });

  // use update limit when direct /verified-contracts?number -> verified-contracts
  useEffect(() => {
    if (pageSize && pageSize !== limit) setLimit(pageSize);
    if (parseInt(query.get("number") || "10") > 100) {
      notification["warning"]({
        message: "Warning",
        description: "Each page will only load a maximum of 100 records",
        placement: "bottomRight",
      });
    }
  }, [pageSize]);

  const pageTotal = useMemo(() => {
    return data?.data?.total && pageSize
      ? Math.ceil(data?.data?.total / pageSize)
      : 0;
  }, [data?.data?.total, pageSize]);

  function changeNumPerPage(value: string) {
    if (value) {
      setLimit(Number(value));
      query.set("number", value);
      navigate({ search: query.toString() });
    }
  }
  return (
    <Wrapper>
      <Helmet>
        <title>{`Blocks`}</title>
      </Helmet>
      <Container className="mx-md-down-0">
        <WrapTable>
          <TitleStyle level={2} className="uppercase">
            Recent Blocks
          </TitleStyle>
          <div
            style={{
              display: "flex",
              alignContent: "center",
              justifyContent: "space-between",
              flexWrap: "wrap",
              marginBottom: 20,
            }}
          >
            <div>
              <DataTableTotal
                total={data?.data?.total ? data.data.total : ""}
                type="block"
              />
            </div>
            <Paging
              currPage={currentPage}
              pageTotal={pageTotal}
              pageSize={limit}
              changeNumPerPage={changeNumPerPage}
            />
          </div>
          {isFetching ? (
            <SkeletonTable type="blockPage" record={20} loading={isFetching} />
          ) : (
            <DataTableNew
              columns={Columns}
              data={data?.success && data?.data?.items ? data.data.items : []}
              loading={isFetching}
            />
          )}
          <div style={{ marginTop: 20 }}>
            <Paging
              currPage={currentPage}
              pageTotal={pageTotal}
              pageSize={limit}
              changeNumPerPage={changeNumPerPage}
            />
          </div>
        </WrapTable>
        {/* <DataTable
            title="Recent Blocks"
            data={data?.success && data?.data?.items ? data.data.items : []}
            columns={Columns}
            total={data?.data?.total ? data.data.total : ""}
            headerLeft={
              <DataTableTotal
                total={data?.data?.total ? data.data.total : ""}
              />
            }
            setParams={setArgs}
            loading={isFetching}
          /> */}
      </Container>
    </Wrapper>
  );
}
