import styled from "styled-components";
import { Link } from "react-router-dom";

const LinkButton = styled(Link)`
  margin-top: 20px;
  padding: 5px 10px;
  border-radius: 4px;
  display: inline-block;
  background-color: ${({ theme }) => theme.bg6};
`;

export { LinkButton };
