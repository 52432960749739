import React from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Col, Divider, Row, Space, Tabs } from "antd";
import { skipToken } from "@reduxjs/toolkit/dist/query";

import { BlockNumberType } from "constants/types";
import { useGetBlockByNumberQuery } from "app/services/block";
import { timeFormatDistance, timeFormatUtc, formatNumber } from "utils";
import {
  Wrapper,
  Container,
  TextOverlay,
  TextMain,
  TabStyle,
  TabBoxDetail,
} from "common/styledGeneral";
import RowLoading from "common/RowLoading";
import CopyIcon from "common/CopyIcon";
import { PageTitle } from "common/PageTitle";
import TabsWithHash from "common/TabWithHash";
import { TxsOnBlock } from "./TxsOnBlock";

const { TabPane } = Tabs;

export function BlockDetail() {
  const { blockNumber } = useParams<BlockNumberType>();
  const { data, isLoading } = useGetBlockByNumberQuery(
    blockNumber || skipToken
  );

  return (
    <Wrapper>
      {blockNumber && (
        <Helmet>
          <title>{`Block ${blockNumber}`}</title>
        </Helmet>
      )}
      <Container className="mx-md-down-0">
        {isLoading ? (
          <RowLoading />
        ) : (
          <TabStyle>
            <PageTitle title="Blocks Details" fontSize="18" />
            {/* <Row gutter={24}>
              <Col lg={24}>
                <Space>
                  <CopyIcon value={data.data.number} after={false} />
                  <Link to={`/block/${data.data.number}`}>
                    <TextMain>#{data.data.number}</TextMain>
                  </Link>
                </Space>
              </Col>
            </Row> */}
            <TabBoxDetail>
              <TabsWithHash type="card" tabKeys={["transactions"]}>
                <TabPane tab="Details" key="default">
                  <Row gutter={24}>
                    <Col span={9} md={5} lg={4}>
                      <TextOverlay className="mv-2">
                        Block height &nbsp;
                      </TextOverlay>
                    </Col>
                    <Col span={15} md={19} lg={20}>
                      <Space className="mv-2" align="start">
                        <Link to={`/block/${data.data.number}`}>
                          <TextMain>{data.data.number}</TextMain>
                        </Link>
                        <CopyIcon value={data.data.number} after={true} />
                      </Space>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col span={9} md={5} lg={4}>
                      <TextOverlay className="mv-2">
                        Timestamp &nbsp;
                      </TextOverlay>
                    </Col>
                    <Col span={15} md={19} lg={20}>
                      <Space wrap className="word-break mt-1">
                        <TextMain>
                          {timeFormatDistance(data.data.timestamp)}
                        </TextMain>
                        <Divider type="vertical" />
                        <TextOverlay>
                          {timeFormatUtc(data.data.timestamp)}
                        </TextOverlay>
                      </Space>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col span={9} md={5} lg={4}>
                      <TextOverlay className="mv-2">
                        Transactions &nbsp;
                      </TextOverlay>
                    </Col>
                    <Col span={15} md={19} lg={20}>
                      <TextMain className="mv-2">
                        {data.data.transactions}
                      </TextMain>
                    </Col>
                  </Row>
                  <Divider className="my-1" />
                  <Row gutter={24}>
                    <Col span={9} md={5} lg={4}>
                      <TextOverlay className="mv-2">
                        Gas used &nbsp;
                      </TextOverlay>
                    </Col>
                    <Col span={15} md={19} lg={20}>
                      <TextMain className="mv-2">
                        {formatNumber(data.data.gasUsed)}
                      </TextMain>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col span={9} md={5} lg={4}>
                      <TextOverlay className="mv-2">
                        Gas limit &nbsp;
                      </TextOverlay>
                    </Col>
                    <Col span={15} md={19} lg={20}>
                      <TextMain className="mv-2">
                        {formatNumber(data.data.gasLimit)}
                      </TextMain>
                    </Col>
                  </Row>
                  <Divider className="my-1" />
                  <Row gutter={24}>
                    <Col span={9} md={5} lg={4}>
                      <TextOverlay className="mv-2">
                        Block hash &nbsp;
                      </TextOverlay>
                    </Col>
                    <Col span={15} md={19} lg={20}>
                      <TextMain className="word-break mv-2">
                        {data.data.hash}
                      </TextMain>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col span={9} md={5} lg={4}>
                      <TextOverlay className="mv-2">
                        Parent hash &nbsp;
                      </TextOverlay>
                    </Col>
                    <Col span={15} md={19} lg={20}>
                      <Space className="mv-2" align="start">
                        <Link to={`/block/${data.data.number - 1}`}>
                          <TextMain className="word-break link-color">
                            {data.data.parentHash}
                          </TextMain>
                        </Link>
                        <CopyIcon
                          value={(data.data.number - 1).toString()}
                          after={true}
                        />
                      </Space>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tab="Transactions" key="transactions">
                  <TxsOnBlock blockNumber={blockNumber ? blockNumber : ""} />
                </TabPane>
              </TabsWithHash>
            </TabBoxDetail>
          </TabStyle>
        )}
      </Container>
    </Wrapper>
  );
}
