import React from "react";
import { Divider, Tabs } from "antd";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { InfoCircleOutlined } from "@ant-design/icons";
import CopyIcon from "common/CopyIcon";
import Code from "./Code";
import Read from "./Read";
import Write from "./Write";
import { TextOverlay } from "common/styledGeneral";

const { TabPane } = Tabs;

export function ContractData({ data }: { data: any }) {
  // console.log("dataaaa", data);
  // const abiContract = data?.contractData?.abiCode ? JSON.parse(data.contractData.abiCode) : null
  // const contractData = abiContract ? getContract(abiContract, address ? address : '') : ''
  const readForm =
    data?.contractData?.abi &&
    data.contractData.abi.length > 0 &&
    data.contractData.abi.filter((abi: any) => {
      return (
        abi.outputs &&
        (abi.stateMutability === "view" || abi.stateMutability === "pure")
      );
    });
  const writeForm =
    data?.contractData?.abi &&
    data.contractData.abi.length > 0 &&
    data.contractData.abi.filter((abi: any) => {
      return (
        abi.outputs &&
        abi.stateMutability !== "view" &&
        abi.stateMutability !== "pure"
      );
    });

  const readProxyForm =
    data?.contractData?.proxyTarget &&
    data?.contractData?.proxyTargetData?.abi &&
    data.contractData.proxyTargetData.abi.length > 0 &&
    data.contractData.proxyTargetData.abi.filter((abi: any) => {
      return (
        abi.outputs &&
        (abi.stateMutability === "view" || abi.stateMutability === "pure")
      );
    });
  const writeProxyForm =
    data?.contractData?.proxyTarget &&
    data?.contractData?.proxyTargetData?.abi &&
    data.contractData.proxyTargetData.abi.length > 0 &&
    data.contractData.proxyTargetData.abi.filter((abi: any) => {
      return (
        abi.outputs &&
        abi.stateMutability !== "view" &&
        abi.stateMutability !== "pure"
      );
    });
  return (
    <div style={{ position: "relative" }}>
      {!data?.contractData?.sourceCode ? (
        <>
          <NoteStyled>
            <Link to={`/contracts/verify?address=${data?.address}`}>
              <InfoCircleOutlined /> Verify Contract Code
            </Link>
          </NoteStyled>
          <StylePostiton>
            <CopyIcon value={data?.code} />
          </StylePostiton>

          <ContractSource height="15pc">{data.code}</ContractSource>
        </>
      ) : (
        <TabStyled className="mt-2">
          <Tabs type="card">
            <TabPane tab="Code" key="default">
              <Code data={data?.contractData || {}} />
            </TabPane>
            <TabPane tab="Read Contract" key="readContract">
              <Read data={readForm || []} />
            </TabPane>
            <TabPane tab="Write Contract" key="writeContract">
              <Write
                data={writeForm || []}
                abi={data?.contractData?.abi || []}
              />
            </TabPane>
            {readProxyForm && (
              <TabPane tab="Read as Proxy" key="readProxyContract">
                <TextOverlay>
                  ABI for the implementation contract at
                </TextOverlay>{" "}
                <Link to={`/address/${data?.contractData?.proxyTarget}`}>
                  {data?.contractData?.proxyTarget}
                </Link>
                <Divider style={{ marginBottom: 16, marginTop: 16 }} />
                <Read data={readProxyForm || []} />
              </TabPane>
            )}
            {writeProxyForm && (
              <TabPane tab="Write as Proxy" key="writeProxyContract">
                <TextOverlay>
                  ABI for the implementation contract at
                </TextOverlay>{" "}
                <Link to={`/address/${data?.contractData?.proxyTarget}`}>
                  {data?.contractData?.proxyTarget}
                </Link>
                <Divider style={{ marginBottom: 16, marginTop: 16 }} />
                <Write
                  data={writeProxyForm || []}
                  abi={data?.contractData?.proxyTargetData?.abi || []}
                />
              </TabPane>
            )}
          </Tabs>
        </TabStyled>
      )}
    </div>
  );
}

const NoteStyled = styled.div`
  margin: 5px 0 20px 0px;
  color: ${({ theme }) => theme.textOverlay};
`;
const StylePostiton = styled.span`
  position: absolute;
  right: 0;
  top: 0;
`;

const ContractSource = styled.pre<{ height?: string }>`
  background-color: ${({ theme }) => theme.bg3};
  font-size: 13px;
  padding: 15px;
  white-space: pre-wrap;
  height: ${({ height }) => (height ? height : "20px")};
  word-wrap: break-word;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.border2};
  color: ${({ theme }) => theme.textOverlay};
`;

const TabStyled = styled.div`
  .ant-tabs-tab {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn:after {
    display: none;
  }
  .ant-tabs-tabpane {
    border: none;
  }
  .ant-tabs-tab-btn {
    font-size: 13px;
  }
`;
