import React from "react";
import { Col, Row } from "antd";

import { formatNumber } from "utils";
import { useGetTxsNumberQuery } from "app/services/account";
import { TextMain, TextOverlay } from "common/styledGeneral";

export const TxsNumber = ({ address }: { address: string }) => {
  const { data, error, isLoading } = useGetTxsNumberQuery({
    list_address: [`${address}`],
  });
  return (
    <>
      {data?.data[address]?.neonTxs && (
        <Row gutter={24}>
          <Col span={7} md={6} lg={5}>
            <TextOverlay className="mv-2">Neon Txs</TextOverlay>
          </Col>

          <Col span={17} md={18} lg={19}>
            <TextMain className="mv-2">
              {formatNumber(data.data[address].neonTxs)}
            </TextMain>
          </Col>
        </Row>
      )}
      {data?.data[address]?.solanaTxs && (
        <Row gutter={24}>
          <Col span={7} md={6} lg={5}>
            <TextOverlay className="mv-2">Solana Txs</TextOverlay>
          </Col>

          <Col span={17} md={18} lg={19}>
            <TextMain className="mv-2">
              {formatNumber(data.data[address].solanaTxs)}
            </TextMain>
          </Col>
        </Row>
      )}
    </>
  );
};
