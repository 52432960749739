import styled from "styled-components";
import { Button, Form, Input, List } from "antd";

export const FormStyled = styled(Form)`
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.bg1};
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.primary};
  position: relative;
  .ant-input {
    color: ${({ theme }) => theme.text};
  }
  .anticon-close-circle {
    color: ${({ theme }) => theme.textOverlay};
  }
`;

export const SearchBox = styled.div`
  width: 100%;
  margin: 32px 0;
  @media only screen and (max-width: 991px) {
    margin: 15px 0 10px;
  }
`;

export const FormItemStyled = styled.div`
  position: relative;
  width: 100%;
  z-index: 1;
  &:before {
    content: "";
    position: absolute;
    width: 80px;
    height: 120px;
    left: -30px;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(195, 0, 225, 0.35);
    filter: blur(50px);
    z-index: -1;
  }
`;

export const InputStyled = styled(Input)`
  background: rgba(212, 158, 255, 0.07);
  backdrop-filter: blur(15px);
  box-shadow: inset 0px 0px 15px rgba(212, 158, 255, 0.1);
  border-radius: 8px;
  border: none;
  padding: 15px;
  position: relative;
  width: 100%;

  .ant-input,
  .ant-select-selector {
    background: transparent;
    padding-left: 5px !important;
    color: ${({ theme }) => theme.text};
  }

  .ant-input-suffix {
    .anticon.ant-input-clear-icon {
      color: #fff;
    }
  }
`;

export const ButtonSearch = styled(Button)`
  background: ${({ theme }) => theme.primary};
  border-radius: 8px;
  border: 0 !important;
  color: #fff;
  width: 48px !important;
  height: 40px !important;
  margin-right: 4px;
  padding: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  z-index: 9;
  &:hover {
    background: ${({ theme }) => theme.primaryHover};
    color: #fff;
  }

  .anticon-search {
    line-height: 0;
  }
`;

export const ListWrapper = styled.div`
  background: ${({ theme }) => theme.bg};
  backdrop-filter: blur(500px);
  border-radius: 8px;
  overflow: hidden;
  margin-top: 7px;
  position: absolute;
  z-index: 1000;
  width: 100%;
  padding: 0px 0 15px;
`;

export const ListStyled = styled(List)`
  border: none;

  .ant-list-items {
    margin: 0 !important;
  }

  .ant-list-header {
    background: ${({ theme }) => theme.bg5};
    border-bottom: 1px solid ${({ theme }) => theme.border2} !important;
    padding: 7px 15px !important;
  }

  .ant-spin-nested-loading {
    border-radius: 0 !important;
    border: 0 !important;
    max-height: 320px;
    overflow-y: auto;
  }
`;

export const ListHeader = styled.div`
  text-align: left;
  font-weight bold;
  padding: 7px 15px;
  color: ${({ theme }) => theme.text};
`;

export const ListItemStyled = styled(List.Item)`
  flex-direction: column;
  padding: 10px 15px !important;
  margin: 0 !important;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.border2} !important;
  cursor: pointer;
  &:last-child {
    border: 0 !important;
  }
  &:hover {
    background-color: ${({ theme }) => theme.bgHover};
  }
`;

export const Token = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 3px;
`;

export const TokenHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 3px;
  color: ${({ theme }) => theme.textOverlay};
`;
export const TokenTitle = styled.div`
  margin-left: 14px;
  font-weight: 500;
  text-align: initial;
  color: ${({ theme }) => theme.text};
`;

export const TokenExtension = styled.div`
  margin-left: 14px;
  font-size: 14px;
  text-align: left;
  width: 100%;
  color: ${({ theme }) => theme.textOverlay};
`;

export const MenuSmallMobile = styled.div`
  background: transparent;
  align-items: center;
  width: 100% !important;
  overflow: hidden;
  overflow-x: auto;
  .noBorder {
    border: 0;
  }
  .ant-row {
    align-items: center;
    height: 64px;
    flex-flow: nowrap;
  }
  .ant-menu {
    background: transparent;
    width: 100%;
    align-items: center !important;
    justify-content: center !important;
    padding: 0 !important;
    margin: 0 !important;
    li {
      color: ${({ theme }) => theme.text};
      border: 0 !important;
      padding: 0 14px !important;
      // &:first-child {
      //   padding-left: 0 !important;
      // }
      // &:last-child {
      //   padding-right: 0 !important;
      // }
      &:after {
        display: none;
      }
      .ant-menu-title-content {
        color: ${({ theme }) => theme.text};
        a {
          color: ${({ theme }) => theme.text};
        }
      }
      &.ant-menu-item-selected * {
        font-weight: bold;
        color: ${({ theme }) => theme.textHover}!important;
      }
    }
  }
  .ant-menu-submenu-selected {
    color: ${({ theme }) => theme.text};
    font-weight: bold;
  }
  @media only screen and (max-width: 767px) {
    .pc-menu {
      background: ${({ theme }) => theme.bg};
      display: none;
    }
  }
`;
