import React, { useState } from "react";
import { Tooltip } from "antd";
import { CopyToClipboard } from "react-copy-to-clipboard";

import iconCopy from "assets/images/icon-copy.svg";

interface CopyIconProps {
  value: string;
  after?: boolean;
}
export default function CopyIcon({ value, after }: CopyIconProps) {
  const [isCopied, setCopied] = useState(false);
  const style = after ? { marginLeft: "5px" } : { marginRight: "5px" };

  return (
    <Tooltip
      placement="top"
      title={isCopied ? "Copied" : "Copy to clipboard"}
      color="#4A2F4A"
    >
      <CopyToClipboard
        text={value}
        onCopy={() => setCopied(true)}
        //@ts-ignore
        onMouseEnter={() => setCopied(false)}
      >
        <img
          className="copyIcon"
          width="15"
          style={{ ...style, cursor: "pointer" }}
          src={iconCopy}
          alt="icon copy"
        />
      </CopyToClipboard>
    </Tooltip>
  );
}
