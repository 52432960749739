import React from "react";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Tabs } from "antd";

interface TabsWithHashProps {
  tabsComp?: any;
  children?: any;
  type?: string;
  activeTab?: string;
  tabKeys: Array<string>;
}

export default function TabsWithHash({
  tabsComp: TabsComp = Tabs,
  children,
  activeTab,
  tabKeys,
  ...rest
}: TabsWithHashProps) {
  const navigate = useNavigate();
  let { hash, search } = useLocation();
  hash = hash?.replace("#", "") || "";
  search = search?.slice(1) || "";
  const [activeKey, setActiveKey] = useState("default");

  useEffect(() => {
    if (!!hash && tabKeys.includes(hash)) {
      setActiveKey(hash);
    } else {
      setActiveKey("default");
    }
  }, [hash]);

  useEffect(() => {
    if (!activeTab) return;

    handleOnTabClick(activeTab);
  }, [activeTab]);

  function handleOnTabClick(key: string) {
    setActiveKey(key);
    if (key !== "default") navigate({ hash: key, search });
    else navigate({ hash: "", search });
  }

  return (
    <TabsComp activeKey={activeKey} onTabClick={handleOnTabClick} {...rest}>
      {children}
    </TabsComp>
  );
}
