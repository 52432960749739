import React, { useState } from "react";
import styled from "styled-components";
import { Button, Input } from "antd";
import { TxParseDataType } from "constants/types";
import { txParseDataColumns } from "../columnsConfig";
import DataTable from "common/DataTable";
import CopyIcon from "common/CopyIcon";
import { TextMain, CustomStyleTable, ValueRow } from "common/styledGeneral";

interface TxParseDataProp {
  input: string;
  data: TxParseDataType;
}
const { TextArea } = Input;

export default function TxParseData({ data, input }: TxParseDataProp) {
  const [value, setValue] = useState("default");
  function getValue(v: string) {
    setValue(v);
  }
  if (!data || !input) return null;
  let params = [];
  let inputDataSource = [];
  if (
    data.encodedInput &&
    data.decodedInput &&
    Object.keys(data.encodedInput).length > 0 &&
    Object.keys(data.decodedInput).length > 0
  ) {
    params = data.decodedInput.params.map(
      (item: any) => `${item.type} ${item.name}`
    );
    inputDataSource = data.decodedInput.params.map(
      (item: any, index: number) => {
        const inputData = data.decodedInput.data[item?.name || index];

        return { ...item, index, data: inputData };
      }
    );
    return (
      <>
        {value === "default" && (
          <BorderStyle>
            {data?.decodedInput?.name && (
              <ValueRow>
                <TextMain className="word-break">
                  Function: {data.decodedInput.name}({params.join(", ")})
                </TextMain>
              </ValueRow>
            )}
            {data?.encodedInput?.methodId && (
              <ValueRow>
                <TextMain>MethodId: {data.encodedInput.methodId}</TextMain>
              </ValueRow>
            )}
            {data.encodedInput.data.map((item, index) => {
              return (
                <ValueRow key={index}>
                  <TextMain className="word-break">
                    [{index}]: {item}
                  </TextMain>
                </ValueRow>
              );
            })}
          </BorderStyle>
        )}
        {value === "original" && (
          <>
            <StylePosition>
              <CopyIcon value={input} />
            </StylePosition>
            <BorderStyle>
              <TextMain className="word-break">{input}</TextMain>
            </BorderStyle>
          </>
        )}
        {value === "decode" && (
          <BorderStyle className="b-0 p-0">
            <DataTable
              data={inputDataSource}
              columns={txParseDataColumns}
              showPagination={false}
            />
          </BorderStyle>
        )}

        <ValueRow>
          <Button
            className={`btn-switch${value === "original" ? " active" : ""}`}
            onClick={() => getValue("original")}
          >
            <TextMain>Original</TextMain>
          </Button>
          <Button
            className={`btn-switch${value === "default" ? " active" : ""}`}
            onClick={() => getValue("default")}
          >
            <TextMain>Default View</TextMain>
          </Button>
          <Button
            className={`btn-switch${value === "decode" ? " active" : ""}`}
            onClick={() => getValue("decode")}
          >
            <TextMain>Decode Input Data</TextMain>
          </Button>
        </ValueRow>
      </>
    );
  }
  return (
    <>
      <StylePosition>
        <CopyIcon value={input} />
      </StylePosition>
      <BorderStyle>
        <TextMain className="word-break">{input}</TextMain>
      </BorderStyle>
    </>
  );
}

const TextAreaStyled = styled(TextArea)`
  border-color: ${({ theme }) => theme.border2};
  background-color: transparent;
  color: ${({ theme }) => theme.textOverlay};
  border-radius: 8px;
  &.ant-input {
    height: auto;
  }
  &:hover,
  &:focus,
  &.ant-input-focused {
    border-color: ${({ theme }) => theme.border2};
    box-shadow: 0 0 1px 2px rgb(145 120 149 / 20%);
  }
`;

const BorderStyle = styled.div`
  border: 1px solid ${({ theme }) => theme.border2};
  border-radius: 8px;
  padding: 15px;
  margin: 10px 0;
  max-height: 400px;
  overflow-y: auto;
  .content-table {
    margin-top: -15px;
    padding: 0;
    background: none;
  }
`;

const StylePosition = styled.span`
  position: absolute;
  right: 20px;
  top: -35px;
`;
