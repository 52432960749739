import React, { useEffect, useState } from "react";
import {
  Link as CustomLink,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";

import { Menu, Row, Col, Select } from "antd";

import {
  CaretDownOutlined,
  WarningFilled,
  CloseOutlined,
  MenuOutlined,
  UserOutlined,
} from "@ant-design/icons";

import { currentNetwork, selectNetwork } from "app/services/general";
import {
  useAppDispatch,
  useAppSelector,
  useNavigateSearch,
  useQuery,
} from "app/hooks";

import { Header, CustomDrawer, ToggleBtnMenu, SelectNetwork } from "./styled";

import { Container } from "common/styledGeneral";
import LogoNeonScan from "assets/images/logo.svg";
import iconCloseTr from "assets/images/icon-close-tr.svg";
import MenuOutlinedImg from "assets/images/menu-outlined-img.svg";
import iconContracts from "assets/images/icon-contracts.svg";
import iconCheckList from "assets/images/icon-check-list.svg";
import iconResources from "assets/images/icon-resources.svg";
import iconToken from "assets/images/icon-tokens.svg";
import iconTransaction from "assets/images/icon-transactions.svg";

const { Option } = Select;
const { SubMenu } = Menu;

function Navbar() {
  const [isActive, setActive] = useState(false);
  const [visible, setVisible] = useState(false);
  // const location = useLocation();
  const dispatch = useAppDispatch();
  const defaultNetwork = useAppSelector(currentNetwork);
  // const navigateSearch = useNavigateSearch();
  // const query = useQuery();
  // const cluster = query.get("cluster")?.trim() || "";

  // const onChangeNetwork = (network: any) => {
  //   dispatch(selectNetwork(network));
  //   if (network === "mainnet") {
  //     navigateSearch(location.pathname);
  //   } else {
  //     navigateSearch(location.pathname, { cluster: network });
  //   }
  //   window.location.reload();
  // };

  const onClick = (network: any) => {
    if (network === "testnet") {
      window.location.replace("https://testnet.neonscan.org/");
    } else if (network === "devnet") {
      window.location.replace("https://devnet.neonscan.org/");
    } else {
      window.location.replace("https://neonscan.org/");
    }
  };

  // Toggled
  const toggleClass = () => {
    setActive(!isActive);
  };

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  // submenu keys of first level
  const rootSubmenuKeys = [
    "sub1",
    "sub2",
    "sub3",
    "sub4",
    "sub5",
    "sub6",
    "sub7",
  ];
  const [openKeys, setOpenKeys] = React.useState([""]);
  const onOpenChange = (keys: any) => {
    const latestOpenKey = keys.find((key: any) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  return (
    <Header>
      <Container>
        <Row justify="space-between" gutter={16}>
          <Col>
            <CustomLink to={""}>
              <img height={24} src={LogoNeonScan} alt="LOGO NEONSCAN" />
            </CustomLink>
          </Col>
          <Col xs={{ span: 0 }} lg={{ span: 24 }} flex="auto">
            <Menu
              mode="horizontal"
              className="noBorder"
              style={{ justifyContent: "flex-end" }}
            >
              <Menu.Item key="home">
                <CustomLink to="/">Home</CustomLink>
              </Menu.Item>

              <Menu.Item key="txs">
                <CustomLink to="/txs">Transactions</CustomLink>
              </Menu.Item>

              <Menu.Item key="blocks">
                <CustomLink to="/blocks">Blocks</CustomLink>
              </Menu.Item>
              <Menu.Item key="tokens">
                <CustomLink to="/tokens">Tokens</CustomLink>
              </Menu.Item>
              <Menu.Item key="verifiedContract">
                <CustomLink to="/verified-contracts">
                  Verified Contracts
                </CustomLink>
              </Menu.Item>
              <Menu.Item key="stats">
                <CustomLink to="/stats">Stats</CustomLink>
              </Menu.Item>

              {/* <SubMenu
                popupClassName="neon-sub-menu"
                key="resources"
                title={
                  <div>
                    Resources <CaretDownOutlined className="d-xs-none" />
                  </div>
                }
              >
                <Menu.Item>
                  <CustomLink to={""} onClick={onClose}>
                    Analysis
                  </CustomLink>
                </Menu.Item>
                <Menu.Item>
                  <CustomLink to={""} onClick={onClose}>
                    Public-api
                  </CustomLink>
                </Menu.Item>
                <Menu.Item>
                  <CustomLink to={""} onClick={onClose}>
                    Introduction
                  </CustomLink>
                </Menu.Item>
              </SubMenu> */}
            </Menu>
          </Col>
          <Col className="ml-auto">
            <SelectNetwork
              dropdownClassName="st-dropdown-network"
              value={defaultNetwork}
              onChange={onClick}
            >
              <Option value="mainnet">Mainnet</Option>
              {/* <Option value="testnet" disabled>
                Testnet
              </Option> */}
              <Option value="devnet">Devnet</Option>
            </SelectNetwork>
          </Col>

          {/* Menu Mobile */}
          <Col lg={{ span: 0 }}>
            <ToggleBtnMenu className="btn-menu-sp" onClick={showDrawer}>
              <img src={MenuOutlinedImg} />
            </ToggleBtnMenu>
            <CustomDrawer
              title={
                <div className="text-center">
                  <span className="btn-back" onClick={onClose}>
                    <img src={iconCloseTr} />
                  </span>
                  <CustomLink to={""}>
                    <img height={24} src={LogoNeonScan} alt="LOGO NEONSCAN" />
                  </CustomLink>
                </div>
              }
              height={"100%"}
              placement="bottom"
              closable={false}
              onClose={onClose}
              visible={visible}
            >
              <Menu
                className="st-menu"
                openKeys={openKeys}
                onOpenChange={onOpenChange}
                mode="inline"
              >
                <Menu.Item key="txs">
                  <CustomLink
                    className="d-flex-center"
                    to="/txs"
                    onClick={onClose}
                  >
                    <img className="mr-1" src={iconTransaction} /> Transactions
                  </CustomLink>
                </Menu.Item>

                <Menu.Item key="blocks">
                  <CustomLink
                    className="d-flex-center"
                    to="/blocks"
                    onClick={onClose}
                  >
                    <img className="mr-1" src={iconContracts} /> Blocks
                  </CustomLink>
                </Menu.Item>
                <Menu.Item key="tokens">
                  <CustomLink
                    className="d-flex-center"
                    to="/tokens"
                    onClick={onClose}
                  >
                    <img className="mr-1" src={iconToken} /> Tokens
                  </CustomLink>
                </Menu.Item>
                <Menu.Item key="verifiedContract">
                  <CustomLink
                    className="d-flex-center"
                    to="/verified-contracts"
                    onClick={onClose}
                  >
                    <img className="mr-1" src={iconCheckList} /> Verified
                    Contracts
                  </CustomLink>
                </Menu.Item>
                <Menu.Item key="stats">
                  <CustomLink
                    className="d-flex-center"
                    to="/stats"
                    onClick={onClose}
                  >
                    <img className="mr-1" src={iconTransaction} /> Stats
                  </CustomLink>
                </Menu.Item>
                {/* <SubMenu
                  key="sub1"
                  title={
                    <div className="d-flex-center">
                      <img className="mr-1" src={iconResources} /> Resources
                    </div>
                  }
                >
                  <Menu.Item>
                    <CustomLink to={""} onClick={onClose}>
                      Analysis
                    </CustomLink>
                  </Menu.Item>
                  <Menu.Item>
                    <CustomLink to={""} onClick={onClose}>
                      Public-api
                    </CustomLink>
                  </Menu.Item>
                  <Menu.Item>
                    <CustomLink to={""} onClick={onClose}>
                      Introduction
                    </CustomLink>
                  </Menu.Item>
                </SubMenu> */}
              </Menu>
            </CustomDrawer>
          </Col>
        </Row>
      </Container>
    </Header>
  );
}

export default Navbar;
