import React, { useState, FunctionComponent } from "react";
import { Link, useParams } from "react-router-dom";
import BigNumber from "bignumber.js";
import styled from "styled-components";
import { Form, Space, Spin } from "antd";
import { FileTextOutlined } from "@ant-design/icons";

import { getContract } from "utils";
import { TextMain, TextOverlay } from "common/styledGeneral";
import {
  Title,
  FormGroup,
  FormItem,
  ButtonStyled,
  InputStyled,
} from "./styled";

type ReadOutputState = {
  number: any;
  outputs: any;
  name: string;
  contract: any;
};
const ReadDataOutput: FunctionComponent<ReadOutputState> = ({
  number,
  outputs,
  name,
  contract,
}) => {
  const [data, setData] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  // useEffect(() => {
  const fetchData = async () => {
    try {
      setIsLoading(true);
      const result = await contract[name]();
      setData(result);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };
  // fetchData();
  // }, []);
  function isObject(val: any) {
    return val instanceof Object;
  }
  return (
    <>
      <SubTitle
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <TextMain>{`${number}. ${name}`}</TextMain>
        <ButtonStyled
          onClick={fetchData}
          style={{ marginTop: 0 }}
          disabled={isLoading}
        >
          {isLoading && (
            <Spin
              size="small"
              style={{ color: "inherit", marginRight: "10px" }}
            />
          )}
          <TextMain className="inline-block">Query</TextMain>
        </ButtonStyled>
      </SubTitle>
      {data !== null && data !== undefined && (
        <BoxContent>
          {outputs.map((s: any, j: number) => {
            return (
              <div key={`o-content-${j}`}>
                <>
                  {s?.type.indexOf("[]") !== -1 ? (
                    // type is Array
                    <>
                      {s.name && <TextOverlay>{s.name}:</TextOverlay>}
                      {/* if data[s.name] */}
                      {data?.[s?.name]
                        ? Array.isArray(data?.[s?.name]) &&
                          data[s.name].length > 0 &&
                          data[s.name].map((d: any, i: number) => {
                            return s.type.indexOf("address") !== -1 ? (
                              <div key={`address-${i}`}>
                                <Link to={`/address/${d}`}>{d}</Link> <br />
                              </div>
                            ) : (
                              <TextMain key={`output-${i}`}>{d}</TextMain>
                            );
                          })
                        : // If output type array == data
                          Array.isArray(data) &&
                          data.length > 0 &&
                          data.map((d: any, i: number) => {
                            return s.type.indexOf("address") !== -1 ? (
                              <div key={`address-${i}`}>
                                <Link to={`/address/${d}`}>{d}</Link> <br />
                              </div>
                            ) : (
                              <TextMain key={`output-${i}`}>{d}</TextMain>
                            );
                          })}
                    </>
                  ) : Array.isArray(data) ? (
                    <Space>
                      {s.name && <TextOverlay>{s.name}:</TextOverlay>}
                      {data?.[s.name] ? (
                        <>
                          {data[s.name]?._isBigNumber ? (
                            <TextMain>
                              {new BigNumber(data[s.name]._hex).toString()}
                            </TextMain>
                          ) : (
                            <TextMain>{data?.[s?.name] || ""}</TextMain>
                          )}
                        </>
                      ) : data?.[j]?._isBigNumber ? (
                        <TextMain>
                          {new BigNumber(data[j]?._hex).toString()}
                        </TextMain>
                      ) : (
                        <TextMain>{`${data?.[j] || ""}`}</TextMain>
                      )}
                    </Space>
                  ) : !Array.isArray(data) &&
                    isObject(data) &&
                    data !== null ? (
                    <>
                      {s.name && <TextOverlay>{s.name}:</TextOverlay>}
                      {data?._isBigNumber ? (
                        <TextMain>
                          {
                            // @ts-ignore
                            // data[s.name]
                            BigNumber(data._hex).toString()
                          }
                        </TextMain>
                      ) : (
                        <TextMain>{data[s.name]}</TextMain>
                      )}
                    </>
                  ) : (
                    <>
                      {s.type === "address" ? (
                        <Link to={`/address/${data}`}>{data}</Link>
                      ) : (
                        <TextMain>{`${data}`}</TextMain>
                      )}
                    </>
                  )}
                  <TextOverlay
                    style={{ fontStyle: "italic", fontSize: "13px" }}
                  >
                    {" "}
                    {s.type}
                  </TextOverlay>
                </>
              </div>
            );
          })}
        </BoxContent>
      )}
    </>
  );
};

type ReadData = {
  number: number;
  data: any;
  name: string;
  contract: any;
};

const ReadForm: FunctionComponent<ReadData> = ({
  number,
  data,
  name,
  contract,
}) => {
  const [dataResult, setDataResult]: Array<any> = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const onReadFinish = async (value: any) => {
    let formatData: Array<any> = [];
    setIsLoading(true);
    const params = Object.values(value);
    // Convert data with type []
    const extendParams = params.map((item: any) => {
      if (item.startsWith("[") && item.endsWith("]")) {
        item = JSON.parse(item);
      }
      return item;
    });
    try {
      if (contract) {
        const result = await contract[name](...extendParams);
        for (let i = 0; i < data.outputs.length; i++) {
          const output = data.outputs[i];
          let outputRs = output;
          let value;
          if (
            typeof result === "object" && // get value Object
            result !== null &&
            !Array.isArray(result) && // remove Array
            !result._isBigNumber // remove value BigNumber
          ) {
            value = result[output.name];
            if (value !== "undefined") {
              value = result[i];
            }
          } else if (Array.isArray(result)) {
            value = result[i];
            // value = JSON.stringify(result);
          } else {
            if (result && result._isBigNumber) {
              value = new BigNumber(result._hex).toString();
            } else {
              value = result;
            }
          }
          if (output.type === "address") {
            if (value === 0) {
              value = "0x0000000000000000000000000000000000000000";
            }

            value = value.toLowerCase();
          }
          outputRs = { ...outputRs, value };
          formatData.push(outputRs);
        }
      }
    } catch (error: any) {
      formatData.push({
        name: "Error",
        type: "string",
        value: error.message,
      });
    }
    setDataResult(formatData);
    setIsLoading(false);
  };
  return (
    <>
      <SubTitle>
        <TextMain>{`${number}. ${name}`}</TextMain>
      </SubTitle>
      <BoxContent>
        <Form onFinish={onReadFinish}>
          {data.inputs.map((input: any, i: number) => {
            return (
              <FormGroup key={`rf-content-${i}`}>
                <TextOverlay>{`${input.name} (${input.type})`}</TextOverlay>
                <FormItem name={input.name ? input.name : name + i}>
                  <InputStyled placeholder={`${input.name} (${input.type})`} />
                </FormItem>
              </FormGroup>
            );
          })}
          <ButtonStyled htmlType="submit" disabled={isLoading}>
            <TextMain>Query</TextMain>
          </ButtonStyled>{" "}
          &nbsp;
          {isLoading && <Spin size="small" />}
          <div style={{ marginTop: "20px" }}>
            {dataResult.length > 0 && (
              <TextMain>
                [<b>{name}</b> method response]
              </TextMain>
            )}
            {dataResult.map((d: any, k: number) => {
              return d.name === "Error" ? (
                <div key={`r-${k}`}>
                  <TextMain
                    style={{
                      marginRight: "10px",
                      color: "#B32E33",
                      fontStyle: "italic",
                    }}
                  >
                    {d.name}
                  </TextMain>
                  <TextOverlay style={{ fontStyle: "italic" }}>
                    {d.type.toString()}:
                  </TextOverlay>{" "}
                  <TextMain
                    style={{
                      color: "#B32E33",
                      fontStyle: "italic",
                    }}
                  >
                    {d.value.toString()}
                  </TextMain>
                </div>
              ) : (
                <div key={`r-${k}`}>
                  {d.name && (
                    <TextMain style={{ marginRight: "10px" }}>
                      {d.name}
                    </TextMain>
                  )}
                  {
                    <TextOverlay style={{ fontStyle: "italic" }}>
                      {d.type.toString()}:
                    </TextOverlay>
                  }{" "}
                  &nbsp;
                  {d.value !== null ? (
                    <TextMain>{d.value.toString()}</TextMain>
                  ) : (
                    <TextMain>null</TextMain>
                  )}{" "}
                  &nbsp;
                </div>
              );
            })}
          </div>
        </Form>
      </BoxContent>
    </>
  );
};

function Read({ data }: { data: any }) {
  const { address } = useParams();
  const contractData = data ? getContract(data, address ? address : "") : "";
  return (
    <>
      {data.length > 0 && (
        <>
          <Title>
            <FileTextOutlined style={{ color: "#836c87" }} /> Read Contract
            Information
          </Title>
          {data.map((v: any, i: number) => {
            return (
              <Box key={`r-content-${i}`}>
                {/* <SubTitle>
                  <TextMain>{`${i + 1}. ${v.name}`}</TextMain>
                </SubTitle> */}
                {/* <BoxContent> */}
                {v.inputs.length < 1 ? (
                  <ReadDataOutput
                    number={i + 1}
                    outputs={v.outputs}
                    name={v.name}
                    contract={contractData}
                  />
                ) : (
                  <ReadForm
                    number={i + 1}
                    data={v}
                    name={v.name}
                    contract={contractData}
                  />
                )}
                {/* </BoxContent> */}
              </Box>
            );
          })}
        </>
      )}
    </>
  );
}

const Box = styled.div`
  border: 1px solid ${({ theme }) => theme.borderOverlay};
  margin-bottom: 20px;
  border-radius: 8px;
  overflow: hidden;
`;

const SubTitle = styled.div`
  padding: 8px 15px;
  margin-bottom: 0;
  border-bottom: 1px solid ${({ theme }) => theme.borderOverlay};
`;

const BoxContent = styled.div`
  padding: 15px;
`;
export default Read;
