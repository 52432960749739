import React, { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";

import { formatNumber } from "utils";
import { useGetTokenListQuery } from "app/services/token";
import { useQuery, useWindowSize } from "app/hooks";
import { Columns } from "./columnsConfig";
import DataTableTotal from "common/DataTable/DataTableTotal";
import {
  Wrapper,
  Container,
  TitleStyle,
  TextOverlay,
  BoxStyleCommon,
} from "common/styledGeneral";
import { LayoutTokenMB } from "features/tokens/LayoutMB";
import SkeletonTable from "common/SkeletonTable";
import { WrapTable } from "common/TableAccountTxs/styled";
import { DataTableNew } from "common/TableAccountTxs/Table";
import { useNavigate } from "react-router-dom";
import Paging from "common/TableAccountTxs/Pagination";
import { TableHeader } from "common/DataTable/dataTableStyles";
import { notification } from "antd";

export function TokenList() {
  const { width } = useWindowSize();
  const query = useQuery();
  const currentPage = parseInt(query.get("page") || "1");
  const pageSize =
    parseInt(query.get("number") || "20") > 100
      ? 100
      : parseInt(query.get("number") || "20");
  const [limit, setLimit] = useState(pageSize);

  const offset = useMemo(() => {
    return (currentPage - 1) * pageSize;
  }, [currentPage, pageSize]);

  const [sort, setSort] = useState({
    sortby: "fd",
    sorttype: "desc",
  });
  const navigate = useNavigate();

  const { data, isFetching } = useGetTokenListQuery({
    offset,
    limit: pageSize,
    sortby: sort.sortby,
    sorttype: sort.sorttype,
  });
  // use update limit when direct /tokens?number -> token
  useEffect(() => {
    if (pageSize && pageSize !== limit) setLimit(pageSize);
    if (parseInt(query.get("number") || "10") > 100) {
      notification["warning"]({
        message: "Warning",
        description: "Each page will only load a maximum of 100 records",
        placement: "bottomRight",
      });
    }
  }, [pageSize]);

  const pageTotal = useMemo(() => {
    return data?.data?.total && pageSize
      ? Math.ceil(data?.data?.total / pageSize)
      : 0;
  }, [data?.data?.total, pageSize]);

  function changeNumPerPage(value: string) {
    if (value) {
      setLimit(Number(value));
      query.set("number", value);
      navigate({ search: query.toString() });
    }
  }

  function handleTableChange(pagination: any, filters: any, sorter: any) {
    if (sorter && sorter.field && sorter.order) {
      let sortby, sorttype;
      switch (true) {
        case sorter.field === "price":
          sortby = "price";
          break;
        case sorter.field === "holder":
          sortby = "holder";
          break;
        case sorter.field === "priceChangePercentage24h":
          sortby = "priceChangePercentage24h";
          break;
        default:
          sortby = "fd";
          break;
      }

      sorttype = sorter.order === "ascend" ? "asc" : "desc";
      setSort({ ...sort, sortby, sorttype });
    }
  }
  return (
    <Wrapper>
      <Helmet>
        <title>Tokens</title>
      </Helmet>
      <Container className="mx-md-down-0">
        {width && width > 991 ? (
          <WrapTable>
            <TitleStyle style={{ marginBottom: 20 }} level={2}>
              TOKENS
            </TitleStyle>
            <div
              style={{
                display: "flex",
                alignContent: "center",
                justifyContent: "space-between",
                flexWrap: "wrap",
                marginBottom: 20,
              }}
            >
              <div>
                <DataTableTotal
                  total={data?.data?.total ? data.data.total : ""}
                  type="tx"
                />
              </div>
              <Paging
                currPage={currentPage}
                pageTotal={pageTotal}
                pageSize={limit}
                changeNumPerPage={changeNumPerPage}
              />
            </div>
            {isFetching ? (
              <SkeletonTable
                type="tokenPage"
                record={20}
                loading={isFetching}
              />
            ) : (
              <DataTableNew
                data={data?.success && data?.data?.items ? data.data.items : []}
                columns={Columns}
                loading={isFetching}
                onChange={handleTableChange}
              />
            )}
            <div style={{ marginTop: 20 }}>
              <Paging
                currPage={currentPage}
                pageTotal={pageTotal}
                pageSize={limit}
                changeNumPerPage={changeNumPerPage}
              />
            </div>
          </WrapTable>
        ) : (
          <BoxStyleCommon>
            <TitleStyle level={2}>TOKENS</TitleStyle>
            <TableHeader className="mb-2">
              {data?.data?.total && (
                <TextOverlay>
                  Total {formatNumber(data.data.total)} tokens
                </TextOverlay>
              )}
              <WrapTable className="not-bg-content-table">
                <Paging
                  currPage={currentPage}
                  pageTotal={pageTotal}
                  pageSize={limit}
                  changeNumPerPage={changeNumPerPage}
                />
              </WrapTable>
            </TableHeader>
            <LayoutTokenMB
              data={data?.data ? data.data : []}
              total={data?.success && data?.data?.total ? data.data.total : ""}
              loading={isFetching}
            />
            <WrapTable className="not-bg-content-table">
              <Paging
                currPage={currentPage}
                pageTotal={pageTotal}
                pageSize={limit}
                changeNumPerPage={changeNumPerPage}
              />
            </WrapTable>
          </BoxStyleCommon>
        )}
      </Container>
    </Wrapper>
  );
}
