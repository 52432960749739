import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useGetHolderByTimeQuery } from "app/services/token";
import { holderColumnsToken } from "../columnsConfig";
import DataTable from "common/DataTable";
import DataTableTotal from "common/DataTable/DataTableTotal";
import { TextMain, TextOverlay, TitleStyle } from "common/styledGeneral";
import { Col, Row, Select, Space } from "antd";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import {
  dataFormater,
  dateFormater,
  formatNumber,
  formatUtc,
  timeFormatDistance,
} from "utils";

const { Option } = Select;

export function HolderLineChart({ address }: { address: string }) {
  const [args, setArgs] = useState({ address: "", type: "1H", size: 20 });
  const { data, error, isLoading } = address
    ? useGetHolderByTimeQuery({ ...args, address })
    : { data: null, error: null, isLoading: false };
  const newData = data?.data.map((item: any) => {
    return {
      ...item,
      dateTime: formatUtc(
        item.unixTime,
        args.type === "1H" ? "HH:mm" : "dd/MM"
      ),
    };
  });

  return (
    <>
      <Space
        style={{
          padding: "5px 10px 5px 10px",
          backgroundColor: "#281B29",
          marginBottom: 20,
          marginTop: 10,
          borderRadius: 5,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <TextMain className="bold block">Holder Over Time</TextMain>
        <Select
          dropdownClassName="st-dropdown-theme"
          defaultValue={args.type}
          onChange={(value) => setArgs({ ...args, type: value ? value : "1H" })}
        >
          <Option value="1H">Hour</Option>
          <Option value="1D">Day</Option>
        </Select>
      </Space>
      <ResponsiveContainer height={320}>
        <LineChart
          data={newData || []}
          margin={{
            top: 10,
            right: 0,
            left: -10,
            bottom: 0,
          }}
        >
          <CartesianGrid
            strokeWidth={0.5}
            strokeDasharray="7"
            vertical={false}
          />
          <XAxis
            dataKey="dateTime"
            axisLine={false}
            padding={{ left: 10 }}
            tickLine={false}
          />
          <Tooltip content={<TooltipChart />} />
          <YAxis
            axisLine={false}
            tickLine={false}
            tickFormatter={dataFormater}
          />
          <Line
            type="monotone"
            dataKey="value"
            stroke="#C74AE3"
            activeDot={{ r: 1 }}
            strokeWidth={3}
            name="Holders"
          />
        </LineChart>
      </ResponsiveContainer>
    </>
  );
}

function TooltipChart({ active, payload }: any) {
  if (active && payload && payload.length) {
    return (
      <TooltipWrap>
        <TextOverlay style={{ fontSize: "12px" }}>
          {timeFormatDistance(payload[0].payload.unixTime)}
        </TextOverlay>
        {/* <TextOverlay className="block" style={{ fontSize: "12px" }}>
          {formatDistanceStrict(
            payload[0].payload.unixTime * 1000,
            new Date(),
            {
              unit: timeTxChart === "1D" ? "hour" : "minute",
            }
          )}{" "}
          ago
        </TextOverlay> */}
        <TextMain style={{ fontSize: "13px" }}>
          Holder: {formatNumber(payload[0].payload.value)}
        </TextMain>
        {/* <TextMain style={{ fontSize: "13px" }}>
          TPS: {formatNumber(payload[0].payload.tps, 4)}
        </TextMain> */}
      </TooltipWrap>
    );
  }
  return null;
}

const TooltipWrap = styled.div`
  background-color: ${({ theme }) => theme.bg1};
  padding: 10px 15px;
  border-radius: 8px;
`;
