import React from "react";
import styled from "styled-components";
import { Skeleton } from "antd";
import {
  Bar,
  BarChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";
import { dataFormater, formatNumber, formatUtc } from "utils";
import {
  BoxChart,
  TextMain,
  TextOverlay,
  TextStyle1,
} from "common/styledGeneral";
import { useGetTxsChartQuery } from "app/services/stats";
import { formatDistanceStrict } from "date-fns";

export function TxsChart({ type }: { type: string }) {
  const { data, error, isLoading } = useGetTxsChartQuery(type);

  const newTxChart =
    data?.data &&
    data.data.length > 0 &&
    data.data.map((item: any, key: number) => {
      const dateType = formatUtc(
        item.unixTime,
        type === "1D" ? "HH:ss" : "dd/MM"
      );
      return { ...item, dateType };
    });
  return (
    <>
      <TextOverlay className="mv-2">New Transactions Over Time</TextOverlay>
      {isLoading ? (
        <Skeleton />
      ) : (
        <BoxChartStyled>
          <ResponsiveContainer width="100%" height="100%" aspect={2.5}>
            <BarChart
              data={newTxChart ? newTxChart : []}
              margin={{
                top: 5,
                left: -10,
              }}
            >
              <XAxis
                dataKey="dateType"
                axisLine={false}
                tickLine={false}
                stroke="rgba(238, 200, 247, 0.6)"
                tickSize={10}
              />
              <YAxis
                tickFormatter={dataFormater}
                axisLine={false}
                tickLine={false}
                tickSize={10}
                stroke="rgba(238, 200, 247, 0.6)"
              />
              <Tooltip
                content={<TooltipContent />}
                cursor={{
                  stroke: "#A93EC1",
                  fill: "#A93EC1",
                }}
                // allowEscapeViewBox={{ y: true }}
              />
              <Bar dataKey="value" fill="#99DCF7" />
            </BarChart>
          </ResponsiveContainer>
        </BoxChartStyled>
      )}
    </>
  );
}

const TooltipContent = ({ active, payload }: any) => {
  if (active && payload && payload.length) {
    return (
      <TooltipWrap>
        <TextOverlay style={{ fontSize: "12px" }}>
          {formatUtc(payload[0].payload.unixTime)}
        </TextOverlay>
        <TextMain style={{ fontSize: "13px" }}>
          Transactions: {formatNumber(payload[0].payload.value)}
        </TextMain>
        {/* <TextMain style={{ fontSize: "13px" }}>
          TPS: {formatNumber(payload[0].payload.tps, 4)}
        </TextMain> */}
      </TooltipWrap>
    );
  }
  return null;
};

const BoxChartStyled = styled(BoxChart)`
  background-color: ${({ theme }) => theme.bg5};
`;

const TooltipWrap = styled.div`
  background-color: ${({ theme }) => theme.bg1};
  padding: 10px 15px;
  border-radius: 8px;
`;
