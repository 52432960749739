import React from "react";
import { FooterStyle } from "./styled";
import { Container, TextOverlay } from "common/styledGeneral";

function FooterMain() {
  return (
    <FooterStyle>
      <Container className="text-center">
        <TextOverlay className="opacity-06 font-12">
          Powered by Neon EVM
        </TextOverlay>
      </Container>
    </FooterStyle>
  );
}
export default FooterMain;
