import React from "react";
import { Divider } from "antd";
import styled from "styled-components";
import { WarningOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { BoxDetail, TextOverlay } from "common/styledGeneral";

const TransactionNull = ({ value }: { value: string }) => {
  return (
    <BoxDetail>
      <BoxStyled>
        <span style={{ color: "#D9852B", fontSize: 30 }}>
          <WarningOutlined />
        </span>
        <div>
          <TextOverlay style={{ fontSize: 18, fontWeight: 600 }}>
            Sorry, we are unable to find this transaction hash.
          </TextOverlay>
          <div>
            <TextOverlay style={{ wordBreak: "break-word" }}>
              {value}
            </TextOverlay>
          </div>
        </div>
      </BoxStyled>
      <Divider style={{ marginBottom: 16 }} />
      <div
        style={{
          display: "flex",
          gap: 10,
          color: "#d9852b",
          alignItems: "center",
          marginBottom: 15,
        }}
      >
        <InfoCircleOutlined />
        <span>
          Neonscan provides an overview of the current blockchain state,
          including your transaction status, but we do not have control over
          these transactions. Please consider the following:
        </span>
      </div>
      <ul
        className="mb-0"
        style={{
          listStyle: "none",
          paddingLeft: 5,
          display: "flex",
          flexDirection: "column",
          gap: 15,
        }}
      >
        <li>
          <TextOverlay>
            1. If you have recently submitted a transaction, kindly wait for at
            least 10 seconds before refreshing this page.
          </TextOverlay>
        </li>
        <li>
          <TextOverlay>
            2. Your transaction might still be in the TX Pool of a different
            node, waiting to be broadcasted.
          </TextOverlay>
        </li>
        <li>
          <TextOverlay>
            3. During peak network activity, it may take some time for your
            transaction to propagate through the network and for us to index it.
          </TextOverlay>
        </li>
        <li>
          <TextOverlay>
            4. If your transaction does not appear after 5 minutes, we recommend
            checking with the sender, exchange, wallet, or transaction provider
            for additional information.
          </TextOverlay>
        </li>
      </ul>
    </BoxDetail>
  );
};

export default TransactionNull;

const BoxStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  @media (max-width: 767px) {
    flex-direction: column;
    text-align: center;
    gap: 10px;
  }
`;
