import React, { useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import BigNumber from "bignumber.js";
import { Helmet } from "react-helmet-async";
import { Divider, Space, Row, Col, Tabs } from "antd";
import { CopyrightOutlined } from "@ant-design/icons";

import { AddressType } from "constants/types";
import {
  useGetTokenOverviewQuery,
  useGetTokenTransferQuery,
} from "app/services/token";
import {
  Wrapper,
  Container,
  BoxStyleCommon,
  TextMain,
  TitleStyle,
  BoxStyleInner,
  TextOverlay,
  TabStyle,
  TabBoxDetail,
} from "common/styledGeneral";

import { addressCheckSum, formatNumber } from "utils";
import CopyIcon from "common/CopyIcon";
import { Up, Down } from "common/Tag";
import TabsWithHash from "common/TabWithHash";
import { txColumnsToken } from "./columnsConfig";
import DataTable from "common/DataTable";
import DataTableTotal from "common/DataTable/DataTableTotal";
import RowLoading from "common/RowLoading";
import { HolderInToken } from "./HolderOfToken";
import LinkFormat from "common/LinkFormat";
import { HolderLineChart } from "./chart/HolderLineChart";
import { HolderPieChart } from "./chart/HolderPieChart";

const { TabPane } = Tabs;

export function TokenDetail() {
  const [txArgs, setTxArgs] = useState({ offset: 0, limit: 20 });
  const { address } = useParams<AddressType>();
  // @ts-ignore
  const { data, error, isLoading } = useGetTokenOverviewQuery(address);
  const {
    data: txData,
    error: txError,
    isFetching: txLoading,
    // @ts-ignore
  } = useGetTokenTransferQuery({ ...txArgs, address });

  // console.log("!!data?.success", !!data?.success);
  // if (!data || data?.data == null || error)
  //   return <Navigate to={`/search?q=${address}`} />;

  return (
    <Wrapper>
      {data?.data?.name && (
        <Helmet>
          <title>{data.data.name}</title>
        </Helmet>
      )}
      <Container className="mx-md-down-0">
        {isLoading ? (
          <RowLoading />
        ) : (
          <BoxStyleCommon>
            <TitleStyle level={1} className="uppercase font-18">
              <Space>
                {data?.data?.logoURI ? (
                  <img width="24" src={data.data.logoURI} />
                ) : (
                  <CopyrightOutlined style={{ fontSize: "24px" }} />
                )}
                Token
                <TextOverlay>
                  {data?.data?.name} ({data?.data?.symbol})
                </TextOverlay>
              </Space>
            </TitleStyle>
            <TextMain className="flex">
              <CopyIcon value={addressCheckSum(data?.data?.address)} />
              <LinkFormat
                value={addressCheckSum(data?.data?.address)}
                title={addressCheckSum(data?.data?.address)}
              />
            </TextMain>
            <Divider className="my-2" />

            <Row gutter={[24, 24]}>
              <Col className="pt-5" xs={{ span: 24 }} md={{ span: 12 }}>
                <TitleStyle className="mb-2 font-16 absolute" level={2}>
                  Overview
                </TitleStyle>
                <BoxStyleInner>
                  {!!data?.data?.price && (
                    <Row gutter={24}>
                      <Col span={10} md={8}>
                        <TextOverlay className="mv-2">Price</TextOverlay>
                      </Col>
                      <Col span={14} md={16}>
                        <Space>
                          <TextMain className="mv-2">
                            ${formatNumber(data.data.price)}
                          </TextMain>
                          {data.data.priceChangePercentage24h > 0 && (
                            <Up
                              number={formatNumber(
                                data.data.priceChangePercentage24h,
                                2
                              )}
                            />
                          )}
                          {data.data.priceChangePercentage24h < 0 && (
                            <Down
                              number={formatNumber(
                                data.data.priceChangePercentage24h,
                                2
                              )}
                            />
                          )}
                        </Space>
                      </Col>
                    </Row>
                  )}
                  <Row gutter={24}>
                    <Col span={10} md={8}>
                      <TextOverlay className="mv-2">Total supply</TextOverlay>
                    </Col>
                    <Col span={14} md={16}>
                      <TextMain className="mv-2">
                        {data?.data?.totalSupply !== null &&
                        data?.data?.totalSupply >= 0
                          ? formatNumber(
                              new BigNumber(data.data.totalSupply)
                                .dividedBy(10 ** data.data.decimals)
                                .toNumber()
                            )
                          : "__"}
                      </TextMain>
                    </Col>
                  </Row>
                  {!!data?.data?.fd && (
                    <Row gutter={24}>
                      <Col span={10} md={8}>
                        <TextOverlay className="mv-2">
                          Fully diluted market cap
                        </TextOverlay>
                      </Col>
                      <Col span={14} md={16}>
                        <TextMain className="mv-2">
                          $
                          {formatNumber(
                            new BigNumber(data.data.fd)
                              .dividedBy(10 ** data.data.decimals)
                              .toNumber()
                          )}
                        </TextMain>
                      </Col>
                    </Row>
                  )}
                  {!!data?.data?.holder && (
                    <Row gutter={24}>
                      <Col span={10} md={8}>
                        <TextOverlay className="mv-2">Holder</TextOverlay>
                      </Col>
                      <Col span={14} md={16}>
                        <TextMain className="mv-2">
                          {formatNumber(data.data.holder)}
                        </TextMain>
                      </Col>
                    </Row>
                  )}
                </BoxStyleInner>
              </Col>
              <Col className="pt-5" xs={{ span: 24 }} md={{ span: 12 }}>
                <TitleStyle className="mb-2 font-16 absolute" level={2}>
                  More info
                </TitleStyle>
                <BoxStyleInner>
                  {/* <Row gutter={24}>
                    <Col span={10} md={8}>
                      <TextOverlay className="mv-2">Created at</TextOverlay>
                    </Col>
                    <Col span={14} md={16}>
                      <TextMain className="mv-2">
                        <Space direction="vertical">
                          {timeFormatDistance(
                            Date.parse(data.data.createdAt) / 1000
                          )}
                          <TextOverlay>
                            {formatUtc(Date.parse(data.data.createdAt) / 1000)}
                          </TextOverlay>
                        </Space>
                      </TextMain>
                    </Col>
                  </Row> */}
                  <Row gutter={24}>
                    <Col span={10} md={8}>
                      <TextOverlay className="mv-2">Decimals</TextOverlay>
                    </Col>
                    <Col span={14} md={16}>
                      <TextMain className="mv-2">
                        {data?.data?.decimals ? data.data.decimals : "__"}
                      </TextMain>
                    </Col>
                  </Row>
                  <Divider className="my-1" />
                  {data?.data?.extensions?.website && (
                    <Row gutter={24}>
                      <Col span={10} md={8}>
                        <TextOverlay className="mv-2">Website</TextOverlay>
                      </Col>
                      <Col span={14} md={16}>
                        <a href={data.data.extensions.website}>
                          <TextMain className="link-color mv-2">
                            {data.data.extensions.website}
                          </TextMain>
                        </a>
                      </Col>
                    </Row>
                  )}
                </BoxStyleInner>
              </Col>
            </Row>

            <TabStyle className="not-bg-tab mt-3">
              <TabBoxDetail className="tmp-tab-box2">
                <TabsWithHash type="card" tabKeys={["holder", "analytics"]}>
                  <TabPane tab="Transfers" key="default">
                    <DataTable
                      fontSize="16"
                      notBgContentTable="not-bg-content-table"
                      data={txData?.data?.items ? txData.data.items : []}
                      headerLeft={
                        <DataTableTotal
                          total={txData?.data?.total ? txData.data.total : 0}
                          type="tx"
                        />
                      }
                      total={txData?.data?.total ? txData.data.total : 0}
                      columns={txColumnsToken}
                      setParams={setTxArgs}
                      loading={txLoading}
                    />
                  </TabPane>
                  <TabPane tab="Holder" key="holder">
                    <HolderInToken
                      address={address ? address : ""}
                      price={data?.data?.price || ""}
                    />
                  </TabPane>
                  <TabPane tab="Analytics" key="analytics">
                    <Row gutter={24}>
                      <Col span={24} md={12}>
                        <HolderLineChart address={address ? address : ""} />
                      </Col>
                      <Col span={24} md={12}>
                        <HolderPieChart
                          address={address ? address : ""}
                          totalSupply={
                            data?.data?.totalSupply ? data.data.totalSupply : 0
                          }
                        />
                      </Col>
                    </Row>
                  </TabPane>
                </TabsWithHash>
              </TabBoxDetail>
            </TabStyle>
          </BoxStyleCommon>
        )}
      </Container>
    </Wrapper>
  );
}
