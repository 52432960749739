import React, { ChangeEvent, useState } from "react";
import { useNavigate } from "react-router";
import { Menu } from "antd";
import { useWindowSize } from "app/hooks";
import { Link as CustomLink } from "react-router-dom";
import { CopyrightOutlined } from "@ant-design/icons";

import { useGetTokenArgQuery } from "app/services/general";
import { useGeneralContext } from "providers/general";

import {
  FormItemStyled,
  SearchBox,
  InputStyled,
  ListWrapper,
  ListStyled,
  ListItemStyled,
  ListHeader,
  TokenHeader,
  TokenTitle,
  TokenExtension,
  MenuSmallMobile,
} from "common/SearchBox/searchBoxStyled";
import iconSearch from "assets/images/icon-search.svg";

export default function SearchContainer() {
  const { width } = useWindowSize();
  const [search, setSearch] = useState({ searchString: "", type: "" });
  const [searchResult, setSuggestions] = useState<{ tokens: Array<any> }>({
    tokens: [],
  });
  const { network } = useGeneralContext();
  const navigate = useNavigate();
  const { data, error, isLoading } = useGetTokenArgQuery("tokenlist.json");
  const tokensList =
    data?.tokens &&
    data.tokens.length > 0 &&
    data.tokens.filter((item: any) => {
      return item.chainId === data.chainInfo[network];
    });
  const tokenSuggestions = (searchInput: string, tokenList: Array<any>) => {
    const tokenSuggestions = tokenList.filter(
      (token: any) =>
        token.name.toLowerCase().includes(searchInput.toLowerCase()) ||
        token.symbol.toLowerCase().includes(searchInput.toLowerCase()) ||
        token.address.toLowerCase().includes(searchInput.toLowerCase())
    );

    return tokenSuggestions;
  };
  const handelOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const searchString = e.target.value.trim();
    if (!searchString) return resetState();
    const getTokenResult = tokenSuggestions(
      searchString,
      tokensList ? Object.values(tokensList) : []
    );
    setSuggestions({ ...searchResult, tokens: getTokenResult });

    switch (true) {
      case searchString.length === 66:
        setSearch({ searchString, type: "tx" });
        break;

      case !searchString.match(/\D+/):
        setSearch({ searchString, type: "block" });
        break;

      default:
        setSearch({ searchString, type: "address" });
        break;
    }
  };
  const handleOnSearch = () => {
    if (searchResult.tokens.length > 0) {
      navigate(`/token/${searchResult.tokens[0].address.toLowercase()}`);
      return resetState();
    }
    if (search.searchString && search.type) {
      navigate(`/${search.type}/${search.searchString.toLowerCase()}`);
      return resetState();
    }
  };
  const handleOnSelect = (address: string, type: string) => {
    navigate(`/${type}/${address.toLowerCase()}`);
    return resetState();
  };
  function resetState() {
    setSuggestions({ tokens: [] });
    setSearch({ searchString: "", type: "" });
  }
  return (
    <SearchBox>
      <FormItemStyled>
        <InputStyled
          placeholder="Search tx hash, account, token, block ..."
          onChange={handelOnChange}
          onPressEnter={handleOnSearch}
          value={search.searchString}
          allowClear
          prefix={<img src={iconSearch} />}
        />
        {search.searchString && searchResult.tokens.length > 0 && (
          <SearchResult data={searchResult.tokens} onSelect={handleOnSelect} />
        )}
      </FormItemStyled>
      {width && width > 991 ? (
        "  "
      ) : (
        <MenuSmallMobile className="mt-1">
          <Menu className="noBorder" mode="horizontal">
            <Menu.Item key="mb-txs">
              <CustomLink to="/txs">Transactions</CustomLink>
            </Menu.Item>
            <Menu.Item key="mb-blocks">
              <CustomLink to="/blocks">Blocks</CustomLink>
            </Menu.Item>
            <Menu.Item key="mb-tokens">
              <CustomLink to="/tokens">Tokens</CustomLink>
            </Menu.Item>
            <Menu.Item key="mb-docs">
              <CustomLink to="/docs">Docs</CustomLink>
            </Menu.Item>
          </Menu>
        </MenuSmallMobile>
      )}
    </SearchBox>
  );
}

function SearchResult({ data, onSelect }: { data: any; onSelect: Function }) {
  return (
    <ListWrapper>
      <ListStyled
        itemLayout="horizontal"
        bordered
        header={<ListHeader>Tokens</ListHeader>}
        dataSource={data}
        renderItem={(item: any) => (
          <ListItemStyled onClick={() => onSelect(item.address, "token")}>
            <TokenHeader>
              {item?.logoURI ? (
                <img src={item.logoURI} width={24} />
              ) : (
                <CopyrightOutlined style={{ fontSize: "24px" }} />
              )}
              <span style={{ wordBreak: "break-all" }}>
                <TokenTitle>
                  {item.name} ({item.symbol})
                </TokenTitle>
                <TokenExtension>{item.address.toLowerCase()}</TokenExtension>
              </span>
            </TokenHeader>
          </ListItemStyled>
        )}
      />
    </ListWrapper>
  );
}
