import React, { useState, FunctionComponent } from "react";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import { ethers } from "ethers";
import { Tabs, Row, Col, Spin, Tooltip, Form, Input, Result } from "antd";
import { FileTextOutlined } from "@ant-design/icons";
import { TextMain, TextOverlay } from "common/styledGeneral";
import {
  Title,
  FormGroup,
  FormItem,
  ButtonStyled,
  InputStyled,
} from "./styled";
import ConnectWallet from "./ConnectWallet";
import BigNumber from "bignumber.js";

type WriteData = {
  data: any;
  name: string;
  abiCode: any;
  // contractAddress: string;
  account: string;
  isConnected: boolean;
};
const WriteForm: FunctionComponent<WriteData> = ({
  data,
  name,
  abiCode,
  account,
  isConnected,
}) => {
  const [dataResult, setDataResult]: Array<any> = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { address } = useParams();
  const onWriteFinish = async (value: any) => {
    let formatData: Array<any> = [];
    setIsLoading(true);

    const payableValue = value["payable-value"] ?? null;
    // removing payable value from params
    delete value["payable-value"];

    const params = Object.values(value);
    // Convert data with type []
    const extendParams = params.map((item: any) => {
      if (item && item.startsWith("[") && item.endsWith("]")) {
        try {
          item = JSON.parse(item.replace(/'/g, '"'));
        } catch (e) {
          // console.log(e);
          formatData.push({
            name: "Error",
            type: "string",
            value:
              "Invalid constructor arguments. Value must be array with JSON format. Eg: ['0x223db9c68e90aF24D5e768a2C781F7e8046b47ff', '0x9a607465c45cbD2960D8B861b0cf5E12C9Bb241D']",
          });
        }
      }
      return item;
    });
    try {
      if (!isConnected) {
        throw new Error("Please connect Metamask!");
      }
      if (window.ethereum && account && address && abiCode) {
        await window.ethereum.request({ method: "eth_requestAccounts" });
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const contract = new ethers.Contract(
          address,
          abiCode,
          provider.getSigner()
        );
        const sendData = {
          from: account,
          gasLimit: "0xf4240", // default 1000
          value: payableValue ? payableValue : null,
        };
        //reference: https://docs.ethers.org/v5/single-page/#/v5/migration/web3/-%23-migration-from-web3-js--contracts--overloaded-functions

        // START: handle ambiguous functions (two functions with the same name), the signature must also be specified
        const argTypes = Object.keys(value).map((e) => e.split("___")[0]);
        const sigFunc = `${name}(${argTypes.toString()})`;
        // END:

        const gasLimit = await contract.estimateGas[sigFunc](
          ...extendParams,
          sendData
        );
        sendData.gasLimit = gasLimit.mul(12).div(10).toHexString();

        const result = await contract[sigFunc](...extendParams, sendData);

        formatData.push(
          {
            name: "View your block",
            type: "blockNumber",
            value: result.blockNumber ? result.blockNumber : "",
          },
          {
            name: "View your transaction",
            type: "tx",
            value: result?.hash || "",
          }
        );
      }
    } catch (error: any) {
      formatData.push({
        name: "Error",
        type: "string",
        value: error.message,
      });
    }
    setDataResult(formatData);
    setIsLoading(false);
  };
  return (
    <Form onFinish={onWriteFinish}>
      {data.stateMutability === "payable" && (
        <FormGroup key={`wf-content-payable-value`}>
          <TextOverlay>{`payable value (alan)`}</TextOverlay>
          <FormItem name="payable-value">
            <InputStyled placeholder={`payableValue (uint256)`} />
          </FormItem>
        </FormGroup>
      )}
      {data.inputs.map((input: any, i: number) => {
        return (
          <FormGroup key={`wf-content-${i}`}>
            <TextOverlay>{`${input.name} (${input.type})`}</TextOverlay>
            <FormItem
              name={`${input.type}___${input.name ? input.name : name + i}`}
            >
              <InputStyled placeholder={`${input.name} (${input.type})`} />
            </FormItem>
          </FormGroup>
        );
      })}
      <ButtonStyled htmlType="submit" disabled={isLoading}>
        <TextMain>Write</TextMain>
      </ButtonStyled>{" "}
      &nbsp; {isLoading && <Spin size="small" />}
      <div style={{ marginTop: "20px" }}>
        {dataResult.length > 0 && (
          <div>
            <TextMain>
              [<b>{name}</b> method response]
            </TextMain>
          </div>
        )}
        {dataResult.map((d: any, k: number) => {
          return d.name === "Error" ? (
            <div key={`w-${k}`} className="error">
              <TextMain
                style={{
                  marginRight: "10px",
                  color: "#B32E33",
                  fontStyle: "italic",
                }}
              >
                {d.name}
              </TextMain>
              <TextOverlay style={{ fontStyle: "italic" }}>
                {d.type.toString()}:
              </TextOverlay>
              <TextMain style={{ fontStyle: "italic", color: "#B32E33" }}>
                {d.value.toString()}
              </TextMain>{" "}
              &nbsp;
            </div>
          ) : (
            <>
              {d.type === "blockNumber" && d.value && (
                <Link to={`/block/${d.value}`} style={{ display: "block" }}>
                  {d.name}
                </Link>
              )}
              {d.type === "tx" && d.value && (
                <Link to={`/tx/${d.value}`} style={{ display: "block" }}>
                  {d.name}
                </Link>
              )}
            </>
          );
        })}
      </div>
    </Form>
  );
};

function Write({ data, abi }: { data: any; abi: any }) {
  const [isConnected, setIsConnected] = useState(false);
  const [account, setAccount] = useState("");
  return (
    <>
      {data.length > 0 && (
        <>
          <Title>
            <FileTextOutlined style={{ color: "#836c87" }} /> Write Contract
            Information
          </Title>
          <ConnectWallet
            account={account}
            setAccount={setAccount}
            isConnected={isConnected}
            setIsConnected={setIsConnected}
          />
          {data.map((v: any, i: number) => {
            return (
              <Box key={`w-content-${i}`}>
                <SubTitle>
                  <TextMain>{`${i + 1}. ${v.name} ${
                    v.stateMutability === "payable" ? "(payable)" : ""
                  }`}</TextMain>
                </SubTitle>
                <BoxContent>
                  <WriteForm
                    data={v}
                    name={v.name}
                    abiCode={abi}
                    account={account}
                    isConnected={isConnected}
                  />
                </BoxContent>
              </Box>
            );
          })}
        </>
      )}
    </>
  );
}

const Box = styled.div`
  border: 1px solid ${({ theme }) => theme.borderOverlay};
  margin-bottom: 20px;
  border-radius: 8px;
  overflow: hidden;
`;

const SubTitle = styled.p`
  padding: 8px 15px;
  margin-bottom: 0;
  border-bottom: 1px solid ${({ theme }) => theme.borderOverlay};
`;

const BoxContent = styled.div`
  padding: 15px;
`;

export default Write;
