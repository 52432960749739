import React from "react";
import styled from "styled-components";
import { Table, Select, Space, Spin } from "antd";
import { WrapSpin } from "common/DataTable/dataTableStyles";
import DataTableLoading from "common/DataTable/DataTableLoading";

interface DataTableProps {
  title?: string;
  loading?: boolean;
  columns: any;
  data: any;
}
export default function DataTable({
  title,
  columns,
  data,
  loading,
}: DataTableProps) {
  return (
    <TableStyled>
      {loading && data.length === 0 && <DataTableLoading />}
      <div style={{ position: "relative" }}>
        {!loading && data.length === 0 && (
          <Table
            className="style-table-bordered"
            columns={columns}
            dataSource={[]}
            pagination={false}
            size="middle"
          />
        )}

        {data.length > 0 && (
          <Table
            className="style-table-bordered"
            columns={columns}
            dataSource={data}
            pagination={{ pageSize: 5 }}
            size="middle"
          />
        )}
      </div>
    </TableStyled>
  );
}

const TableStyled = styled.div`
  backdrop-filter: blur(20px);
  border-radius: 8px;
  box-shadow: 0px 4px 8px ${({ theme }) => theme.boxShadow1};
  height: 100%;
  .style-table-bordered {
    .ant-table {
      border: 1px solid ${({ theme }) => theme.borderOverlay1} !important;
      padding-top: 1px;
      background: transparent;
      border-radius: 8px;
      overflow: hidden;
      table {
        color: ${({ theme }) => theme.text};
        .ant-table-tbody > tr {
          &:last-child {
            td {
              border-bottom: 0px !important;
            }
          }
          > td {
            border-bottom: 1px solid ${({ theme }) => theme.borderOverlay1};
            background: ${({ theme }) => theme.bg5};
            padding-left: 15px;
            padding-right: 15px;
          }
        }
        .ant-table-thead > tr > th {
          color: ${({ theme }) => theme.text};
          background: ${({ theme }) => theme.bg7};
          border-bottom: 0px;
          padding-left: 15px;
          padding-right: 15px;
          font-size: 13px;
        }
        .ant-table-tbody > tr.ant-table-row:hover > td {
          background: ${({ theme }) => theme.bg6}!important;
        }
        .ant-table-tbody > tr.ant-table-placeholder:hover > td {
          background: ${({ theme }) => theme.bg6}!important;
        }
      }
    }
  }
  .ant-empty-description {
    color: ${({ theme }) => theme.textOverlay};
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background: transparent;
    color: ${({ theme }) => theme.text};
    border: none;
  }
  .ant-select-arrow {
    color: ${({ theme }) => theme.text};
  }
  .styled-spin {
    .ant-spin-dot-item {
      background-color: ${({ theme }) => theme.primary};
    }
  }
  .ant-pagination-prev button,
  .ant-pagination-next button {
    color: ${({ theme }) => theme.textLink};
  }
  .ant-pagination-item-active {
    background-color: ${({ theme }) => theme.primary};
    border-color: ${({ theme }) => theme.primary};
    a {
      color: #fff;
    }
  }
  .ant-pagination-jump-prev
    .ant-pagination-item-container
    .ant-pagination-item-ellipsis,
  .ant-pagination-jump-next
    .ant-pagination-item-container
    .ant-pagination-item-ellipsis {
    color: #fff;
  }
`;
