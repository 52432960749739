import React, { createContext, useContext, useEffect } from "react";
import { ThemeProvider } from "styled-components";
import { HelmetProvider } from "react-helmet-async";
import { useAppDispatch, useAppSelector, useQuery } from "app/hooks";
import { Colors } from "constants/themeConfig";
import { useNavigateSearch } from "app/hooks";
import { selectNetwork } from "app/services/general";

interface GeneralProviderProps {
  children: any;
}
const GeneralContext = createContext({
  network: "mainnet",
});

export function useGeneralContext() {
  const general = useContext(GeneralContext);

  return { ...general };
}

const GeneralProvider = ({ children }: GeneralProviderProps) => {
  const network = useAppSelector((state) => state.general.network);
  const dispatch = useAppDispatch();
  const navigateSearch = useNavigateSearch();
  const theme = Colors();

  // const params = useQuery();
  // let network = params.get("cluster") || "mainnet";

  // useEffect(() => {
  //   dispatch(selectNetwork(network));
  //   if (network === "mainnet") {
  //     navigateSearch(location.pathname);
  //   } else {
  //     navigateSearch(location.pathname, { cluster: network });
  //   }
  // }, [selectNetwork, network]);

  return (
    <GeneralContext.Provider value={{ network }}>
      <ThemeProvider theme={theme}>
        <HelmetProvider>{children}</HelmetProvider>
      </ThemeProvider>
    </GeneralContext.Provider>
  );
};

export default GeneralProvider;
