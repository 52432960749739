import React, { useState } from "react";
import { Table } from "antd";
import { ClockCircleOutlined } from "@ant-design/icons";
import { TextLink } from "common/DataTable/dataTableStyles";
import { formatUtc, timeFormatDistance } from "utils";

interface DataTableProps {
  columns: any;
  data: any;
  loading?: boolean;
  onChange?: any;
}

export const DataTableNew = ({
  data,
  columns,
  loading,
  onChange,
}: DataTableProps) => {
  const [utc, setUtc] = useState(false);
  const parsedColumns =
    columns &&
    Array.isArray(columns) &&
    columns.map((col) => {
      if (col.dataIndex === "timestamp" || col.dataIndex === "verifyTime") {
        col.title = (
          <>
            {utc ? (
              <TextLink>TIME (UTC)</TextLink>
            ) : (
              <TextLink>
                TIME <ClockCircleOutlined />
              </TextLink>
            )}
          </>
        );
        col.render = (time: any) =>
          utc
            ? formatUtc(time, "MM-dd-yyyy HH:mm:ss")
            : timeFormatDistance(time);
        col.onHeaderCell = () => {
          return {
            onClick: () => {
              setUtc(!utc);
            },
          };
        };
      }
      return col;
    });
  return (
    <Table
      className="style-table-bordered"
      columns={parsedColumns}
      dataSource={data}
      pagination={false}
      loading={loading}
      onChange={onChange}
      size="middle"
      scroll={{ x: columns.length > 5 ? 900 : 450 }}
    />
  );
};
