import React from "react";
import { Space, Typography } from "antd";

import { formatNumber } from "utils";
import { MAX_RECORD } from "constants/api";
import { TextOverlay } from "common/styledGeneral";

type TotalProps = {
  total: null | number;
  type?: string;
};

export default function ShowItemOfNumber({ total, type }: TotalProps) {
  if (!total) return null;
  switch (true) {
    case type === "tx":
      return (
        <Space>
          {total <= 20 && (
            <TextOverlay>
              Total {total && formatNumber(total)}&nbsp;
              {total && total > 1 ? "transactions" : "transaction"}
            </TextOverlay>
          )}
          {total > 20 && (
            <TextOverlay>
              Showing latest 20 of {formatNumber(total)} records
            </TextOverlay>
          )}
        </Space>
      );
    case type === "token":
      return (
        <Space>
          {total < MAX_RECORD && (
            <TextOverlay>
              Total {total && formatNumber(total)}&nbsp;
              {total && total > 1 ? "tokens" : "token"}
            </TextOverlay>
          )}
          {total >= MAX_RECORD && (
            <TextOverlay>
              Showing latest {formatNumber(MAX_RECORD)} of{" "}
              {formatNumber(MAX_RECORD)}+ records
            </TextOverlay>
          )}
        </Space>
      );
    case type === "holder":
      return (
        <Space>
          {total < MAX_RECORD && (
            <TextOverlay>
              Total {total && formatNumber(total)}&nbsp;
              {total && total > 1 ? "holders" : "holder"}
            </TextOverlay>
          )}
          {total >= MAX_RECORD && (
            <TextOverlay>
              Total 1,000+ holders (only top 1,000 holders are displayed)
            </TextOverlay>
          )}
        </Space>
      );
    default:
      return (
        <Space>
          {total <= 20 && (
            <TextOverlay>
              Total {total && formatNumber(total)}&nbsp;
              {total && total > 1 ? "records" : "record"}
            </TextOverlay>
          )}
          {total > 20 && (
            <TextOverlay>
              Showing latest 20 of {formatNumber(total)} records
            </TextOverlay>
          )}
        </Space>
      );
  }
}
