import React, { useState, useMemo, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useNavigate } from "react-router-dom";

import {
  useGetTxsNumberQuery,
  useGetVerifiedContractsQuery,
} from "app/services/account";
import { verifiedContractColumn } from "features/account/columnsConfig";
import { Wrapper, Container, TitleStyle } from "common/styledGeneral";
import DataTableTotal from "common/DataTable/DataTableTotal";
import { VerifyButton } from "common/styled";
import IconSuccess from "assets/images/icon-success.svg";
import { WrapTable } from "common/TableAccountTxs/styled";
import SkeletonTable from "common/SkeletonTable";
import { DataTableNew } from "common/TableAccountTxs/Table";
import Paging from "common/TableAccountTxs/Pagination";
import { useQuery } from "app/hooks";
import { notification } from "antd";

export function VerifyContractList() {
  const query = useQuery();
  const currentPage = parseInt(query.get("page") || "1");
  const pageSize =
    parseInt(query.get("number") || "20") > 100
      ? 100
      : parseInt(query.get("number") || "20");
  const [limit, setLimit] = useState(pageSize);

  const offset = useMemo(() => {
    return (currentPage - 1) * pageSize;
  }, [currentPage, pageSize]);

  const navigate = useNavigate();
  const { data, isFetching } = useGetVerifiedContractsQuery({
    offset,
    limit: pageSize,
  });

  const listAddress = useMemo(() => {
    let params: string[] = [];
    if (data?.data?.items) {
      data.data.items.map((item: any) => {
        return params.push(item.address);
      });
      return params;
    }
  }, [data]);
  const {
    data: txData,
    error: txError,
    isFetching: txFetching,
  } = useGetTxsNumberQuery({ list_address: listAddress } ?? skipToken);

  // use update limit when direct /verified-contracts?number -> verified-contracts
  useEffect(() => {
    if (pageSize && pageSize !== limit) setLimit(pageSize);
    if (parseInt(query.get("number") || "10") > 100) {
      notification["warning"]({
        message: "Warning",
        description: "Each page will only load a maximum of 100 records",
        placement: "bottomRight",
      });
    }
  }, [pageSize]);

  const newData = useMemo(() => {
    if (data?.data?.items) {
      const result = data.data.items.map((item: any) => {
        if (txData?.data && txData.data[item.address]) {
          return { ...item, txCount: txData.data[item.address] };
        } else {
          return item;
        }
      });
      return result;
    } else {
      return [];
    }
  }, [data, txData]);

  const pageTotal = useMemo(() => {
    return data?.data?.total && pageSize
      ? Math.ceil(data?.data?.total / pageSize)
      : 0;
  }, [data?.data?.total, pageSize]);

  function changeNumPerPage(value: string) {
    if (value) {
      setLimit(Number(value));
      query.set("number", value);
      navigate({ search: query.toString() });
    }
  }

  return (
    <Wrapper>
      <Helmet>
        <title>{`Verified Contracts`}</title>
      </Helmet>
      <Container className="mx-md-down-0">
        <div style={{ position: "relative" }}>
          <VerifyButton to={"/contracts/verify"}>
            <img src={IconSuccess} width={18} /> Verify New Contract
          </VerifyButton>
        </div>
        <WrapTable>
          <TitleStyle level={2}>VERIFIED CONTRACTS</TitleStyle>
          <div
            style={{
              display: "flex",
              alignContent: "center",
              justifyContent: "space-between",
              flexWrap: "wrap",
              marginBottom: 20,
            }}
          >
            <div>
              <DataTableTotal
                total={data?.data?.total ? data.data.total : ""}
                type="tx"
              />
            </div>
            <Paging
              currPage={currentPage}
              pageTotal={pageTotal}
              pageSize={limit}
              changeNumPerPage={changeNumPerPage}
            />
          </div>
          {isFetching ? (
            <SkeletonTable
              type="verifyContract"
              record={20}
              loading={isFetching}
            />
          ) : (
            <DataTableNew
              data={newData || []}
              columns={verifiedContractColumn}
              loading={isFetching}
            />
          )}
          <div style={{ marginTop: 20 }}>
            <Paging
              currPage={currentPage}
              pageTotal={pageTotal}
              pageSize={limit}
              changeNumPerPage={changeNumPerPage}
            />
          </div>
        </WrapTable>
      </Container>
    </Wrapper>
  );
}
