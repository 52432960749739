import React from "react";
import styled from "styled-components";
import { formatDistanceStrict } from "date-fns";

import { formatNumber, formatUtc } from "utils";
import { TextMain, TextOverlay } from "common/styledGeneral";

export function TooltipTxChart({ active, payload, timeTxChart }: any) {
  if (active && payload && payload.length) {
    return (
      <TooltipWrap>
        <TextOverlay style={{ fontSize: "12px" }}>
          {formatUtc(payload[0].payload.unixTime)}
        </TextOverlay>
        <TextOverlay className="block" style={{ fontSize: "12px" }}>
          {formatDistanceStrict(
            payload[0].payload.unixTime * 1000,
            new Date(),
            {
              unit: timeTxChart === "1D" ? "hour" : "minute",
            }
          )}{" "}
          ago
        </TextOverlay>
        <TextMain style={{ fontSize: "13px" }}>
          Transactions: {formatNumber(payload[0].payload.value)}
        </TextMain>
        <TextMain style={{ fontSize: "13px" }}>
          TPS: {formatNumber(payload[0].payload.tps, 4)}
        </TextMain>
      </TooltipWrap>
    );
  }
  return null;
}

const TooltipWrap = styled.div`
  background-color: ${({ theme }) => theme.bg1};
  padding: 10px 15px;
  border-radius: 8px;
`;
