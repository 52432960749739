import React from "react";
import BigNumber from "bignumber.js";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Col, Row, Space } from "antd";
import { CopyrightOutlined } from "@ant-design/icons";
import { formatNumber } from "utils";
import { DropdownToken, TextMain, TextOverlay } from "common/styledGeneral";

const ListNft = ({ data }: any) => {
  return (
    <DropdownToken>
      <Row gutter={24} className="title-token">
        <Col span={8}>
          <TextMain>
            {data?.data?.total > 1 ? `NFTs(${data?.data?.total})` : `NFT`}
          </TextMain>
        </Col>
        <Col span={8}>
          <TextMain>Amount</TextMain>
        </Col>
        <Col span={8}>
          <TextMain>Value</TextMain>
        </Col>
      </Row>
      <div>
        {data?.data?.items?.length > 0 &&
          data?.data?.items.map((token: any, key: number) => {
            return (
              <Row gutter={24} className="item-token" key={key}>
                <Col span={8}>
                  <Link to={`/token/${token.tokenAddress}`}>
                    <Space>
                      {token.logoURI ? (
                        <img
                          width={24}
                          src={token.logoURI}
                          alt={token.tokenName}
                        />
                      ) : (
                        <CopyrightOutlined style={{ fontSize: "24px" }} />
                      )}
                      <div>
                        <TextMain className="inner-image">
                          {token.tokenName}
                        </TextMain>
                        {token.price && (
                          <TextOverlay className="font-12 block">
                            ${token.price}
                          </TextOverlay>
                        )}
                      </div>
                    </Space>
                  </Link>
                </Col>
                <Col span={8}>
                  {token.amount && (
                    <TextOverlay>{formatNumber(token.amount)}</TextOverlay>
                  )}
                </Col>
                <Col span={8}>
                  {token.price && token.amount && (
                    <TextOverlay>
                      $
                      {formatNumber(
                        new BigNumber(token.amount)
                          .multipliedBy(token.price)
                          .toString()
                      )}
                    </TextOverlay>
                  )}
                </Col>
              </Row>
            );
          })}
      </div>
    </DropdownToken>
  );
};

export default ListNft;

const ViewButton = styled.div`
  text-align: center;
`;

const LinkStyled = styled.a`
  padding: 10px 0;
  display: block;
  background-color: ${({ theme }) => theme.bg10};
  font-weight: 600;
`;
