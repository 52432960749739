import { useEffect, useState } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { useNavigate, createSearchParams, useLocation } from "react-router-dom";
import type { RootState, AppDispatch } from "./store";
import { getSize } from "utils";

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useNavigateSearch = () => {
  const navigate = useNavigate();
  return (pathname: string, params?: {}) => {
    if (params) {
      navigate({ pathname, search: `?${createSearchParams(params)}` });
    } else {
      navigate(pathname);
    }
  };
};

export const useWindowSize = () => {
  const isClient = typeof window === "object";
  const [windowSize, setWindowSize] = useState(getSize(isClient));

  useEffect(() => {
    function handleResize() {
      setWindowSize(getSize(isClient));
    }

    if (isClient) {
      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
    return undefined;
  }, []);

  return windowSize;
};

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}
