import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import styled from "styled-components";
import { Layout, Row, Col } from "antd";
const { Content } = Layout;

import "App.less";

import { useQuery } from "app/hooks";
import { TransactionList } from "features/transaction/TransactionList";
import { BlockList } from "features/blocks/BlockList";
import { BlockDetail } from "features/blocks/BlockDetail";
import { TokenList } from "features/tokens/TokenList";
import { TransactionDetail } from "features/transaction/TransactionDetail";
import { AccountDetail } from "features/account/AccountDetail";
import { TokenDetail } from "features/tokens/TokenDetail";
import { VerifyContractList } from "features/account/VerifyContractList";
import { Container } from "common/styledGeneral";
import { TokenTxs } from "features/extendPages/TokenTxs";
import { NeonTxs } from "features/extendPages/NeonTxs";
import Verify from "features/account/contract/Verify";
import Stats from "features/stats";
import Error404 from "features/error404";
import Homepage from "features/homepage";
import Header from "common/Header";
import SearchContainer from "common/SearchBox";
import Footer from "common/Footer";

function App() {
  const query = useQuery();
  const searchString = query.get("q")?.trim() || "";
  return (
    <LayoutStyle>
      <Header />
      {!searchString && (
        <Container>
          <Row>
            <Col xs={24} md={{ span: 16, offset: 4 }}>
              <SearchContainer />
            </Col>
          </Row>
        </Container>
      )}
      <Content style={{ display: "flex", alignItems: "center" }}>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/txs" element={<TransactionList />} />
          <Route path="/token-txs" element={<TokenTxs />} />
          <Route path="/internal-txs" element={<NeonTxs />} />
          <Route path="/blocks" element={<BlockList />} />
          <Route path="/block/:blockNumber" element={<BlockDetail />} />
          <Route path="/tokens" element={<TokenList />} />
          <Route path="/tx/:hash" element={<TransactionDetail />} />
          <Route path="/address/:address" element={<AccountDetail />} />
          <Route path="/token/:address" element={<TokenDetail />} />
          <Route path="/search" element={<Error404 />} />
          <Route path="/contracts/verify/" element={<Verify />} />
          <Route path="/verified-contracts" element={<VerifyContractList />} />
          <Route path="/stats" element={<Stats />} />
        </Routes>
      </Content>
      <Footer />
    </LayoutStyle>
  );
}

const LayoutStyle = styled(Layout)`
  background: ${({ theme }) => theme.bg3};
  min-height: 100vh;
`;

export default App;
