import React from "react";
import { ArrowRightOutlined } from "@ant-design/icons";

import { useGetAccountTokenTransferQuery } from "app/services/account";
import { tfColumnsToken } from "./columnsConfig";
// import DataTable from "common/DataTable";
// import DataTableTotal from "common/DataTable/DataTableTotal";
import { WrapTable } from "common/TableAccountTxs/styled";
import ShowItemOfNumber from "common/TableAccountTxs/ShowItemOfTotal";
import SkeletonTable from "common/SkeletonTable";
import { DataTableNew } from "common/TableAccountTxs/Table";
import { LinkButton } from "features/extendPages/styled";

export function TokenTransfers({ address }: { address: string }) {
  // const [args, setArgs] = useState({ address: "", offset: 0, limit: 20 });
  const { data, isFetching } = useGetAccountTokenTransferQuery({
    address,
    offset: 0,
    limit: 20,
  });
  // check Current Address
  const newData =
    data?.data?.items &&
    data.data.items.length > 0 &&
    data.data.items.map((item: any) => {
      return { ...item, currentAddress: address };
    });
  return (
    <WrapTable>
      <div style={{ marginBottom: 20 }}>
        <ShowItemOfNumber total={data?.data?.total ? data.data.total : 0} />
      </div>
      {isFetching ? (
        <SkeletonTable type="tokenTxs" record={20} loading={isFetching} />
      ) : (
        <DataTableNew
          columns={tfColumnsToken}
          data={newData || []}
          loading={isFetching}
        />
      )}
      {data?.data?.total > 20 && (
        <div style={{ textAlign: "center" }}>
          <LinkButton to={`/token-txs?acc=${address.toLowerCase()}`}>
            View more transaction <ArrowRightOutlined />
          </LinkButton>
        </div>
      )}
    </WrapTable>
    // <DataTable
    //   fontSize="16"
    //   notBgContentTable="not-bg-content-table"
    //   data={newData ? newData : []}
    //   headerLeft={
    //     <DataTableTotal
    //       total={data?.data?.total ? data.data.total : 0}
    //       type="tx"
    //     />
    //   }
    //   total={data?.data?.total ? data.data.total : 0}
    //   columns={tfColumnsToken}
    //   setParams={setArgs}
    //   loading={isLoading}
    // />
  );
}
