import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Col, Row, Space, Button } from "antd";

import { TxDetailLogType } from "constants/types";
import {
  BorderStyle,
  TextMain,
  TextOverlay,
  ValueRow,
} from "common/styledGeneral";
import {
  ButtonType,
  DataType,
  TransactionLog,
  TxLogLoadMore,
} from "features/transaction/transactionStyled";

import CopyIcon from "common/CopyIcon";
import { addressCheckSum } from "utils";

const LIMIT = 5;

interface TransactionDetailLogsProps {
  data: TxDetailLogType[];
}
export function TransactionDetailLog({ data }: TransactionDetailLogsProps) {
  const [offset, setOffset] = useState(0);
  const [logs, setLogs] = useState<Array<any>>([]);
  function onLoadMore() {
    setOffset(offset + LIMIT);
  }

  useEffect(() => {
    setLogs([...logs, ...data.slice(offset, offset + LIMIT)]);
  }, [offset]);
  return (
    <>
      {logs.map((value: any, key: number) => {
        return (
          <TransactionLog key={key}>
            <Row gutter={24}>
              <Col span={6} md={5} lg={4}>
                <TextOverlay className="mv-2">Address &nbsp;</TextOverlay>
              </Col>
              <Col span={18} md={19} lg={20}>
                <Space className="mv-2" align="start">
                  <Link to={`/address/${value.address.toLowerCase()}`}>
                    <TextMain className="word-break link-color">
                      {addressCheckSum(value.address)}
                    </TextMain>
                  </Link>
                  <CopyIcon
                    value={addressCheckSum(value.address)}
                    after={true}
                  />
                </Space>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24} md={5} lg={4}>
                <TextOverlay className="mv-2">Topics &nbsp;</TextOverlay>
              </Col>
              <Col span={24} md={19} lg={20}>
                <TopicRender topic={value} />
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24} md={5} lg={4}>
                <TextOverlay className="mv-2">Data &nbsp;</TextOverlay>
              </Col>
              <Col span={24} md={19} lg={20}>
                <DataRender log={value} />
              </Col>
            </Row>
          </TransactionLog>
        );
      })}
      <div style={{ textAlign: "center" }}>
        {data.length > LIMIT && logs.length < data.length && (
          <TxLogLoadMore onClick={onLoadMore}>Load More</TxLogLoadMore>
        )}
      </div>
    </>
  );
}

const TopicRender = ({ topic }: any) => {
  const [topicType, setTopicType] = useState("hex");
  const onClickType = (e: any) => {
    // const value = e.target.value;
    setTopicType(e);
  };
  return (
    <>
      <BorderStyle>
        <div>
          {topic.decodedTopics && topicType === "dec"
            ? topic.decodedTopics.map((t: any, i: any) => (
                <TextMain className="block word-break" key={i}>
                  {i + 1}. {t}
                </TextMain>
              ))
            : topic.topics.map((topic: any, topicIdx: number) => (
                <TextMain className="block word-break" key={topicIdx}>
                  {topicIdx + 1}. {topic}
                </TextMain>
              ))}
        </div>
      </BorderStyle>
      {topic.decodedTopics && (
        <ValueRow>
          <Button
            className={`mt-0 mb-1 btn-switch${
              topicType === "hex" ? " active" : ""
            }`}
            onClick={() => onClickType("hex")}
          >
            <TextMain>Hex</TextMain>
          </Button>
          <Button
            className={`mt-0 mb-1 btn-switch${
              topicType === "dec" ? " active" : ""
            }`}
            onClick={() => onClickType("dec")}
          >
            <TextMain>Dec</TextMain>
          </Button>
        </ValueRow>
      )}
    </>
  );
};

const DataRender = ({ log }: any) => {
  const [topicType, setTopicType] = useState("hex");
  const onClickType = (e: any) => {
    // const value = e.target.value;
    setTopicType(e);
  };
  let newParam: Array<any> = [];
  if (log.params && log.params.length > 0) {
    log.params.forEach((p: any) => {
      if (!p.indexed) {
        newParam.push(p.name);
      }
    });
  }
  return (
    <>
      <BorderStyle>
        {log.decodedData && topicType === "dec" ? (
          newParam.map((p: any, i: any) => (
            <TextMain className="block word-break" key={i}>
              {p}: {log.decodedData[p]}
            </TextMain>
          ))
        ) : (
          <TextMain className="block word-break">{log.data}</TextMain>
        )}
      </BorderStyle>
      {log.decodedData && (
        <ValueRow>
          <Button
            className={`mt-0 mb-1 btn-switch${
              topicType === "hex" ? " active" : ""
            }`}
            onClick={() => onClickType("hex")}
          >
            <TextMain>Hex</TextMain>
          </Button>
          <Button
            className={`mt-0 mb-1 btn-switch${
              topicType === "dec" ? " active" : ""
            }`}
            onClick={() => onClickType("dec")}
          >
            <TextMain>Dec</TextMain>
          </Button>
        </ValueRow>
      )}
    </>
  );
};
