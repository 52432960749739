import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import styled from "styled-components";
import BigNumber from "bignumber.js";
import { Helmet } from "react-helmet-async";
import { Divider, Space, Row, Col, Tabs, Tooltip, Button } from "antd";
import { DoubleRightOutlined, CheckCircleFilled } from "@ant-design/icons";
import { Link as LinkRouter, Navigate } from "react-router-dom";

import { AddressType } from "constants/types";
import { ADDRESS_MAPPER } from "constants/address_mapper";
import { useGetAccountInfoQuery } from "app/services/account";
import {
  Wrapper,
  Container,
  BoxStyleCommon,
  TextMain,
  TitleStyle,
  BoxStyleInner,
  TextOverlay,
  TabStyle,
  TabBoxDetail,
  BoxLogoImage,
  ButtonStyled,
  StyledButton,
} from "common/styledGeneral";
import { PageTitle } from "common/PageTitle";
import CopyIcon from "common/CopyIcon";

import {
  addressCheckSum,
  formatNumber,
  formatUtc,
  timeFormatDistance,
} from "utils";

import TabsWithHash from "common/TabWithHash";
import RowLoading from "common/RowLoading";
import { TokensInAccount } from "./TokensInAccount";
import { TokenTransfers } from "./TokenTransfers";
import { NeonTransfers } from "./NeonTransfers";
import { AccountTxs } from "./AccountTxs";
import { AccountTokens } from "./AccountTokens";
import { ContractData } from "./contract/ContractData";
import iconNeon24 from "assets/images/neonlabs.png";
import { TxsNumber } from "./contract/TxsNumber";
import { NftsInAccount } from "./NftInAccount";

const { TabPane } = Tabs;

export function AccountDetail() {
  const { address } = useParams<AddressType>();
  const [neonPrice, setNeonPrice] = useState(0);
  const [neonValue, setNeonValue] = useState<any>();
  const [tokenType, setTokenType] = useState("token");
  const balanceRef = useRef(null);
  const { data, error, isLoading } = useGetAccountInfoQuery(address || "");

  async function neonMarketInfo() {
    try {
      const response = await fetch(
        "https://api.coingecko.com/api/v3/simple/price?ids=neon&vs_currencies=USD"
      );
      const result = await response.json();
      if (result?.neon?.usd) {
        setNeonPrice(result.neon.usd);
        const value = data.data.balance * result.neon.usd;
        setNeonValue(formatNumber(value, 6));
      }
    } catch (e) {
      console.log("Error:", error);
    }
  }
  useEffect(() => {
    if (data?.data?.balance) {
      neonMarketInfo();
    }
  }, [data?.data?.balance]);

  if (
    (data?.success && !data?.data) ||
    (!data?.success && data?.data) ||
    error
  ) {
    return <Navigate to={`/search?q=${address}`} />;
  }

  const scrollToRef = (ref: any) => window.scrollTo(0, ref.current.offsetTop);
  const executeScroll = () => scrollToRef(balanceRef);
  return (
    <Wrapper>
      {address && (
        <Helmet>
          <title>{`Address ${address}`}</title>
        </Helmet>
      )}
      <Container className="mx-md-down-0">
        {isLoading ? (
          <RowLoading />
        ) : (
          <BoxStyleCommon>
            <PageTitle
              title={
                data?.data?.isContract ? "CONTRACT DETAILS" : "ADDRESS DETAILS"
              }
            />
            <TextMain className="flex word-break">
              <CopyIcon value={addressCheckSum(data.data.address)} />
              <TextMain className="link-color">
                {addressCheckSum(data.data.address)}
              </TextMain>
            </TextMain>
            <Divider className="my-2" />

            <Row gutter={[24, 24]}>
              <Col className="pt-5" xs={{ span: 24 }} md={{ span: 12 }}>
                <TitleStyle className="font-16 absolute" level={2}>
                  Balance
                </TitleStyle>
                <BoxStyleInner>
                  <Row gutter={24}>
                    <Col span={6} md={6} lg={4}>
                      <TextOverlay className="mv-2">NEON</TextOverlay>
                    </Col>
                    <Col span={18} md={18} lg={20}>
                      <TextMain className="mv-2">
                        <Space align="start">
                          <TextMain>
                            <Space>
                              {formatNumber(data?.data?.balanceString || 0)}
                              <BoxLogoImage className="w-20">
                                <img className="neon-logo" src={iconNeon24} />
                              </BoxLogoImage>
                            </Space>
                            <br />
                            <TextOverlay className="font-12">
                              {`($${neonPrice || 0}/NEON)`}
                            </TextOverlay>
                          </TextMain>
                          <Divider type="vertical" />
                          <TextOverlay>${neonValue || 0}</TextOverlay>
                        </Space>
                      </TextMain>
                    </Col>
                  </Row>
                  <Divider className="my-1" />
                  <Row gutter={24}>
                    <Col span={6} md={6} lg={4}>
                      <TextOverlay className="mv-2">Token</TextOverlay>
                    </Col>
                  </Row>
                  <Row gutter={24} style={{ alignItems: "center" }}>
                    <Col span={20} md={21} lg={22}>
                      <AccountTokens address={address ? address : ""} />
                    </Col>
                    <Col span={4} md={3} lg={2}>
                      <Tooltip
                        title="Click to view all tokens balance"
                        color="#4A2F4A"
                      >
                        <a href="#tokensBalance">
                          <ViewStyle onClick={executeScroll}>
                            <DoubleRightOutlined />
                          </ViewStyle>
                        </a>
                      </Tooltip>
                    </Col>
                  </Row>
                </BoxStyleInner>
              </Col>
              <Col className="pt-5" xs={{ span: 24 }} md={{ span: 12 }}>
                <TitleStyle className="font-16 absolute" level={2}>
                  More info
                </TitleStyle>
                {data?.data?.isContract ? (
                  <BoxStyleInner>
                    {ADDRESS_MAPPER[data.data.address] && (
                      <Row>
                        <Col span={7} md={6} lg={5}>
                          <TextOverlay className="mv-2">
                            Public Name
                          </TextOverlay>
                        </Col>
                        <Col span={17} md={18} lg={19}>
                          <TextMain className="mv-2">
                            {ADDRESS_MAPPER[data.data.address]}
                          </TextMain>
                        </Col>
                      </Row>
                    )}
                    <Row gutter={24}>
                      <Col span={7} md={6} lg={5}>
                        <TextOverlay className="mv-2">Creator</TextOverlay>
                      </Col>

                      <Col span={17} md={18} lg={19}>
                        {data?.data?.contractCreator ? (
                          <div className="mv-2" style={{ display: "flex" }}>
                            <LinkRouter
                              className="text-ellipsis"
                              to={`/address/${data.data.contractCreator}`}
                            >
                              {data.data.contractCreator}
                            </LinkRouter>
                            <TextMain
                              style={{
                                whiteSpace: "nowrap",
                                paddingLeft: "5px",
                                paddingRight: "5px",
                              }}
                            >
                              at tx
                            </TextMain>
                            <LinkRouter
                              className="text-ellipsis"
                              to={`/tx/${data.data.contractCreatedAtTransactionHash}`}
                            >
                              {data.data.contractCreatedAtTransactionHash}
                            </LinkRouter>
                          </div>
                        ) : (
                          <TextOverlay className="mv-2">__</TextOverlay>
                        )}
                      </Col>
                    </Row>
                    <Divider className="my-1" />
                    {data?.data?.isToken && data?.data?.tokenInfo && (
                      <Row gutter={24}>
                        <Col span={7} md={6} lg={5}>
                          <TextOverlay className="mv-2">
                            Token Tracker
                          </TextOverlay>
                        </Col>
                        <Col span={17} md={18} lg={19}>
                          <TextMain className="mv-2">
                            <LinkRouter
                              to={`/token/${data.data.tokenInfo.address}`}
                            >
                              <Space>
                                {data.data.tokenInfo?.logoURI && (
                                  <img
                                    width="24"
                                    src={data.data.tokenInfo?.logoURI}
                                  />
                                )}
                                {`${data.data.tokenInfo.name} (${data.data.tokenInfo.symbol})`}
                              </Space>
                            </LinkRouter>
                            {data.data.tokenInfo?.price && (
                              <TextOverlay>
                                &nbsp;(@$
                                {formatNumber(data.data.tokenInfo.price)})
                              </TextOverlay>
                            )}
                          </TextMain>
                        </Col>
                      </Row>
                    )}
                    <TxsNumber address={data.data.address} />
                  </BoxStyleInner>
                ) : (
                  <BoxStyleInner>
                    {ADDRESS_MAPPER[data.data.address] && (
                      <Row>
                        <Col span={7} md={6} lg={5}>
                          <TextOverlay className="mv-2">
                            Public Name
                          </TextOverlay>
                        </Col>
                        <Col span={17} md={18} lg={19}>
                          <TextMain className="mv-2">
                            {ADDRESS_MAPPER[data.data.address]}
                          </TextMain>
                        </Col>
                      </Row>
                    )}
                    <Row gutter={24}>
                      <Col span={7} md={6} lg={5}>
                        <TextOverlay className="mv-2">
                          Last Transaction
                        </TextOverlay>
                      </Col>
                      <Col span={17} md={18} lg={19}>
                        <TextMain className="mv-2">
                          <Space direction="vertical">
                            {timeFormatDistance(data.data.lastTransactionTime)}
                            <TextOverlay>
                              {formatUtc(data.data.lastTransactionTime)}
                            </TextOverlay>
                          </Space>
                        </TextMain>
                      </Col>
                    </Row>
                    <Divider className="my-1" />
                    <Row gutter={24}>
                      <Col span={7} md={6} lg={5}>
                        <TextOverlay className="mv-2">
                          Last Transfer
                        </TextOverlay>
                      </Col>
                      <Col span={17} md={18} lg={19}>
                        <TextMain className="mv-2">
                          <Space direction="vertical">
                            {timeFormatDistance(data.data.lastTransferTime)}
                            <TextOverlay>
                              {formatUtc(data.data.lastTransferTime)}
                            </TextOverlay>
                          </Space>
                        </TextMain>
                      </Col>
                    </Row>
                  </BoxStyleInner>
                )}
              </Col>
            </Row>

            <TabStyle className="not-bg-tab mt-3" ref={balanceRef}>
              <TabBoxDetail className="tmp-tab-box2">
                <TabsWithHash
                  type="card"
                  tabKeys={[
                    "neonTransfer",
                    "tokenTransfers",
                    "contract",
                    "tokensBalance",
                  ]}
                >
                  <TabPane tab="Transactions" key="default">
                    <AccountTxs address={address ? address : ""} />
                  </TabPane>
                  <TabPane tab="NEON Transfers" key="neonTransfer">
                    <NeonTransfers address={address ? address : ""} />
                  </TabPane>
                  <TabPane tab="Token Transfers" key="tokenTransfers">
                    <TokenTransfers address={address ? address : ""} />
                  </TabPane>
                  {data?.data?.isContract && (
                    <TabPane
                      tab={
                        <VerifyIcon data={data?.data?.contractData || null} />
                      }
                      key="contract"
                    >
                      <ContractData data={data.data} />
                    </TabPane>
                  )}
                  <TabPane tab="Tokens Balance" key="tokensBalance">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "left",
                        gap: "16px",
                      }}
                    >
                      <StyledButton
                        className={tokenType === "token" ? "active" : ""}
                        onClick={() => setTokenType("token")}
                      >
                        Tokens
                      </StyledButton>
                      <StyledButton
                        className={tokenType === "nft" ? "active" : ""}
                        onClick={() => setTokenType("nft")}
                      >
                        NFTs
                      </StyledButton>
                    </div>
                    {tokenType === "token" && (
                      <TokensInAccount address={address ? address : ""} />
                    )}
                    {tokenType === "nft" && (
                      <NftsInAccount address={address ? address : ""} />
                    )}
                  </TabPane>
                </TabsWithHash>
              </TabBoxDetail>
            </TabStyle>
          </BoxStyleCommon>
        )}
      </Container>
    </Wrapper>
  );
}

const VerifyIcon = ({ data }: { data: any }) => {
  return (
    <>
      Contract
      {data && (
        <IconStyled>
          <CheckCircleFilled />
        </IconStyled>
      )}
    </>
  );
};

const IconStyled = styled.span`
  position: absolute;
  top: 0;
  right: 3px;
  * {
    color: #1d8c46 !important;
  }
  .anticon {
    margin-right: 0;
    font-size: 12px;
  }
`;

const ViewStyle = styled.span`
  width: 40px;
  height: 40px;
  line-height: 40px;
  display: block;
  margin-top: 10px;
  text-align: center;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.border2};
`;
