import React, { useState } from "react";
import styled from "styled-components";
import { Space, Button, Skeleton } from "antd";
import { Bar, BarChart, ResponsiveContainer, YAxis, Tooltip } from "recharts";
import { dataFormater, formatUtc } from "utils";
import { BoxChart, TextStyle1 } from "common/styledGeneral";
import { useGetTxsChartQuery } from "app/services/home";
import { TooltipTxChart } from "./TooltipTxChart";

export function TxChart() {
  const [timeTxChart, setTimeTxChart] = useState("2m");
  const {
    data: txChart,
    error: txChartError,
    isLoading: txChartLoading,
  } = useGetTxsChartQuery(timeTxChart, { pollingInterval: 15000 });

  const newTxChart =
    txChart?.data &&
    txChart.data.length > 0 &&
    txChart.data.map((item: any, key: number) => {
      const dateType = formatUtc(item.unixTime, "dd/MM");
      return { ...item, dateType };
    });
  return (
    <>
      <SpaceStyled>
        <TextStyle1 className="mt-2 mt-md-0 mb-1">
          Transactions over time
        </TextStyle1>
        <Space
          style={{ justifyContent: "end", alignItems: "start", width: "100%" }}
        >
          <ButtonStyle
            onClick={() => setTimeTxChart("2m")}
            className={timeTxChart === "2m" ? "active" : ""}
          >
            1H
          </ButtonStyle>
          <ButtonStyle
            onClick={() => setTimeTxChart("48m")}
            className={timeTxChart === "48m" ? "active" : ""}
          >
            1D
          </ButtonStyle>
          <ButtonStyle
            onClick={() => setTimeTxChart("1D")}
            className={timeTxChart === "1D" ? "active" : ""}
          >
            1M
          </ButtonStyle>
        </Space>
      </SpaceStyled>
      {txChartLoading ? (
        <Skeleton paragraph={{ rows: 1 }} />
      ) : (
        <BoxChart>
          <ResponsiveContainer width="100%" height="100%" aspect={4}>
            <BarChart
              data={newTxChart ? newTxChart : []}
              margin={{
                top: 5,
                left: -10,
              }}
            >
              {/* <XAxis
              dataKey="dateType"
              axisLine={false}
              tickLine={false}
              stroke="rgba(238, 200, 247, 0.6)"
            /> */}
              <YAxis
                tickFormatter={dataFormater}
                axisLine={false}
                tickLine={false}
                tickSize={10}
                stroke="rgba(238, 200, 247, 0.6)"
              />
              <Tooltip
                content={<TooltipTxChart timeTxChart={timeTxChart} />}
                cursor={{
                  stroke: "#A93EC1",
                  fill: "#A93EC1",
                }}
                // allowEscapeViewBox={{ y: true }}
                position={{ y: -120 }}
              />
              <Bar dataKey="value" fill="#99DCF7" />
            </BarChart>
          </ResponsiveContainer>
        </BoxChart>
      )}
    </>
  );
}

const ButtonStyle = styled(Button)`
  border: none;
  color: ${({ theme }) => theme.textOverlay};
  background-color: ${({ theme }) => theme.bg2};
  border-radius: 6px;
  font-size: 12px;
  padding: 2px 10px;
  height: auto;
  &.active,
  &:hover,
  &:focus {
    color: #ffffff;
    background-color: rgba(169, 62, 193, 0.22);
  }
`;

const SpaceStyled = styled(Space)`
  justify-content: space-between;
  align-items: start;
  width: 100%;
  @media (max-width: 767px) {
    align-items: center;
  }
`;
